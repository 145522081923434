import { Component, Input } from '@angular/core';
import { ImageDataModel } from 'app/models/image-translation.model';
import { UtilityService } from 'app/services';

@Component({
  selector: 'app-image-defer-lazy',
  templateUrl: './image-defer-lazy.component.html',
  styleUrls: ['./image-defer-lazy.component.scss']
})
export class ImageDeferLazyComponent {

  @Input() set image(value: ImageDataModel) {
    if (value) {
      this._image = this.utilityService.convertICUTranslatedObject(value);
    }
  };

  get image(): ImageDataModel {
    return this._image;
  }

  @Input() preRender: boolean = false;

  constructor(private utilityService: UtilityService) { }

  private _image: ImageDataModel;

}