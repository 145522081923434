<div class="info-page__section">
	<div class="info-page__block">
		<ng-content select="[info-page-overwrite]"></ng-content>
		<app-info-content
			*ngIf="title || description || inputFields || inputLinkButton || inputSubmitButton || footerContent"
			[title]="title" [description]="description" [inputFields]="inputFields" [footerContent]="footerContent"
			[inputLinkButton]="inputLinkButton" [inputSubmitButton]="inputSubmitButton" [submitForm]="submitForm">
		</app-info-content>
		<script *ngIf="script != null">
			script
		</script>
	</div>
</div>