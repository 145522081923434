import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/modules/shared/shared.module';
import { MatDialogRef } from '@angular/material/dialog';
import { UtilityService } from 'app/services';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-payment-info-video-dialog',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './payment-info-video-dialog.component.html',
  styleUrls: ['./payment-info-video-dialog.component.scss']
})
export class PaymentInfoVideoDialogComponent {
  videoUrl: SafeResourceUrl;

  constructor(
    public dialogRef: MatDialogRef<PaymentInfoVideoDialogComponent>,
    public utilityService: UtilityService,
    private sanitizer: DomSanitizer
  ) {
    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.utilityService.isNL ? 'https://www.youtube.com/embed/Cfh6h-KBrIE?autoplay=1&mute=1' : 'https://www.youtube.com/embed/n7Ym8F4tEgg?autoplay=1&mute=1');
  }

  closeDialog() {
    this.dialogRef.close();
  }
}