import { Component, Input, OnInit } from '@angular/core';
import { CmsBaseComponent } from '../../cms-base/cms-base.component';

@Component({
	selector: 'app-question-collection-section',
	templateUrl: './question-collection-section.component.html',
	styleUrls: ['./question-collection-section.component.scss']
})
export class QuestionCollectionSectionComponent extends CmsBaseComponent implements OnInit {

	@Input()
	contents!: Question[];
	@Input()
	contentsCollections?: QuestionCollection[];
	@Input()
	seperatorTitle: string;
	@Input()
	collectionTitle?: string;

	constructor() {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();
		if (!this.contentsCollections && this.contents) {
			this.contentsCollections = [];
			this.contentsCollections[0] = new QuestionCollection(this.contents, this.seperatorTitle, this.collectionTitle);
		}
	}

}

export class QuestionCollection {
	contents: Question[]
	seperatorTitle: string
	title: string
	constructor(contents: Question[], seperatorTitle: string, title: string) {
		this.contents = contents;
		this.title = title;
		this.seperatorTitle = seperatorTitle;
	}
}

export class Question {
	description: string
	title: string
	constructor(description: string, title: string) {
		this.description = description;
		this.title = title;
	}
}