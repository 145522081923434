<app-modal id="tutor-search__banner-popup" (modalClose)="modalClose({submitted: false})"
	#modal="modal">
	<div modal-close style="z-index: 9;">
		<i class="popup-component__block__close fa fa-times close" (click)="modalClose({submitted: false})"></i>
	</div>
	<div class="info-page__header" modal-title>
		<div class="tutor-search__banner-modal__header">
			<div class="tutor-search__banner-modal__header__title">{{'texts.TutorSearchPopupOnlineComponent_text_one' |
				translate}}
			</div>
		</div>
	</div>
	<div class="info-page__body" modal-body>
		<div class="video-component">
			<app-video [video]="videoSrcSet" [overlay]="overlayImageSrc" [overlayTitle]="overlayTitle"></app-video>
		</div>
		<p class="tutor-search__banner-modal__body__text">
			{{'texts.TutorSearchPopupOnlineComponent_text_two' | translate}}
		</p>
	</div>
	<div class="info-page__footer tutor-search__banner-modal__footer" modal-footer>
		{{'texts.TutorSearchPopupOnlineComponent_more_information_text1' | translate}}
		<a target="_blank" [routerLink]="['/OnlineTutorialExplanation'] | localize"
			(click)="modalClose({submitted: false})">
			{{'texts.TutorSearchPopupOnlineComponent_more_information_link1' | translate}}
		</a>
		{{'texts.TutorSearchPopupOnlineComponent_more_information_text2' | translate}}
	</div>
</app-modal>