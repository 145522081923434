import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, UtilityService } from 'app/services';
import { CmsBaseComponent } from '../cms-base/cms-base.component';

@Component({
  selector: 'app-bonus-table',
  templateUrl: './bonus-table.component.html',
  styleUrls: ['./bonus-table.component.scss']
})
export class BonusTableComponent extends CmsBaseComponent implements OnInit {

  title!: string;
  description!: string;
  contentsHead!: { text: any, type: string; }[];
  contentsBody!: [];
  transparentBackground: boolean = false;
  transparentLines: boolean = false;
  noFilter: boolean = false;

  adminTableComponent: any;

  displayedColumns = [];
  dataSource = [];

  datatableMessages = {
    emptyMessage: '',
    totalMessage: ''
  };

  constructor(
    public authService: AuthService,
    private router: Router,
    private translationService: TranslateService,
    public utilityService: UtilityService,
    private activatedRoute: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (this.utilityService.isBrowser) {
      if (!this.authService.user) {
        setTimeout(() => {
          this.router.navigate(['/401'], { queryParams: { returnUrl: this.activatedRoute.snapshot.url } });
        });
      }
    }

    this.translationService.get('texts.AppointmentSection_length_menu', { value: '' })
      .subscribe((translation: string) => this.datatableMessages.totalMessage = translation);
    this.translationService.get('texts.AppointmentSection_empty_table', { value: '' })
      .subscribe((translation: string) => this.datatableMessages.emptyMessage = translation);

    this.displayedColumns = this.contentsHead.map(value => value.text);
    this.dataSource = this.contentsBody;
    this.adminTableComponent = {
      'data': this.contentsBody
    };
  }
}
