import { Component, Input } from '@angular/core';
import { InputIcon, InputElement, InputSubmitButton } from 'app/models';

@Component({
	selector: 'app-button',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.scss']
})
export class ButtonComponent {

	@Input()
	inputIcon: InputIcon
	@Input()
	label: string
	@Input()
	labelClass: string
	@Input()
	iconType: string
	@Input()
	iconLabel: string
	@Input()
	input: InputElement
	@Input()
	messageError: string
	@Input()
	isMaxWidth: boolean
	@Input()
	inputField: InputSubmitButton;

	@Input()
	middle: boolean
	@Input()
	orange: boolean
	@Input()
	small: boolean
}
