import { PreparedLink } from ".";

export interface CardPriceContent {
	className: string;
	title: string;
	description: string[];
	price: number;
	buttonText: string;
	buttonLink: PreparedLink;
}

export interface LevelRates {
	junior: LevelRate;
	senior: LevelRate;
	supreme: LevelRate;
}

interface LevelRate {
	level: string;
	rate: number;
	rate_online: number;
}

export interface ContentObject {
	juniorPrice: string;
	seniorPrice: string;
	supremePrice: string;
	juniorCopies: string[];
	seniorCopies: string[];
	supremeCopies: string[];
	button: {
		title: string;
		link: string;
	};
}