<div class="register-container">
    <div class="close-button" *ngIf="isCloseButtonVisible" (click)="closeDialog()"><app-svg-icon name="close-large"></app-svg-icon></div>
    <div class="bah-logo-container"><img class="bah-logo" [src]="'images.Misc_logo_rebrand.originalSource' | translate"
            [alt]="'images.Misc_logo_rebrand.title' | translate" (click)="closeDialogAndNavigate()"></div>
    <div class="desktop-vector-1">
        <app-svg-icon name="student-registration-1"></app-svg-icon>
    </div>
    <div class="desktop-vector-2">
        <app-svg-icon name="student-registration-2"></app-svg-icon>
    </div>
    <div class="picture-section">
        <div class="background-picture">
        </div>
    </div>
    <div class="dialog-section">
    </div>
    <div class="dialog-container">
        <ng-content></ng-content>
    </div>
</div>