import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { CmsBaseComponent } from '../../cms/cms-base/cms-base.component';

@Component({
	selector: 'app-text-info-section',
	templateUrl: './text-info-section.component.html',
	styleUrls: ['./text-info-section.component.scss']
})
export class TextInfoSectionComponent extends CmsBaseComponent implements OnInit {
	@Input()
	anchorLink: string;
	@Input()
	contents: any[];
	@Input()
	description: string;
	@Input()
	useLargeHeading: boolean = false;

	private _sectionBackgroundColor: string;
	sectionBackground: SafeStyle;
	get sectionBackgroundColor() {
		return this._sectionBackgroundColor;
	}
	@Input() set sectionBackgroundColor(val: string) {
		this._sectionBackgroundColor = val;
		this.sectionBackground = this._sanitizer.bypassSecurityTrustStyle(this.sectionBackgroundColor);
	}

	@Input()
	subtitle: string;
	@Input()
	title: string;

	constructor(
		private _sanitizer: DomSanitizer,
	) {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.useLargeHeading = this.useLargeHeading || this.isFirstSection;
	}

}
