import { Inject, Injectable, LOCALE_ID, Optional, PLATFORM_ID, EventEmitter, isDevMode } from '@angular/core';
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { MessageType, BypassAPIGlobalHandleEnums } from '../constants';
import { ToastService } from './toast.service';
import { PreparedLink, RoomMessage, User } from '../models';
import { API_BASE } from '../tokens/api-base';
import { v4 as uuidv4 } from 'uuid';
import { HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AbstractControl, ValidatorFn } from '@angular/forms';

@Injectable({
	providedIn: 'root'
})
export class UtilityService {
	constructor(
		private gtmService: GoogleTagManagerService,
		private toastService: ToastService,
		private translationService: TranslateService,
		private location: Location,
		@Inject(PLATFORM_ID) private platformID,
		@Optional() @Inject(LOCALE_ID) private locale,
		@Optional() @Inject(API_BASE) private apiBaseUrl,
		@Optional() @Inject(DOCUMENT) private document: Document,
	) {
		this.sessionId = uuidv4()
		this.setCountry();
		this.setSupportPhoneNumberCallable();
	}

	public readonly sessionId: string;

	private _isSemiFullScreen = false;
	set isSemiFullScreen(value: boolean) {
		this._isSemiFullScreen = value;
		this.onFullScreenChange.emit();
	}
	get isSemiFullScreen() {
		return this._isSemiFullScreen;
	}
	isFullScreen: boolean;
	onFullScreenChange = new EventEmitter();

	get isBrowser() {
		return isPlatformBrowser(this.platformID);
	}

	country: string;
	isDE: boolean;
	isNL: boolean;
	supportPhoneNumberCallable: string;
	isCodeControlledBackNavigation = false;

	private _isFooterVisible = true;

	get isFooterVisible(): boolean {
		return this._isFooterVisible;
	}

	set isFooterVisible(value: boolean) {
		this._isFooterVisible = value;
		this.onFooterVisibilityChange.emit(value);
	}

	onFooterVisibilityChange = new EventEmitter<boolean>();

	unescapeHTML(value: any): any {
		value = this.isBrowser ? this.htmlDecode(value) : unescape(value);
		value = value.replace(/&lt;q&gt;/g, '<q>').replace(/&lt;\/q&gt;/g, '</q>');
		value = value.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
		value = value.replace(/&lt;style&gt;/g, '<style>').replace(/&lt;\/style&gt;/g, '</style>');
		return value;
	}

	htmlDecode(value) {
		let doc = new DOMParser().parseFromString(value, "text/html");
		return doc.documentElement.textContent;
	}

	escapeArray(param: any) {
		if ((typeof param == 'object' || Array.isArray(param)) && param !== null) {
			Object.keys(param).forEach((key) => {
				if (typeof param[key] == 'string') {
					param[key] = unescape(param[key]);
				} else if (typeof param[key] == 'object' || Array.isArray(param[key])) {
					param[key] = this.escapeArray(param[key]);
				}
			});
		} else if (typeof param == 'string') {
			param = unescape(param);
		}
		return param;
	}

	// TODO: this should be moved to a dedicated service, something like AnalyticsService
	// after that we should be able to replace console.log with logService.log
	trackEvent(category: string, event: string, label?: string, input?: any, misc?: any, value?: any) {
		if (!this.isBrowser)
			return;

		let gtmTag = {};

		switch (category) {
			case "event":
				gtmTag = {
					event: event,
					eventCategory: '',
					eventAction: '',
					eventLabel: '',
					eventInput: '',
					eventMisc: '',
					eventValue: ''
				};
				break;
			case "virtualPageView":
				gtmTag = {
					event: 'virtualPageView',
					pageUrl: event,
					eventCategory: '',
					eventAction: '',
					eventLabel: '',
					eventInput: '',
					eventMisc: '',
					eventValue: ''
				};
				break;
			default:
				gtmTag = {
					event: 'GAEvent',
					eventCategory: category,
					eventAction: event ? event : '',
					eventLabel: label ? label : '',
					eventInput: input ? input : '',
					eventMisc: misc ? misc : '',
					eventValue: value ? value : ''
				};
		}

		// this is purposely available on test environments
		this.gtmService.pushTag(gtmTag).catch((err) => {
			console.log('Error pushing gtm tag: ', err);
		});

		// this is only needed for dev purposes, uncomment as required
		console.log('trackEvent', gtmTag);
	}

	isValidPostCode(postalCode: string | null): boolean {
		switch (this.country) {
			case 'de':
				return Boolean(postalCode && postalCode.length == 5) && /^\d+$/.test(postalCode);
			case 'nl':
				return Boolean(postalCode && postalCode.length >= 6 && postalCode.length <= 7);
		}
		return false;
	}

	generateUuid(): string {
		return uuidv4()
	}

	get isMobileRes() {
		return this.isBrowser && window.innerWidth < 576;
	}
	get isDesktopRes() {
		return this.isBrowser && window.innerWidth > 991;
	}

	/**
	 * @param {string} postCodeField Jquery element or selector
	 * - Testing post code format.
	 * - Showing error message or returning postCode.
	 * @returns {string} Correctly formatted post code input value
	 */
	getPostCode(postCode: string | null) {
		const regex = this.getPostCodeRegex();
		const postCodeVal = postCode ? postCode.replace(/\s+/g, '') : '';
		// TODO: add check for error fields accordingly to their input field
		// $('.postcode-error').removeClass('visible');

		if (this.isValidPostCode(postCodeVal)) {
			if (regex && !regex.test(postCodeVal)) {
				// $(postCodeField).addClass('visible');
			} else {
				// $(postCodeField).removeClass('visible');
			}
		}
		return postCodeVal;
	}

	getPostCodeRegex(): RegExp | null {
		switch (this.country) {
			case 'de':
				return /^\d{5}$/i;
			case 'nl':
				return /^\d{4}[a-z]{2}$/i;
		}
		return null;
	}

	isValidHouseNumber(houseNumber: any) {
		return houseNumber && houseNumber.length > 0 && houseNumber.length <= 4;
	}

	get apiBase() {
		if (isPlatformServer(this.platformID)) {
			return `https://${this.apiBaseUrl}`;
		} else {
			return '';
		}
	}

	getLanguageCode(): string | null {
		switch (this.country) {
			case 'de':
				return 'de-DE';
			case 'nl':
				return 'nl-NL';
		}
		return null;
	}

	getTimeZone(): string | null {
		if (this.country == 'de' || this.country == 'nl') {
			return 'Europe/Amsterdam';
		}
		else {
			return null;
		}
	}

	getLongDateString(date: any) {
		const dateStr = date.toLocaleString(this.getLanguageCode() ?? '', { month: 'long' });
		let monthStr = '';
		switch (this.country) {
			case 'de':
				monthStr = dateStr.charAt(0).toUpperCase() + dateStr.substring(1);
				return `${date.getDate()}. ${monthStr}`;
			case 'nl':
				monthStr = dateStr.charAt(0).toUpperCase() + dateStr.substring(1);
				return `${monthStr} ${date.getDate()}`;
		}
		return 'Invalid date.';
	}

	/**
	 * Copies the input string to the user's clipboard
	 * @param {String} text
	 */
	updateClipboard(link: any) {
		try {
			navigator.clipboard.writeText(link)
				.then(() => {
					this.translationService.get('texts.Misc_clipboard_success').subscribe(translation => {
						this.toastService.showMsg(translation, MessageType.success);
					});
				})
				.catch(err => {
					this.allowAndUpdateClipboard(link);
				});
		} catch (e) {
			this.allowAndUpdateClipboard(link);
		}
	}

	allowAndUpdateClipboard(link: any) {
		try {
			// @ts-ignore
			navigator.permissions.query({ name: 'clipboard-write' }).then(result => {
				if (result.state == 'granted' || result.state == 'prompt') {
					this.updateClipboard(link);
				} else {
					this.translationService.get('texts.Misc_clipboard_error').subscribe(translation => {
						this.toastService.showMsg(translation, MessageType.error);
					});
				}
			});
		} catch (error) {
			this.translationService.get('texts.Misc_clipboard_error').subscribe(translation => {
				this.toastService.showMsg(translation + JSON.stringify(error), MessageType.error);
			});
		}
	}

	prepareLink(url: string, defaultValue?: string): PreparedLink {
		if (!url && defaultValue) {
			url = defaultValue;
		}
		const urlObject = this.getFullURL(url);
		if (!urlObject && url && url[0] != '/') {
			url = '/' + url;
		}
		return {
			url,
			urlObject
		};
	}

	/**
	 * To check if we use an href or routerLink element, we need to distinguish
	 * @param urlStr
	 * @returns {Boolean} if it is a full url with origin or not.
	 */
	getFullURL(urlStr: string): URL | null {
		try {
			const urlObject = new URL(urlStr);
			if (urlObject) {
				return urlObject;
			}
			return null;
		} catch (e) {
			return null;
		}
	}

	showConfetti(timeout = 5000) {
		// if (this.isBrowser) {
		// 	confetti.start()
		// 	var confettiSettings = { target: 'body' };
		// 	var confetti = new ConfettiGenerator(confettiSettings);
		// 	confetti.render();
		// 	setTimeout(() => {
		// 		confetti.clear();
		// 	}, timeout);
		// }
	}

	getChatUserImage(room: RoomMessage, user: User) {
		if (room.users?.length) {
			let _user = (room.users[0].id == user.id ? room.users[1] : room.users[0]);
			if (_user) {
				return _user.image.length ? ((isDevMode()
					? 'https://staging.bijlesaanhuis.nl/profileImages/'
					: '/profileImages/') + _user.image) : '';
			}
		}
		return '';
	}

	// The new NGX translate ICU Message Format translated objects returns a properties as function instead of a string
	// This function is needed to convert the function to a string
	convertICUTranslatedObject(obj): any {
		const newObj = {};

		for (const key in obj) {
			if (obj.hasOwnProperty(key)) {
				const value = obj[key];

				if (typeof value === 'function') {
					newObj[key] = value();
				} else if (typeof value === 'object' && value !== null) {
					newObj[key] = this.convertICUTranslatedObject(value); // Recursively convert inner objects
				} else {
					newObj[key] = value;
				}
			}
		}
		return newObj;
	}

	getResponsiveImageSource(image): string {
		let responsiveSource = "";
		if (Object.keys(image.sources).length) {
			if (this.isBrowser) {
				if (this.document.body.offsetWidth < 481) {
					responsiveSource = image.sources.mobile;
				} else if (this.document.body.offsetWidth < 568) {
					responsiveSource = image.sources.small;
				} else if (this.document.body.offsetWidth < 768) {
					responsiveSource = image.sources.tablet;
				} else if (this.document.body.offsetWidth < 992) {
					responsiveSource = image.sources.large;
				} else {
					responsiveSource = image.originalSource ?? image.sources.large;
				}
			}
		} else {
			responsiveSource = image.originalSource ?? image.sources.large;
		}
		return responsiveSource;
	}

	openFullScreen(elem: any) { // type is HTMLElement
		if (elem.requestFullscreen) {
			elem.requestFullscreen();
		} else if (elem.webkitRequestFullscreen) { /* Safari */
			elem.webkitRequestFullscreen();
		} else if (elem.msRequestFullscreen) { /* IE11 */
			elem.msRequestFullscreen();
		}
		this.isFullScreen = true;
	}

	closeFullscreen() {
		if (this.document.exitFullscreen) {
			this.document.exitFullscreen();
			// @ts-ignore
		} else if (this.document.webkitExitFullscreen) { /* Safari */
			// @ts-ignore  
			this.document.webkitExitFullscreen();
			// @ts-ignore
		} else if (this.document.msExitFullscreen) { /* IE11 */
			// @ts-ignore  
			this.document.msExitFullscreen();
		}
		this.isFullScreen = false;
	}

	loadScript(url: string, id: string, callback?: () => void,) {
		const existingScript = this.document.getElementById(id);
		if (!existingScript) {
			const body = <HTMLDivElement>this.document.body;
			const script = this.document.createElement('script');
			script.innerHTML = '';
			script.type = "text/javascript"
			script.src = url;
			script.async = true;
			script.id = id;
			script.onload = callback;
			body.appendChild(script);
		} else {
			if (callback) {
				callback();
			}
		}
	}

	addGooglePhoneNumberLib() {
		return new Promise((resolve) => {
			this.loadScript('../assets/plugins/libphonenumber.js', 'google-phone-number-lib', () => {
				const phoneInstance = window["libphonenumber"].PhoneNumberUtil.getInstance();
				resolve(phoneInstance);
			})
		})
	}

	phonePatternValidator(phoneNumberUtil?): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } => {
			if (!control.value) {
				return null;
			}

			let valid = false;

			try {
				const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(
					control.value, this.country.toUpperCase()
				);
				valid = phoneNumberUtil.isValidNumber(phoneNumber);
			} catch (e) { }

			return valid ? null : { invalidPhone: true };
		};
	}

	// we need to send the route from the component to get the context from it's component
	addOpenQueryParam(param: string, route: ActivatedRoute, router: Router) {
		const queryParams = { ...route.snapshot.queryParams };

		queryParams[param] = 'true';
		router.navigate([], {
			relativeTo: route,
			queryParams: queryParams,
			queryParamsHandling: 'merge'
		});
	}

	goBackOnMobile() {
		if (!this.isDesktopRes) {
			this.location.historyGo(-1);
			this.isCodeControlledBackNavigation = true;
		}
	}

	public addHeaders(byPass: BypassAPIGlobalHandleEnums.ToastMessageHandle | BypassAPIGlobalHandleEnums.ErrorHandle | BypassAPIGlobalHandleEnums.All) {
		let headers = new HttpHeaders();
		if (byPass == BypassAPIGlobalHandleEnums.ToastMessageHandle || byPass == BypassAPIGlobalHandleEnums.All) {
			headers = headers.append('bypassGlobalToastMessage', 'true');
		}
		if (byPass == BypassAPIGlobalHandleEnums.ErrorHandle || byPass == BypassAPIGlobalHandleEnums.All) {
			headers = headers.append('bypassGlobalErrorHandling', 'true');
		}
		return headers;
	}

	private setCountry() {
		if (isPlatformServer(this.platformID)) {
			this.country = this.locale;
		} else {
			const parts = this.document.location.hostname.split('.');
			this.country = parts[parts.length - 1];
		}

		if (this.country == 'de') {
			this.isDE = true;
		} else {
			this.isNL = true;
		}
	}

	private setSupportPhoneNumberCallable() {
		this.translationService.get(['texts.Packages_support_tel_number']).subscribe(translations => {
			const number = translations["texts.Packages_support_tel_number"]
			this.supportPhoneNumberCallable = "tel:" + number;
		})
	}
}
