import { ErrorHandler, Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { readFileSync } from 'fs';
import { isPlatformServer } from '@angular/common';
import { LogService } from '../services/log.service';

import * as Tracer from '../../source-map-handler/trace';
import { LOG_TYPE } from '../constants';

@Injectable()
export class GlobalErrorHandlerService extends ErrorHandler {
  private mapConsumer;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private injector: Injector
  ) {
    super();

    if (isPlatformServer(platformId)) {
      this.setServerSourceMaps();
    }
  }

  private setServerSourceMaps() {
    const sourceMap = require('../../source-map-handler');
    const rawSourceMap = JSON.parse(readFileSync('./dist/server/main.js.map', 'utf8'));
    new sourceMap.SourceMapConsumer(rawSourceMap).then(value => {
      this.mapConsumer = value;
    });
  }

  public handleError(error) {
    if (isPlatformServer(this.platformId)) {
      console.log(JSON.stringify({
        message: error.message ? error.message.split('\n')[0] : error.toString(),
        level: 400,
        level_name: 'ERROR',
        microservice: 'customer',
        stack: error.stack,
      }));
      if (this.mapConsumer) {
        Tracer.PrintStackTraceJson(this.mapConsumer, error);
      }
    } else {
      const logService = this.injector.get(LogService);
      logService.log(LOG_TYPE.ERROR, error);

      if (typeof error === 'object') {
        if (error instanceof TypeError && (location?.host.includes('local') || location?.host.includes('staging'))) {
          error = error.stack || error.message;
        } else {
          error = error.toString();
        }
        logService.report(LOG_TYPE.ERROR, error);
      }
    }
    throw error;
  }
}
