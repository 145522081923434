<div class="g-layout">
    <img class="g-icon" src="https://media.bijlesaanhuis.nl/logos/google-reviews-logo.png" alt="google-icon">
    <div class="g-content">
        <div class="g-title">Google Reviews</div>
        <div class="g-rating">
            <span class="g-score">4.7</span>
            <div>
                <img *ngFor="let star of [1,2,3,4,5]" class="g-star" [src]="star <= 4 ? 'assets/icons/svg/google-star.svg' : 'assets/icons/svg/google-star-not-full.svg'" alt="google-star">
            </div>
        </div>
    </div>
</div>