import { Component, Input, OnInit } from '@angular/core';
import { CmsBaseComponent } from '../../cms/cms-base/cms-base.component';
import { CardPackage } from 'app/models';

@Component({
	selector: 'app-card-package',
	templateUrl: './card-package.component.html',
	styleUrls: ['./card-package.component.scss']
})
export class CardPackageComponent extends CmsBaseComponent implements OnInit {

	@Input()
	description?: string
	@Input()
	title: string = "texts.CardPackageSection_title";

	packages: CardPackage[] = [
		{
			title: "texts.CardPackageSection_package_1_title",
			price: "100",
			finalPrice: "108",
			mostPopular: false
		}, {
			title: "texts.CardPackageSection_package_2_title",
			price: "250",
			finalPrice: "275",
			mostPopular: false
		}, {
			title: "texts.CardPackageSection_package_3_title",
			price: "450",
			finalPrice: "505", mostPopular: true
		}, {
			title: "texts.CardPackageSection_package_4_title",
			price: "900",
			finalPrice: "1020",
			mostPopular: false
		}
	];

	constructor() {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();
	}

}

