import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CardContent} from 'app/models';
import {CmsBaseComponent} from '../../cms/cms-base/cms-base.component';
import {PageConfigService, UtilityService} from '../../../services';
import {defaultCardContent} from '../../../constants';

@Component({
  selector: 'app-card-basic',
  templateUrl: './card-basic.component.html',
  styleUrls: ['./card-basic.component.scss']
})
export class CardBasicComponent extends CmsBaseComponent implements OnInit {
  @Input()
  component?: string
  @Input()
  title: string = ''
  @Input()
  titleImgSrc?: string
  @Input()
  titleImgAltText?: string
  @Input()
  subtitle: string = ''
  @Input()
  contents!: CardContent[]
  @Input()
  sectionClass: string = '';

  @Input()
  numbers: boolean = false;

  private country: string;

  constructor(
    public pageConfigService: PageConfigService,
    private utilityService: UtilityService,
    private translationService: TranslateService,
  ) {
    super();
    this.country = this.utilityService.country;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.numbers = this.section.component == 'card-numbers';

    if (!this.contents) {

      if (this.numbers) {
        this.contents = [
          defaultCardContent[this.country]['CardNumberSection_img_desktop'],
          defaultCardContent[this.country]['CardNumberSection_img_calendar'],
          defaultCardContent[this.country]['CardNumberSection_img_graph'],
        ];
      } else {
        this.contents = [
          defaultCardContent[this.country]['CardBasicSection_img_help'],
          defaultCardContent[this.country]['CardBasicSection_img_student'],
          defaultCardContent[this.country]['CardBasicSection_img_tutor'],
        ];
      }
    }
    this.sectionClass = this.numbers ? "card-numbers-section" : "card-basic-section";
    if (!this.title) {
      this.translationService.get(['texts.CardSection_title', 'texts.CardNumberSection_title']).subscribe(translations => {
        this.title = this.numbers ? translations['texts.CardNumberSection_title'] : translations['texts.CardSection_title'];
      });
    }
  }

  getSafeHTML(html: string) {
    return this.utilityService.unescapeHTML(html);
  }
}
