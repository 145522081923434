import { ActivatedRoute } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CmsBaseComponent } from '../../cms/cms-base/cms-base.component';
import { UtilityService } from '../../../services/utility.service';
import { Observable } from 'rxjs';
import { PopupService } from 'app/services';

@Component({
	selector: 'app-error-page',
	templateUrl: './error-page.component.html',
	styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent extends CmsBaseComponent implements OnInit {
	@Input()
	statusCode: number

	@Input()
	error: any;

	description: string
	title: string

	coveredErrorCodes = [401, 403, 404, 500];

	params$: Observable<any>;

	googleError: boolean;

	constructor(
		public utilityService: UtilityService,
		public popupService: PopupService,
		private translationService: TranslateService,
		private route: ActivatedRoute
	) {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.init();
	}

	init(): void {
		const errorCode = this.route.snapshot.data.error;

		if (this.coveredErrorCodes.includes(errorCode)) {
			this.statusCode = errorCode;
		}
		if (!this.statusCode) {
			this.statusCode = this.section && this.section.component ? Number(this.section.component.replace('error-', '')) : 401;
			// this.statusCode = this.statusCode ?? Number(this.section.component.replace('error-', ''));
		}

		if (this.statusCode == 401 && this.route.snapshot.queryParams.google_login_error && this.route.snapshot.queryParams.msg) {
			this.googleError = true;
			this.translationService.get([this.route.snapshot.queryParams.msg]).subscribe(translations => {
				this.title = translations[this.route.snapshot.queryParams.msg];
			});
			return;
		}

		if (this.statusCode === 401 && this.route.snapshot.queryParams.returnUrl) {
			this.popupService.showLoginModal();
			return
		}

		let translationStatusCode = this.statusCode && this.coveredErrorCodes.includes(this.statusCode) ? this.statusCode : 500;

		let descriptionKey = `texts.Error${translationStatusCode}Section_text`;
		let titleKey = `texts.Error${translationStatusCode}Section_title`;

		this.translationService.get([descriptionKey, titleKey]).subscribe(translations => {
			this.description = translations[descriptionKey];
			this.title = translations[titleKey];
		});
	}

	reload(): void {
		if (this.utilityService.isBrowser) {
			location.reload();
		}
	}
}
