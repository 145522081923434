export class PageSection {
	id!: string;
	component!: string;
	page!: string;
	position!: number;
	data: any;

	constructor(user: Partial<PageSection>) {
		Object.assign(this, user);
	}
}
