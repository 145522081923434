const StackTrace = require('stacktrace-js');

export const PrintStackTraceJson = (consumer, error) => {
  StackTrace.fromError(error).then((stackFrames) => {
    const s = MapFrames(consumer, stackFrames);
    console.log(JSON.stringify({
      message: error.message ? error.message.split('\n')[0] : error.toString(),
      level: 400,
      level_name: 'ERROR',
      microservice: 'customer',
      file: s.file,
      line: s.line,
      column: s.column,
      stack: s.stack,
    }));
  }).catch((err) => {
    console.log(JSON.stringify({
      message: error.message ? error.message.split('\n')[0] : error.toString(),
      level: 400,
      level_name: 'ERROR',
      microservice: 'customer',
    }));
  });
}

export const MapFrames = (consumer, stack) => {
  let file, line, column;
  let s = stack.map(frame => {
    if (consumer && frame.getLineNumber > 0) {
      const original = consumer.originalPositionFor({
        line: frame.getLineNumber(),
        column: frame.getColumnNumber()
      });
      if (file === '' && original.source.indexOf('node_modules') === -1) {
        file = original.source;
        line = original.line;
        column = original.column;
      }
      return original;
    }
    if (file === '' && frame.getFileName().indexOf('node_modules') === -1) {
      file = frame.getFileName();
      line = frame.getLineNumber();
      column = frame.getColumnNumber();
    }
    return {
      source: frame.getFileName(),
      function: frame.getFunctionName(),
      line: frame.getLineNumber(),
      column: frame.getColumnNumber(),
    };
  });
  return {
    file: file,
    line: line,
    column: column,
    stack: s,
  }
}
