<div class="dialog-container">
    <div class="close-button" (click)="close()"><app-svg-icon name="close"></app-svg-icon></div>
    <div class="dialog-title">{{"texts.Feedback_Dialog_Title" | translate}}</div>
    <div class="dialog-text" [translateParams]="{name: tutorBasicData.forename}" translate>texts.Feedback_Dialog_Text
    </div>
    <div class="picture-and-name-container">
        <div class="picture-container">
            <div class="tutor-photo"><app-image-defer-lazy [image]="image"></app-image-defer-lazy></div>
        </div>
        <div class="tutor-name">{{tutorBasicData.forename}}</div>
    </div>
    <button class="book-button button" (click)="bookLesson()" [translateParams]="{name: tutorBasicData.forename}"
        translate>texts.Feedback_Dialog_Book_Lesson_Button</button>
    <div class="or-text" translate>texts.Misc_Or</div>
    <div class="cta-option" (click)="goToSearch()"><app-svg-icon name="Square Academic Cap"></app-svg-icon><span
            translate>texts.Feedback_Dialog_Cta_1</span></div>
    <div class="cta-option" [routerLink]="['/Misc_contact'] | localize" (click)="close()"><app-svg-icon
            name="call"></app-svg-icon><span translate>texts.Feedback_Dialog_Cta_2</span></div>
</div>