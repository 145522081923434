import { Component, Input, OnInit } from '@angular/core';
import { UtilityService } from '../../../services';
import { CmsBaseComponent } from '../../cms/cms-base/cms-base.component';
import { CardTopicContent } from 'app/models';

@Component({
	selector: 'app-card-topic',
	templateUrl: './card-topic.component.html',
	styleUrls: ['./card-topic.component.scss']
})
export class CardTopicComponent extends CmsBaseComponent implements OnInit {
	@Input()
	title?: string
	@Input()
	titleImgSrc?: string
	@Input()
	titleImgAltText?: string
	@Input()
	subtitle?: string
	@Input()
	contents: CardTopicContent[] = []
	@Input()
	sectionClass?: string

	@Input()
	numbers: boolean = false;

	constructor(
		private utilityService: UtilityService,
	) {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();
		if(typeof this.contents === "object") {
			this.contents = Object.values(this.contents);
		}
		for (let i in this.contents) {
			if (this.contents[i].link) {
				this.contents[i].preparedLink = this.utilityService.prepareLink(this.contents[i].link);
			}
		}
	}

}

