import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

@Directive({
  selector: '[passwordValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: PasswordValidatorDirective, multi: true }],
  exportAs: "passwordValidate"
})
export class PasswordValidatorDirective implements Validator {

  passwordRegex: RegExp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d).{8,}$/;

  passwordPatternValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid = this.passwordRegex.test(control.value);
      return valid ? null : { invalidPassword: true };
    };
  }

  validate(control: AbstractControl): { [key: string]: any } | null {
    return this.passwordPatternValidator()(control);
  }

}
