import { CompHostDirective } from './../../directives/compHost.directive';
import { MatDialogRef } from '@angular/material/dialog';
import { Component, Input, ViewChild, ComponentFactoryResolver, Type, Output, EventEmitter } from '@angular/core';
import { FocusMonitor } from '@angular/cdk/a11y';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-dialog-core',
  templateUrl: './dialog-core.component.html',
  styleUrls: ['./dialog-core.component.scss']
})
export class DialogCoreComponent<T> {

  @Input() title: string;
  @Input() titleIcon: string;
  @Input() titleIconTooltip: string;
  @Input() childComponent: Type<any>;

  @Input() actionText = 'texts.Dialog_close';

  @Output() onChildReady = new EventEmitter();

  @ViewChild(CompHostDirective, { static: true }) compHost!: CompHostDirective;
  @ViewChild('actionButton') actionButton: MatButton

  childComponentInstance: any;

  constructor(
    public dialogRef: MatDialogRef<DialogCoreComponent<T>>,
    private compFactoryResolver: ComponentFactoryResolver,
    private focusMonitor: FocusMonitor) { }

  ngOnInit(): void {
    this.loadComponent(this.childComponent)
  }

  ngAfterViewInit() {
    if (this.actionButton) {
      this.focusMonitor.stopMonitoring(this.actionButton._elementRef);
    }
  }

  private loadComponent(component: Type<any>) {
    const viewContainerRef = this.compHost.viewContainerRef;
    viewContainerRef.clear();
    const cF = this.compFactoryResolver.resolveComponentFactory(component);
    const _child = viewContainerRef.createComponent(cF);
    this.childComponentInstance = _child.instance;
    this.onChildReady.emit(this.childComponentInstance);
  }

}
