import { Component, Input, OnInit } from '@angular/core';
import { CmsBaseComponent } from '../cms-base/cms-base.component';

@Component({
	selector: 'app-link-tab-section',
	templateUrl: './link-tab-section.component.html',
	styleUrls: ['./link-tab-section.component.scss']
})
export class LinkTabSectionComponent extends CmsBaseComponent implements OnInit {
	@Input()
	description?: string;
	@Input()
	title?: string;
	@Input()
	subtitle?: string;
	@Input()
	linkColor?: string;

	selectedLinkTabIndex: number = 0;

	constructor() {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();
	}

	setSelectedLinkTabIndex(value: number): void {
		this.selectedLinkTabIndex = value;
	}

}
