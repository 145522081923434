import { UtilityService } from './../../../services/utility.service';
import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, QueryList, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import KeenSlider, { KeenSliderInstance, KeenSliderOptions } from 'keen-slider';
import { SliderConfigModel } from 'app/models/slider-config-model';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent {

  @Input() temp: TemplateRef<any>;
  @Input() items: QueryList<any>;
  @Input() customConfig: SliderConfigModel;

  slider: KeenSliderInstance;
  currentSlide: number = 0;
  dotHelper: Array<Number>;

  @ViewChild("sliderRef") sliderRef: ElementRef<HTMLElement>

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setCarouselToScreenSize();
    this.updateCarousel();
  }

  private sliderConfig = {
    slides: {
      perView: 1,
    },
    initial: this.currentSlide,
    slideChanged: (s) => {
      this.currentSlide = s.track.details.rel
    }
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private utilityService: UtilityService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.items?.previousValue) {
      this.updateCarousel()
    } else if (changes.items?.currentValue) {
      this.cdr.detectChanges();
      this.setCarouselToScreenSize();
      this.createCarousel();
    }
  }

  updateCarousel() {
    if (this.utilityService.isBrowser) {
      this.cdr.detectChanges();
      this.dotHelper = [];
      this.currentSlide = 0;
      setTimeout(() => {
        this.slider.update(this.sliderConfig)
        this.setDots();
        this.slider.moveToIdx(0)
      })
    }
  }

  setCarouselToScreenSize() {
    if (this.utilityService.isBrowser && !this.customConfig) {
      if (window.innerWidth > 575) {
        this.sliderConfig.slides.perView = 3;
      } else {
        this.sliderConfig.slides.perView = 1;
      }
    }
  }

  createCarousel() {
    if (this.utilityService.isBrowser) {
      if (this.customConfig) {
        this.initCustomCarousel()
      }
      else {
        this.slider = new KeenSlider(this.sliderRef.nativeElement, this.sliderConfig)
      }
      this.setDots();
    }
  }

  ngOnDestroy() {
    if (this.slider) this.slider.destroy()
  }

  private initCustomCarousel() {
    let config: KeenSliderOptions = {
      initial: this.currentSlide,
      slideChanged: (s) => {
        this.currentSlide = s.track.details.rel
      }, mode: "snap",
      slides: () => [
        ...this.customConfig.page,
      ],
      loop: this.customConfig.loop,
    }

    this.slider = new KeenSlider(this.sliderRef.nativeElement, config,

      [
        (slider) => {
          let timeout
          let autoScroll = this.customConfig.autoScroll || 3000;
          let mouseOver = false
          function clearNextTimeout() {
            clearTimeout(timeout)
          }
          function nextTimeout() {
            clearTimeout(timeout)
            if (mouseOver) return
            timeout = setTimeout(() => {
              slider.next()
            }, autoScroll)
          }
          slider.on("created", () => {
            slider.container.addEventListener("mouseover", () => {
              mouseOver = true
              clearNextTimeout()
            })
            slider.container.addEventListener("mouseout", () => {
              mouseOver = false
              nextTimeout()
            })
            nextTimeout()
          })
          slider.on("dragStarted", clearNextTimeout)
          slider.on("animationEnded", nextTimeout)
          slider.on("updated", nextTimeout)
        },
      ]
    )
  }

  private setDots() {
    this.dotHelper = [
      ...Array(this.slider.track.details.slides.length).keys(),
    ]
  }
}
