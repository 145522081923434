<!-- COMMENTED OUT UNTIL NEW PICTURES PROVIDED -->
<!-- <div class="section-grid section " [class.section-active]="isStudent"> -->
<div class="section-grid section section-active">
    <div class="section-title title " [class.titleGreen]="componentStyle">
        {{'texts.Price_Page_Trustpilot_Section_Title'|translate}}
    </div>
    <div class="button-toggle-container">
        <a class=" button-toggle" [class.button-toggle-active]="!isStudent"
            (click)=" swapData(false)">{{'texts.Price_Page_Trustpilot_Section_Button_Parents'|
            translate }}</a>
        <a class=" button-toggle" [class.button-toggle-active]="isStudent"
            (click)="swapData(true)">{{'texts.Price_Page_Trustpilot_Section_Button_Students'|
            translate }}</a>
    </div>
    <app-google-one-line-review class="google-one-line-container"></app-google-one-line-review>
    <div class="testimonial-container">
        <app-carousel [items]="testimonials" [temp]="testimonialTemplate">
        </app-carousel>
    </div>
</div>
<ng-template #testimonialTemplate let-data="data">
    <!-- COMMENTED OUT UNTIL NEW PICTURES PROVIDED -->
    <!-- <div class="testimonial-card" [class.studentCard]="isStudent"> -->
    <div class="testimonial-card studentCard">
        <div class="testimonial-card-top">
            <div class="round-picture-container" *ngIf="data._imageObj">
                <app-image-defer-lazy [image]="data._imageObj"></app-image-defer-lazy>
            </div>
            <div class="testimonial-right">
                <div class="testimonial-name subtitle" [disabled]>{{data.name}}</div>
                <app-google-stars [rating]="data.rating"></app-google-stars>
            </div>
        </div>
        <div class="testimonial-card-bottom text" [disabled]> {{data.feedback}}
        </div>
    </div>
</ng-template>