import { Component, Input } from '@angular/core';
import { AuthService, PageConfigService, PageLoadService, UtilityService, VariableService } from '../../../services';
import { VERSION_HASH, VERSION_NAME } from '../../../../../version';

@Component({
  selector: 'app-footer-section',
  templateUrl: './footer-section.component.html',
  styleUrls: ['./footer-section.component.scss']
})
export class FooterSectionComponent {
  @Input()
  version: string = `${VERSION_NAME}.${VERSION_HASH}`;

  constructor(
    public authService: AuthService,
    public utilityService: UtilityService,
    public pageConfigService: PageConfigService,
    public pageLoadService: PageLoadService,
    public variableService: VariableService
  ) {

  }

  openCookieSetting() {
    const Cookiebot = (window as any).Cookiebot;
    if (typeof Cookiebot !== 'undefined') {
      Cookiebot.renew();
    } else {
      console.error('Cookiebot script not loaded.');
    }
  }
}
