import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { PageLoadService, UtilityService } from '../../../services';
import { PageTemplate } from '../../../models';

@Component({
	selector: 'app-page-load',
	templateUrl: './page-load.component.html',
	styleUrls: ['./page-load.component.scss']
})
export class PageLoadComponent {

	pageTemplate$!: Observable<PageTemplate>;

	lastPageTemplate: PageTemplate | null = null;

	location: Location;

	constructor(
		public pageLoadService: PageLoadService,
		public utilityService: UtilityService,
		location: Location
	) {
		this.location = location;
	}
}
