<section class="card-topic-section__section">
	<div *ngIf="title" class="card-topic-section__section-container">
		<h2 *ngIf="title && !isFirstSection" class="card-topic-section__title" [innerHTML]="title"></h2>
		<h1 *ngIf="title && isFirstSection" class="card-topic-section__title" [innerHTML]="title"></h1>
	</div>
	<div class="card-topic-section__section-container card-topic-section__container">
		<div *ngFor="let card of contents" class="card-topic-section__card-wrapper">
			<a *ngIf="card.link && card.preparedLink && !card.preparedLink.urlObject" class="card-topic-section__card-link" [routerLink]="[card.preparedLink.url] | localize">
				<app-card-topic-card [card]="card"></app-card-topic-card>
			</a>
			<a *ngIf="card.link && card.preparedLink && card.preparedLink.urlObject" class="card-topic-section__card-link"
				[href]="card.preparedLink.url">
				<app-card-topic-card [card]="card"></app-card-topic-card>
			</a>
			<app-card-topic-card *ngIf="!card.link" [card]="card"></app-card-topic-card>
		</div>
	</div>
</section>