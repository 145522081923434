<div class="table-container">
  <section class="table-section table-section--bonus">
    <div class="table-section__table">
      <h1 *ngIf="title && isFirstSection" class="table-section__title" [innerHTML]="title | translate"></h1>
      <h2 *ngIf="title && !isFirstSection" class="table-section__title" [innerHTML]="title | translate"></h2>
      <div class="table-section__description" [innerHTML]="description | unescape"></div>
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" [ngClass]="{
                        'bg-transparent': transparentBackground,
                        'lines-transparent': transparentLines,
                        'no-filter': noFilter
                    }">
        <ng-container *ngFor="let column of contentsHead; let i = index" [matColumnDef]="column.text">
          <th mat-header-cell *matHeaderCellDef> {{ column.text | translate }} </th>
          <td mat-cell *matCellDef="let row">
            <ng-container *ngIf="row[i]">
              <span *ngIf="column.type == 'button'">
                <a [href]="row[i].link">
                  <input type="button" class="table-section__button" [value]="row[i].text | translate" />
                </a>
              </span>
              <span *ngIf="column.type == 'download'">
                <a [href]="row[i].link" target="_blank">
                  <i *ngIf="!row[i].text; else hasText" class="far fa-file-alt"></i>
                  <ng-template #hasText>
                    {{row[i].text | translate}}
                  </ng-template>
                </a>
              </span>
              <span *ngIf="column.type == 'func'">
                <input type="button" class="table-section__button" (click)="row[i].func"
                  [value]="row[i].text | translate" />
              </span>
              <span *ngIf="column.type == 'link'">
                <a class="table-section__link" [href]="row[i].link">{{row[i].text | translate}}</a>
              </span>
              <span *ngIf="column.type == 'text'">
                <p class="table-section__text" [innerHTML]="row[i].text | unescape"></p>
              </span>
            </ng-container>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </section>
</div>