<section class="card-wide-section__section">
	<div *ngIf="title" class="card-wide-section__section-container">
		<h2 *ngIf="!isFirstSection" class="card-wide-section__title" [innerHTML]="title"></h2>
		<h1 *ngIf="isFirstSection" class="card-wide-section__title" [innerHTML]="title"></h1>
	</div>
	<div class="card-wide-section__section-container card-wide-section__container--items">
		<div *ngFor="let card of contents" class="card-wide-section__card-wrapper">
			<div class="card-wide-section__card">
				<div class="card-wide-section__card__content">
					<div *ngIf="card.imgSrc" class="card-wide-section__card__img">
						<img [lazyLoad]="card.imgSrc" [alt]="card.title" style="width:auto;">
					</div>
					<div *ngIf="card.description" class="card-wide-section__card__description" [innerHTML]="card.description | unescape"></div>
					<div class="card-wide-section__card__titles-wrapper">
						<div *ngIf="card.title" class="card-wide-section__card__title" [innerHTML]="card.title"></div>
						<div *ngIf="card.subtitle" class="card-wide-section__card__subtitle" [innerHTML]="card.subtitle"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>