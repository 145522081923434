<div class="card-topic-section__card">
	<div class="card-topic-section__card__content">
		<div *ngIf="card.imgSrc" class="card-topic-section__card__img">
			<img [lazyLoad]="card.imgSrc" [alt]="card.title" style="width:auto;">
		</div>
		<div *ngIf="card.title" class="card-topic-section__card__title">
			{{card.title}}
		</div>
		<div *ngIf="card.description" class="card-topic-section__card__description">
			{{card.description}}
		</div>
	</div>
</div>