import { Component } from '@angular/core';

@Component({
  selector: 'app-tutor-cards',
  templateUrl: './tutor-cards.component.html',
  styleUrls: ['./tutor-cards.component.scss']
})
export class TutorCardsComponent {

}
