import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
// Leave InputElement import, otherwise it is breaking
import { FooterContent, InputField, InputLinkButton, InputSubmitButton } from 'app/models';

@Component({
	selector: 'app-info-page',
	templateUrl: './info-page.component.html',
	styleUrls: ['./info-page.component.scss']
})
export class InfoPageComponent {

	@Input()
	title: string;
	@Input()
	description: string;
	@Input()
	inputFields: InputField[];
	@Input()
	footerContent: FooterContent;
	@Input()
	inputLinkButton: InputLinkButton;
	@Input()
	inputSubmitButton: InputSubmitButton;
	@Input()
	script: string;
	@Input()
	isLogin: boolean = false;

	@Input()
	submitForm: UntypedFormGroup

	constructor(
	) { }
}
