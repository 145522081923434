<section [id]="anchorLink" class="center-text__section" [style.background]="sectionBackgroundColor">
	<div class="center-text__container">
		<div class="center-text__text" [style.color]="textColor">

			<h1 *ngIf="supertitle && isFirstSection" class="center-text__text__supertitle" [bindHTML]="supertitle"></h1>
			<h2 *ngIf="supertitle && !isFirstSection" class="center-text__text__supertitle" [bindHTML]="supertitle"></h2>

			<h1 *ngIf="!supertitle && title && isFirstSection, else titleH2Template" class="center-text__text__title" [bindHTML]="title"></h1>
			<ng-template #titleH2Template>
		  	<h2 class="center-text__text__title" [bindHTML]="title"></h2>
			</ng-template>

			<h3 *ngIf="subtitle" class="center-text__text__subtitle" [bindHTML]="subtitle"></h3>

			<div *ngIf="description">
				<div class="center-text__text__description" [bindHTML]="description"></div>
			</div>

		</div>
	</div>
</section>