import { UtilityService } from './../../../services/utility.service';
import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, Input, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { CmsBaseComponent } from '../cms-base/cms-base.component';

@Component({
  selector: 'app-hubspot-section',
  templateUrl: './hubspot-section.component.html',
  styleUrls: ['./hubspot-section.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class HubspotSectionComponent extends CmsBaseComponent implements OnInit {

	@Input()
	region?: string;
	@Input()
	portalId?: string;
	@Input()
	formId?: string;

	hubspotForm: any;

	constructor(
		private renderer: Renderer2,
		private utilityService: UtilityService,
		@Inject(DOCUMENT) private _document: Document
	) {
		super();
	}

	@ViewChild('hubspotContainer', { static: false }) hbContainer: ElementRef;

	ngOnInit(): void {
		super.ngOnInit();
	}

	ngAfterViewInit(): void {
		if(this.utilityService.isBrowser) {
			this.loadHubspotScript();
		}
	}

	loadHubspotScript(): void {
		const hubspotScriptElement = this._document.createElement('script');
		hubspotScriptElement.src = '//js.hsforms.net/forms/v2.js';

		this.renderer.appendChild(this.hbContainer.nativeElement, hubspotScriptElement);

		hubspotScriptElement.onload = () => {
			// @ts-ignore
			hbspt.forms.create({
				region: this.region,
				portalId: this.portalId,
				formId: this.formId
			});
		};

		this.moveForm();
	}

	moveForm(): void {
		setTimeout(() => {
			try {
				if (document.querySelectorAll(".hbspt-form").length > 0) {
					this.hubspotForm = document.querySelectorAll(".hbspt-form")[0];
					this.renderer.appendChild(this.hbContainer.nativeElement, this.hubspotForm);
				} else {
					this.moveForm();
				}
			} catch (error) {
				console.error('Hubspot form error:', error);
			}
		}, 100);
	}
}
