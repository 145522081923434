import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subscription, Observable } from 'rxjs';
import { UtilityService } from './utility.service';
import { ApiService } from './api.service';
import { TranslateService } from '@ngx-translate/core';
import { Coordinates } from "../microservice-clients/user";
import { BypassAPIGlobalHandleEnums } from 'app/constants';

@Injectable({
	providedIn: 'root'
})
export class GeoService {
	language: string 

	fieldOrder = {
		'nl': ["postCode", "houseNumber", "street", "city"],
		'de': ["street", "houseNumber", "postCode", "city"],
	};

	fields = {
		street: {
			name: 'street',
			placeholder: 'InputGroupLocationComponent_street',
			class: 'input-group-location__street',
			fieldClass: 'address-one street'
		},
		houseNumber: {
			name: 'houseNumber',
			placeholder: 'InputGroupLocationComponent_housenumber',
			class: 'input-group-location__houseNumber',
			fieldClass: 'house'
		},
		city: {
			name: 'city',
			placeholder: 'InputGroupLocationComponent_city',
			class: 'input-group-location__city',
			fieldClass: 'address-two city'
		},
		postCode: {
			name: 'postCode',
			placeholder: 'InputGroupLocationComponent_postcode',
			class: 'input-group-location__postCode',
			fieldClass: 'postcode'
		},
	};

	formFields = [];

	streetField: UntypedFormControl = null;
	houseNumberField: UntypedFormControl = null;
	postCodeField: UntypedFormControl = null;
	cityField: UntypedFormControl = null;

	showGroupMessage: boolean = false;
	showInvalidMessage: boolean = false;
	addressAjax: Subscription = null;


	lastHouseNumberValue: string = null;
	lastPostCodeValue: string = null;
	coordinates: Coordinates = null;
	state: string;

	constructor(
		private apiService: ApiService,
		private utilityService: UtilityService,
		private translationService: TranslateService,
	) { 
		this.language = this.utilityService.country;
	}


	initForm() {
		this.translationService.get([
			'texts.InputGroupLocationComponent_street',
			'texts.InputGroupLocationComponent_housenumber',
			'texts.InputGroupLocationComponent_city',
			'texts.InputGroupLocationComponent_postcode',
		]).subscribe(translations => {
			this.fields.street.placeholder = translations['texts.InputGroupLocationComponent_street'];
			this.fields.houseNumber.placeholder = translations['texts.InputGroupLocationComponent_housenumber'];
			this.fields.city.placeholder = translations['texts.InputGroupLocationComponent_city'];
			this.fields.postCode.placeholder = translations['texts.InputGroupLocationComponent_postcode'];
		});
		this.formFields = [];
		this.language = this.utilityService.country;
		for (let key of this.fieldOrder[this.language]) {
			this.formFields.push(this.fields[key]);
		}
	}

	validateAddress(callback: Function = null): void {

		if (this.language == 'nl') {
			let houseNumber = this.houseNumberField.value;
			let postalCode = this.postCodeField.value;

			// POSTALCODE 2012ES
			// HOUSE NUMBER 30

			if (this.utilityService.isValidHouseNumber(houseNumber)) {
				postalCode = postalCode.replace(/\s+/g, '');

				// avoid ajax calls for same values
				if (this.lastHouseNumberValue == houseNumber && this.lastPostCodeValue == postalCode) {
					return;
				}

				this.lastHouseNumberValue = houseNumber;
				this.lastPostCodeValue = postalCode;

				if (this.utilityService.isValidPostCode(postalCode)) {
					this.streetField.setValue("");
					this.cityField.setValue("");

				}
			}
			this.validateAddressNL(houseNumber, postalCode, callback);
		} else if (this.language == 'de') {
			this.validateAddressDE(callback);
		}
	}


	validateAddressNL(houseNumber, postalCode, callback: Function = null): void {
		this.abortAddressValidation();

		let params = new HttpParams()
			.set('city', this.cityField.value)
			.set('houseNumber', this.houseNumberField.value)
			.set('zip', this.postCodeField.value)
			.set('streetName', this.streetField.value)
			.set('country', 'nl');

		const headers = this.utilityService.addHeaders(BypassAPIGlobalHandleEnums.All);
		this.addressAjax = this.apiService.getWithHeaders(`geo/address`, headers, params).subscribe((response) => {
			this.showGroupMessage = false;
			this.streetField.setValue(response.address.streetName);
			this.cityField.setValue(response.address.city);

			this.coordinates = {
				latitude: response.address.coordinates.lat,
				longitude: response.address.coordinates.long
			}
			this.state = response.address.province;

			if (callback) {
				callback();
			}
		}, (error) => {
			this.showGroupMessage = true;
			this.cityField.setValue('');
			this.streetField.setValue('');
			if (callback) {
				callback();
			}
		});
	}

	// TODO: validateAddressNL and validateAddressDE could be unified, has to test with NL env to make the change
	validateAddressDE(callback: Function = null): void {
		this.abortAddressValidation();

		let params = new HttpParams()
			.set('houseNumber', this.houseNumberField.value)
			.set('zip', this.postCodeField.value)
			.set('streetName', this.streetField.value)
			.set('country', 'de');

		this.addressAjax = this.apiService.get(`geo/address`, params).subscribe((response) => {
			this.showInvalidMessage = false;

			this.coordinates = {
				latitude: response.address.coordinates.lat,
				longitude: response.address.coordinates.long
			}

			this.cityField.setValue(response.address.city);
			if (callback) {
				callback();
			}
		}, (error) => {
			this.showInvalidMessage = true;
			if (callback) {
				callback();
			}
		});
	}

	abortAddressValidation(): void {
		// abort ajaxcalls with old values
		if (this.addressAjax && !this.addressAjax.closed) {
			this.addressAjax.unsubscribe();
		}
	}

	isFormValid(form: UntypedFormGroup, ignoreReadonlyFields = false): boolean {
		let isValid = true;
		let isControlEmpty = false;
		for (let controlKey in this.fields) {
			if (controlKey != 'city') {
				const control = (form.controls[controlKey] as UntypedFormControl);
				if (ignoreReadonlyFields) {
					if (this.language == 'nl' && ['city', 'street'].includes(controlKey)) {
						continue;
					}
				}
				if (control.value) {
					control.markAllAsTouched();
					control.updateValueAndValidity();
					isValid = isValid ? control.valid : isValid;
				} else {
					isControlEmpty = true;
				}
			}
		}
		// Only show address not found error when every field has been filled in.
		if (isControlEmpty) {
			isValid = false;
		} else if (!isValid) {
			this.showInvalidMessage = !isValid;
		}

		return isValid;
	}

	// TODO: when coordinates model will be synchronized use that one
	getCoordinatedByZip(zipCode: String): Observable<{ coordinates: { lat: number, long: number } }> {
		const headers = this.utilityService.addHeaders(BypassAPIGlobalHandleEnums.All);
		return this.apiService.getWithHeaders(`geo/coordinate?country=${this.utilityService.country}&zip=${zipCode}`, headers)
	}
}
