import { UtilityService } from './../../../services/utility.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'noDecimalCurrencyFormatter' })
export class NoDecimalCurrencyFormatterPipe implements PipeTransform {
    constructor(private utilityService: UtilityService) { }

    transform(value: any): string {
        if (!value) {
            return value;
        }

        const valueString = value === 'string' ? value : value.toString();
        const valueStringArray: Array<string> = valueString.split('.');


        if (valueStringArray[0].length > 3) {
            valueStringArray[0] = this.thousandSeparator(valueStringArray[0]);
        }

        const formattedValue = valueStringArray[0];

        return this.utilityService.isNL ? ('€ ' + formattedValue) : (formattedValue + ' €');
    }

    private thousandSeparator(integerPart: string) {
        // Add thousand separators to the integer part
        const formattedIntegerPart = integerPart.replace(/\B(?=\d{3}(?=(\d{3})*$))/g, '.');

        return formattedIntegerPart;
    }
}