import { Component, Input, OnInit } from '@angular/core';
import { UtilityService } from '../../../services';
import { PreparedLink } from 'app/models';

@Component({
	selector: 'app-link-button',
	templateUrl: './link-button.component.html',
	styleUrls: ['./link-button.component.scss']
})
export class LinkButtonComponent implements OnInit {

	@Input()
	id: string;
	@Input()
	link: string;
	preparedLink: PreparedLink;
	@Input()
	text: string;
	@Input()
	class: string;

	constructor(
		private utilityService: UtilityService,
	) { }

	ngOnInit(): void {
		this.preparedLink = this.utilityService.prepareLink(this.link);
	}

}
