import { Component, EventEmitter, Output } from '@angular/core';

@Component({
	selector: 'app-modal',
	exportAs: 'modal',
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
	@Output() modalClose: EventEmitter<any> = new EventEmitter<any>();

	// window.onclick = (event) {
	// 	if (event.target == document.getElementById('edit-appointment-popup')) {
	// 		var selection = window.getSelection();
	// 		if (selection.type != 'Range') {
	// 			e.preventDefault();
	// 			$('#edit-appointment-popup').hide();
	// 		}
	// 	}
	// };
}
