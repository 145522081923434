export type LogLevelName = 'DEBUG' | 'INFO' | 'WARNING' | 'ERROR';
export type LogLevel = 100 | 200 | 300 | 400;

export interface LogType {
  level: LogLevel;
  name: LogLevelName;
}

export interface LogRecord {
  level: LogLevel;
  // snake_case format used to match log dashboard
  level_name: LogLevelName
  message: string;
  microservice: 'customer-client';
  userId: number;
  appVersion: string;
  url: string;
  timestamp: string;
  sessionId: string;
  device: string;
  }