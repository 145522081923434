<footer id="footer">
	<div class="footer__container">
		<ng-container *ngIf="utilityService.country != 'de' && !pageConfigService.getIsStorybrand(); else footer2">
			<ul class="footer__list">
				<li>
					<div class="footer__list--title">{{ 'texts.Footer_title_info' | translate }}</div>
				</li>
				<li><a [routerLink]="['/Misc_tutor_search'] | localize">{{ 'texts.Misc_tutor_search' | translate }}</a></li>
        <li><a [routerLink]="['/Misc_privacy'] | localize">{{'texts.Footer_privacy' | translate }}</a></li>
				<li><a [routerLink]="['/Misc_terms'] | localize">{{ 'texts.Footer_terms' | translate }}</a></li>
				<li *ngIf="authService.isStudent()">
					<a [routerLink]="['/Footer_info_prices'] | localize">{{ 'texts.Footer_info_prices' | translate }}</a>
				</li>
				<li><a [routerLink]="['/Misc_contact'] | localize">{{ 'texts.Misc_contact' | translate }}</a></li>
			</ul>
			<ul class="footer__list">
        <li>
          <div class="footer__list--title">{{'texts.Footer_title_helpful' | translate }}</div>
        </li>
        <li><a [routerLink]="['/Navigation_prices'] | localize">{{ 'texts.NavigationSection_prices' | translate }}</a></li>
        <li><a [routerLink]="['/Footer_info_blog'] | localize">{{ 'texts.Footer_info_blog' | translate }}</a></li>
        <li><a [routerLink]="['/Misc_learning_material'] | localize">{{ 'texts.Footer_learning_material' | translate }}</a></li>
        <li *ngIf="!authService.isStudent()">
          <a [routerLink]="['/Misc_become_tutor'] | localize">{{ 'texts.Footer_info_become_a_tutor' | translate }}</a>
        </li>
		<li><a (click)="openCookieSetting()">{{'texts.cookie_settings' | translate }}</a></li>
      </ul>
			<ul class="footer__list">
				<li>
					<div class="footer__list--title">{{ 'texts.Footer_title_courses' | translate }}</div>
				</li>
				<li><a [routerLink]="['/Footer_subject_maths'] | localize">{{ 'texts.Footer_subject_maths' | translate }}</a></li>
				<li><a [routerLink]="['/Footer_subject_economics'] | localize">{{ 'texts.Footer_subject_economics' | translate	}}</a></li>
				<li><a [routerLink]="['/Footer_subject_physics'] | localize">{{ 'texts.Footer_subject_physics' | translate }}</a></li>
				<li><a [routerLink]="['/Footer_subject_all'] | localize">{{ 'texts.Footer_subject_all' | translate }}</a></li>
			</ul>
		</ng-container>
		<ng-template #footer2>
			<ul class="footer__list">
				<li>
					<div class="footer__list--title">{{'texts.Footer_title_about' | translate }}</div>
				</li>
				<li><a [routerLink]="['/Misc_about_us'] | localize">{{'texts.Footer_info_find_team' | translate }}</a></li>
				<li><a [routerLink]="['/Misc_terms'] | localize">{{'texts.Footer_terms' | translate }}</a></li>
				<li><a [routerLink]="['/Misc_privacy'] | localize">{{'texts.Footer_privacy' | translate }}</a></li>
				<li *ngIf="utilityService.country == 'de'">
					<a [routerLink]="['/Footer_company_details'] | localize">{{'texts.Footer_company_details' | translate }}</a>
				</li>
			</ul>
			<ul class="footer__list">
				<li>
					<div class="footer__list--title">{{'texts.Footer_title_more' | translate }}</div>
				</li>
				<li><a [routerLink]="['/Footer_info_prices'] | localize">{{'texts.Footer_info_prices' | translate }}</a></li>
				<li><a [routerLink]="['/Misc_learning_material'] | localize">{{'texts.Misc_learning_material' | translate }}</a>
				</li>
				<li *ngIf="utilityService.country == 'de'">
					<a [routerLink]="['/Footer_info_partner'] | localize">{{'texts.Footer_info_partner' | translate }}</a>
				</li>
				<li><a [routerLink]="['/Misc_tutor_search'] | localize">{{'texts.Misc_tutor_search' | translate }}</a></li>
				<li><a (click)="openCookieSetting()">{{'texts.cookie_settings' | translate }}</a></li>
			</ul>
			<ul class="footer__list">
				<li>
					<div class="footer__list--title">{{'texts.Footer_title_support' | translate }}</div>
				</li>
				<li>
					<a [routerLink]="['/Misc_contact'] | localize">{{'texts.Misc_contact' | translate }}</a>
				</li>
				<li *ngIf="!authService.isStudent()">
					<a [routerLink]="['/Misc_become_tutor'] | localize">{{'texts.Footer_info_become_a_tutor' | translate }}</a>
				</li>
				<li>
					<a [routerLink]="['/Navigation_faq'] | localize" role="link">{{'texts.NavigationSection_faq' | translate }}</a>
				</li>
				<li>
					<a [href]="'tel:' + (variableService.contactVariable$ | async)?.contactPhoneLinked" role="link">
						<i class="fa fa-phone-alt"></i>
						{{(variableService.contactVariable$ | async)?.contactPhoneDisplayed}}
						<br>
						<span style="font-size:12px">
							{{'texts.NavigationSection_phone_info_new' |
							translate: { open: (variableService.contactVariable$ | async)?.contactTimeOpen, close:
							(variableService.contactVariable$ | async)?.contactTimeClose} }}
						</span>
					</a>
				</li>
			</ul>
		</ng-template>
		<ul class="footer__list footer__list__team">
			<li class="footer__list__people-item--title">
				<div class="footer__list--title">{{ 'texts.Footer_title_team' | translate }}</div>
				<a [routerLink]="['/Navigation_vacancies'] | localize" class="footer__list--title footer__list--link">
					{{ 'texts.NavigationSection_vacancies' | translate }}
				</a>
			</li>
			<li class="footer__list__people-item">
				<div class="footer__list__people-img">
					<a [routerLink]="['/Misc_about_us'] | localize">
						<img [lazyLoad]="'images.Footer_team_icon_jamie.originalSource' | translate"
							[alt]="'images.Footer_team_icon_jamie.title' | translate">
					</a>
				</div>
			</li>
			<li class="footer__list__people-item">
				<div class="footer__list__people-img">
					<a [routerLink]="['/Misc_about_us'] | localize">
						<img [lazyLoad]="'images.Footer_team_icon_florian.originalSource' | translate"
							[alt]="'images.Footer_team_icon_florian.title' | translate">
					</a>
				</div>
			</li>
			<li class="footer__list__people-item">
				<div class="footer__list__people-img">
					<a [routerLink]="['/Misc_about_us'] | localize">
						<img [lazyLoad]="'images.Footer_team_icon_nick.originalSource' | translate"
							[alt]="'images.Footer_team_icon_nick.title' | translate">
					</a>
				</div>
			</li>
			<li class="footer__list__people-item">
				<div class="footer__list__people-img">
					<a [routerLink]="['/Misc_about_us'] | localize">
						<img [lazyLoad]="'images.Footer_team_icon_eike.originalSource' | translate"
							[alt]="'images.Footer_team_icon_eike.title' | translate">
					</a>
				</div>
			</li>
			<li class="footer__list__people-item">
				<div class="footer__list__people-img">
					<a [routerLink]="['/Misc_about_us'] | localize">
						<img [lazyLoad]="'images.Footer_team_icon_james.originalSource' | translate"
							[alt]="'images.Footer_team_icon_james.title' | translate">
					</a>
				</div>
			</li>
			<li class="footer__list__people-item">
				<div class="footer__list__people-img">
					<a [routerLink]="['/Misc_about_us'] | localize">
						<img [lazyLoad]="'images.Footer_team_icon_koen.originalSource' | translate"
							[alt]="'images.Footer_team_icon_koen.title' | translate">
					</a>
				</div>
			</li>
			<li class="footer__list__people-item">
				<div class="footer__list__people-img">
					<a [routerLink]="['/Misc_about_us'] | localize">
						<img [lazyLoad]="'images.Footer_team_icon_milou.originalSource' | translate"
							[alt]="'images.Footer_team_icon_milou.title' | translate">
					</a>
				</div>
			</li>
			<ng-template [ngIf]="utilityService.country == 'nl'">
				<li class="footer__list__people-item">
					<div class="footer__list__people-img">
						<a [routerLink]="['/Misc_about_us'] | localize">
							<img [lazyLoad]="'images.Footer_team_icon_lieke.originalSource' | translate"
								[alt]="'images.Footer_team_icon_lieke.title' | translate">
						</a>
					</div>
				</li>
			</ng-template>
			<li class="footer__list__strap">
				{{ 'texts.Footer_strap_line' | translate }}
			</li>
		</ul>
	</div>

	<div class="footer__social-media">
		<a [href]="'ROUTES.Misc_social_media_facebook' | translate" title="Follow on Facebook" target="_blank"
			rel="noopener"><i class="fab fa-facebook-square"></i></a>
		<a [href]="'ROUTES.Misc_social_media_instagram' | translate" title="Follow on Instagram" target="_blank"
			rel="noopener"><i class="fab fa-instagram"></i></a>
		<a [href]="'ROUTES.Misc_social_media_linkedin' | translate" title="Follow on LinkedIn" target="_blank"
			rel="noopener"><i class="fab fa-linkedin"></i></a>
	</div>
	<div class="footer__copyright">
		<p>
			{{'texts.Footer_copyright' | translate}}
		</p>
		<p *ngIf="pageLoadService.pageTemplate" class="version-number">Version:&nbsp;
			{{version}}
		</p>
	</div>
</footer>