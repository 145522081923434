<section class="cards__section" [ngClass]="sectionClass">
	<div class="cards__section-container">
		<h2 *ngIf="title && !isFirstSection" class="cards__title" [innerHTML]="title"></h2>
		<h1 *ngIf="title && isFirstSection" class="cards__title" [innerHTML]="title"></h1>
		<img *ngIf="titleImgSrc" class="cards__title-image" [lazyLoad]="titleImgSrc" [alt]="titleImgAltText"
			sizes="(max-width: 136px) 100vw, 136px" />
		<p *ngIf="subtitle" class="cards__subtitle" [innerHTML]="subtitle"></p>
	</div>

	<div *ngIf="numbers" class="cards__section-container cards__card-number__container">
		<div *ngFor="let card of contents; let cardIteration = index" class="cards__card-number-desktop">
			<div class="cards__card-divider"></div>
			<div class="cards__card-number">
				<span>{{cardIteration + 1}}</span>
			</div>
		</div>
	</div>
	<div class="cards__section-container cards__container">

		<div *ngFor="let card of contents; let cardIteration = index" class="cards__card-wrapper">

			<div *ngIf="numbers" class="cards__card-number-mobile">
				<div class="cards__card-divider"></div>
				<div class="cards__card-number">
					<span>{{cardIteration + 1}}</span>
				</div>
			</div>

			<div class="cards__card">
				<div class="cards__card__content">

					<div *ngIf="card.imgSrc" class="card-basic-section__card__img">
						<picture *ngIf="!pageConfigService.isSafari()">
							<img [lazyLoad]="card.imgSrc" [alt]="card.imgAltText" style="width:auto;">
						</picture>
						<img *ngIf="pageConfigService.isSafari()" [lazyLoad]="card.imgSrc" [alt]="card.imgAltText"
							style="width:auto;">
					</div>
					<h3 *ngIf="card.title" class="card-basic-section__card__title"
						[innerHTML]="getSafeHTML(card.title)">
					</h3>
					<div *ngIf="card.description" class="card-basic-section__card__description"
						[innerHTML]="getSafeHTML(card.description)">
					</div>

				</div>
			</div>
		</div>
	</div>
</section>