import { MessageType } from "../constants";

export class ToastParams {
	iconHTML!: string;
	title!: string;
	description!: string;
	type!: MessageType.error | MessageType.info | MessageType.success | MessageType.warning;
	link?: string;

	constructor(user: Partial<ToastParams>) {
		Object.assign(this, user);
	}
}

export interface ToastOptions {
	image?: any,
	showFullMessage?: boolean,
	timeout?: number | string,
	title?: string,
	link?: string,
}