<div #stepperContainer class="stepper-container">
    <div class="step-numbers">{{currentStep}} &nbsp; / &nbsp; {{steps}}</div>
    <div class="max-bar-width">
        <div #stepBar class="step-bar" [style.width]="widthBasedOnSteps"></div>
    </div>
</div>

<div #carouselContainer class="carousel-container">
    <div #content class="content">
        <ng-content>
        </ng-content>
    </div>
</div>

<!-- Example how to use it in parent component -->

<!-- 
    <div class="container">
    <app-stepper #stepper [activeTabIndex]=2>
        <app-advice-section></app-advice-section>
        <app-quick-booking-section></app-quick-booking-section>
        <app-teachers-area-map></app-teachers-area-map>
        <app-teachers-area-map></app-teachers-area-map>
    </app-stepper>
    <button (click)="prevStep()">step back</button>
    <button (click)="nextStep()">step forward</button>
</div>
 -->