<section class="contact-formular__section">
	<div class="contact-formular__container">
		<div class="contact-formular__text">
			<h2 *ngIf="title && !isFirstSection" class="contact-formular__text__title" [innerHTML]="title"></h2>
			<h1 *ngIf="title && isFirstSection" class="contact-formular__text__title" [innerHTML]="title"></h1>
			<div *ngIf="description" class="contact-formular__text__description" [innerHTML]="description | unescape">
			</div>
		</div>
		<form [formGroup]="checkoutForm" (ngSubmit)="checkoutForm.valid && onSubmit()">
			<div class="contact-header__email">

				<div class="contact-header__email-wrapper">
					<input type="text" class="contact-header__email-input" formControlName="name"
						[placeholder]="'texts.ContactFormSection_name' | translate" required />
					<app-input-error [element]="name" [type]="'name'"></app-input-error>
				</div>

				<div class="contact-header__email-wrapper"
					[ngClass]="{'contact-header__email-wrapper--small-form' : isSmallForm}">
					<input type="email" class="contact-header__email-input" formControlName="email"
						[placeholder]="'texts.ContactFormSection_email' | translate" required />
					<app-input-error [element]="email" [type]="'email'"></app-input-error>
				</div>

				<div *ngIf="showSubject" class="contact-header__email-wrapper">
					<input type="text" class="contact-header__email-input" formControlName="subject"
						[placeholder]="'texts.ContactFormSection_subject' | translate" required />
					<app-input-error [element]="subject" [type]="'subject'"></app-input-error>
				</div>

				<div *ngIf="showLevel" class="contact-header__email-wrapper">
					<input type="text" class="contact-header__email-input" formControlName="level"
						[placeholder]="'texts.ContactFormSection_level' | translate" required />
					<app-input-error [element]="level" [type]="'level'"></app-input-error>
				</div>

				<div *ngIf="showPackage" class="contact-header__email-wrapper">
					<select class="selectus contact-header__email-input" formControlName="package" required
						data-size="auto" [title]="'texts.ContactFormSection_package' | translate">
						<ng-option value="package-a">
							<div class='selectpicker-option'>
								<img class='selectpicker-image'
									src='https://d3oweo1tlra0ga.cloudfront.net/shutterstock_1155179839.jpg' />
								<!-- TODO: translate parameter name setting -->
								<span>{{'texts.Select_package_a_new' | translate: { value1: 108, value2: 100 } }}</span>
							</div>
						</ng-option>
						<ng-option value="package-b">
							<div class='selectpicker-option'>
								<img class='selectpicker-image'
									src='https://d3oweo1tlra0ga.cloudfront.net/shutterstock_1155179839.jpg' />
								<span>{{'texts.Select_package_b_new' | translate: { value1: 275, value2: 250 } }}</span>
							</div>
						</ng-option>
						<ng-option value="package-c">
							<div class='selectpicker-option'>
								<img class='selectpicker-image'
									src='https://d3oweo1tlra0ga.cloudfront.net/shutterstock_1155179839.jpg' />
								<span>{{'texts.Select_package_c_new' | translate: { value1: 505, value2: 450 } }}</span>
							</div>
						</ng-option>
						<ng-option value="package-d">
							<div class='selectpicker-option'>
								<img class='selectpicker-image'
									src='https://d3oweo1tlra0ga.cloudfront.net/shutterstock_1155179839.jpg' />
								<span>{{ 'texts.Select_package_d_new' | translate: { value1: 1020, value2: 900 } }}</span>
							</div>
						</ng-option>
					</select>
					<app-input-error [element]="package" [type]="'package'"></app-input-error>
				</div>

				<div class="contact-header__email-wrapper">
					<textarea type="text" class="contact-header__email-input" formControlName="message"
						[placeholder]="'texts.ContactFormSection_message' | translate" required></textarea>
					<app-input-error [element]="message" [type]="'message'"></app-input-error>
				</div>
			</div>
			<div class="recaptcha-input">
				<span class="msg-error error"></span>
				<re-captcha id="recaptcha" class="g-recaptcha" formControlName="captcha" (resolved)="resolved($event)"
					[siteKey]="siteKey"></re-captcha>
				<div [ngClass]="{'visible' : showRecaptchaMessage}" class="recaptcha-input__message">
					<div class="arrow-up"></div>
					<div>{{'texts.LoginModalComponent_recaptcha_message' | translate}}</div>
				</div>
			</div>
			<div class="contact-formular__button-wrapper">
				<button class="contact-formular__text__button" type="submit" [ngClass]="{'disabled' : !checkoutForm.valid}" (click)="validateCaptcha()">
					{{'texts.ContactFormSection_send' | translate}}
				</button>
			</div>
		</form>
	</div>
</section>