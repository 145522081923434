import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PageConfigService, UtilityService } from '../../../../services';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {

  @Input() video;
  @Input() overlay: any = { src: '' };
  @Input() overlayTitle: string;
  @Input() autoplay = false;

  isVideoPlaying: boolean = false;
  overlaySource: string;

  @ViewChild('videoPlayer') videoPlayer: ElementRef<HTMLVideoElement>;

  constructor(
    public pageConfigService: PageConfigService,
    private translationService: TranslateService,
    private utilityService: UtilityService,
  ) { }

  ngOnInit(): void {
    this.normalizeVideoSource();
  }

  normalizeOverlaySource(): void {
    if (!this.overlay) {
      if (this.video?.images) {
        this.overlay = {
          sources: this.video.images,
          originalSource: this.video.images.large,
          title: this.video.title
        }
      }
    } else {
      if (typeof this.overlay === "string") {
        if (this.overlay.includes('images.')) {
          /* If the overlay is a valid translation key*/
          this.translateOverlayKey();
        } else if (this.overlay.includes('default-') || this.overlay.includes('overlay-')) {
          /* TODO: there are default overlays with the keys default-1 to default-5.
            That legacy code needs to be replaced in the admin create page tool
            to change it into a desired standard format for overlay data.
          */
          this.overlay = 'images.' + this.overlay;
          this.translateOverlayKey();
        } else {
          /* If the overlay is a direct https address*/
          this.overlaySource = this.overlay;
          this.overlay = { originalSource: this.overlay };
        }
      }
    }
    this.setOverlayTitle();
    this.setResponsiveSources();
  }

  normalizeVideoSource(): void {
    if (this.video) {
      if (this.video.src) {
        this.video = this.video.src;
      }
      if (this.video.video) {
        // desired standard format, that is in the api
        this.normalizeOverlaySource();
      } else if (typeof this.video === "string") {
        if (this.video.includes('videos.')) {
          /* If the video is a valid translation key*/
          this.translateVideoKey();
        } else if (this.video.includes('default-')) {
          /* TODO: there are default videos with the keys default-1 to default-5.
            That legacy code needs to be replaced in the admin create page tool
            to change it into a desired standard format for video data.
          */
          this.video = 'videos.' + this.video;
          this.translateVideoKey();
        } else {
          /* If the video is a direct https address*/
          this.video = {
            source: this.video,
          }
          this.normalizeOverlaySource();
        }
      } else if (this.video[0] && typeof this.video[0].src === "string") {
        this.video = 'videos.' + this.video[0].src;
        this.translateVideoKey();
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setResponsiveSources();
  }

  setOverlayTitle(): void {
    if (typeof this.overlay === 'object' && this.overlayTitle) {
      this.overlay.title = this.overlayTitle;
    }
  }

  setResponsiveSources(): void {
    this.overlaySource = this.utilityService.getResponsiveImageSource(this.overlay);
  }

  translateOverlayKey(): void {
    this.translationService.get(this.overlay).subscribe(translation => {
      this.overlay = this.utilityService.convertICUTranslatedObject(translation);
      this.setOverlayTitle();
      this.setResponsiveSources();
    });
  }

  translateVideoKey(): void {
    this.translationService.get(this.video).subscribe(translation => {
      this.video = this.utilityService.convertICUTranslatedObject(translation);
      this.normalizeOverlaySource();
    });
  }

  startVideo() {
    this.videoPlayer.nativeElement.play();
    this.isVideoPlaying = true;
  }
}
