// Code generated by protoc-gen-typescript-http. DO NOT EDIT.
/* eslint-disable camelcase */

import { GroupedCourseModel } from "app/models";

export type UserRole =
  | "UnknownRole"
  | "Student"
  | "Tutor"
  | "Staff";

export type UserState =
  | UserStateEnum.UnknownState
  | UserStateEnum.New
  | UserStateEnum.Onboarding
  | UserStateEnum.Active
  | UserStateEnum.Disabled
  | UserStateEnum.Tutoring
  | UserStateEnum.Introducing
  | UserStateEnum.Chatting;

export enum UserStateEnum {
  UnknownState = 'UnknownState',
  New = 'New',
  Onboarding = 'Onboarding',
  Active = 'Active',
  Disabled = 'Disabled',
  Tutoring = 'Tutoring',
  Introducing = 'Introducing',
  Chatting = 'Chatting',
}

export type RegisterUserRequest = {
  user: UserObj | undefined;
  password: string | undefined;
  captcha: string | undefined;
};

export type UserObj = {
  id?: string;
  role?: UserRole;
  country?: string;
  personalInfo?: PersonalInfo;
  tutorDetails?: TutorDetails;
  studentDetails?: StudentDetails;
  isTest?: boolean;
  createdAt?: string;
  state?: UserState;
  adminLabel?: AdminLabel;
  lastActiveAt?: string;
  courses?: any;
  googleID?: string;
  createdAtMillis?: string;
};

export type PersonalInfo = {
  forename?: string;
  surname?: string;
  emailAddress?: string;
  requestedEmailAddress?: string;
  phoneNumber?: string;
  location?: Address;
  birthDate?: string;
};

export type Address = {
  street: string | undefined;
  houseNumber: string | undefined;
  postalCode: string | undefined;
  city: string | undefined;
  coordinates: Coordinates | undefined;
  country: string | undefined;
  state: string | undefined;
};

export type Coordinates = {
  longitude: number | undefined;
  latitude: number | undefined;
};

// Encoded using RFC 3339, where generated output will always be Z-normalized
// and uses 0, 3, 6 or 9 fractional digits.
// Offsets other than "Z" are also accepted.

export type TutorDetails = {
  profile?: TutorProfile;
  currentEducation?: string;
  highestEducation?: string;
  tutorLevelId?: string;
  intakeDate?: string;
  intakeTime?: string;
  hasAvailability?: string;
  requestedLevelId?: number;
  acceptsNewStudents?: string;
  canTeachOnline?: string;
  maxTravelDistance?: number;
  courseOfferings?: CourseOffering[];
  groupedCourseOfferings?: GroupedCourseModel[];
  requestedCourseOfferings?: CourseOffering[];
  searchWeight?: Float;
  category?: string;

  _acceptsNewStudents?: boolean;
  _canTeachOnline?: boolean;
};

export type TutorProfile = {
  image: string | undefined;
  title: string | undefined;
  shortDescription: string | undefined;
  description: string | undefined;
};

export type CourseOffering = {
  courseId: string | undefined;
  courseName: string | undefined;
  schoolTypeId: number | undefined;
};

export type Float = {
  value: number | undefined;
};

export type StudentDetails = {
  parentInfo?: PersonalInfo | undefined;
  favoritesCount?: number | undefined;
  favorites?: Array<number>;
  expectedTutoringDuration?: string | undefined;
};

export type AdminLabel = {
  labelId: number | undefined;
  since: string | undefined;
};

export type FindTutorsRequest = {
  limit: number | undefined;
  offset: number | undefined;
  randomSeed: number | undefined;
  country: string | undefined;
  latitude: number | undefined;
  longitude: number | undefined;
  lessonType: string | undefined;
  courseIds: number[] | undefined;
  schoolTypeId: number | undefined;
  schoolLevelId: number | undefined;
  schoolYearId: number | undefined;
  atHomeRankingFormula: string | undefined;
  onlineRankingFormula: string | undefined;
  anonymousId: string | undefined;
};

export type TutorSearch = {
  tutorDetails?: TutorDetails;
  tutor: UserObj | undefined;
  atHomeCourseOffered: boolean | undefined;
  onlineCourseOffered: boolean | undefined;
  isFavorite: boolean | undefined;
  travelCost: number | undefined;
  searchDebugInfo: SearchDebugInfo | undefined;
  _level: TutorLevel;
  _courses: Array<string>
  _matchedCourses: Array<string>
  _isRecommended: boolean
};

export type TutorCard = TutorSearch; {
}

export type LessonPrices = {
  hourlyRateAtHome: number | undefined;
  perKmTravelRate: number | undefined;
  hourlyRateOnline: number | undefined;
  atHomeCourseOffered: boolean | undefined;
  onlineCourseOffered: boolean | undefined;
  lessonPriceAtHome: number | undefined;
  lessonTravelDistanceKm: number | undefined;
  lessonTravelPrice: number | undefined;
  lessonPriceOnline: number | undefined;
  hourlyCompensationOnline: number | undefined;
  hourlyCompensationAtHome: number | undefined;
  tutorCompensationOnline: number | undefined;
  tutorCompensationAtHome: number | undefined;
  appointmentStudentOnline: number | undefined;
  appointmentStudentOnlineInCredits: number | undefined;
  appointmentStudentHomeInCredits: number | undefined;
  travelStudentInCredits: number | undefined;
  appointmentStudentHome: number | undefined;
  appointmentTutorOnline: number | undefined;
  appointmentTutorHome: number | undefined;
  travelStudent: number | undefined;
  travelTutor: number | undefined;
  atHomeOffered: boolean | undefined;
  lessonTutorOnline: number | undefined;
  lessonTutorHome: number | undefined;
  onlineOffered: boolean | undefined;
  lessonStudentHome: boolean | undefined;
  lessonStudentOnline: number | undefined;
  travelDistance?: number;
  hasBalanceForAtHome: boolean | undefined;
  hasBalanceForOnline: boolean | undefined;
};

export type WeeklyPricingRequestDetails = {
  user: string;
  latitude?: number;
  longitude?: number;
  begin: string;
  end: string;
  startDate: string;
  endDate: string;
  daysOfWeek: Array<string>;
  skipConflicts: true;
}

export type HomePrice = {
  unit: string;
  amount: number;
  package?: number;
}

export type OnlinePrice = {
  unit: string;
  amount: number;
  package?: number;
}

export type TravelPrice = {
  unit: string;
  amount: number;
  package?: number;
}

export type SearchDebugInfo = {
  formula: string | undefined;
  rank: number | undefined;
  parameters: { [key: string]: string } | undefined;
};

export type FindTutorsResponse = {
  tutors: TutorSearch[] | undefined;
  moreResults: boolean | undefined;
  randomSeed: number | undefined;
  atHomeTutors: TutorSearch[] | undefined;
  onlineTutors: TutorSearch[] | undefined;
};

export type GetLessonPricesRequest = {
  tutorId: number | undefined;
  studentId: number | undefined;
  durationInMinutes: number | undefined;
  latitude: number | undefined;
  longitude: number | undefined;
};

export type GetTutorLevelsRequest = {
  country: string | undefined;
};

export type TutorLevel = {
  category: string;
  country: string;
  id: string;
  lessonStudentHome: number | undefined;
  lessonStudentOnline: number | undefined;
  lessonTutorHome: number | undefined;
  lessonTutorOnline: number | undefined;
  lessonUnit: string;
  name: string;
  travelRatePerKm: number | undefined;
  weight: string;
};

export type GetTutorLevelsResponse = {
  tutorLevels: Array<TutorLevel> | undefined;
};

export type GetPendingTutorLevelChangesRequest = {
  country: string | undefined;
};

export type GetAllUsersRequest = {
  country: string | undefined;
  role: string | undefined;
  state: string | undefined;
  recentActivity: boolean | undefined;
};

export type GetAllUsersResponse = {
  users: UserObj[] | undefined;
};

export type EmptyResponse = {
};

export type GetUserRequest = {
  userId: number | undefined;
};

export type ConfirmEmailRequest = {
  token: string | undefined;
};

export interface User {
  GetTutorLevels(request: GetTutorLevelsRequest): Promise<GetTutorLevelsResponse>;
  GetAllUsers(request: GetAllUsersRequest): Promise<GetAllUsersResponse>;
  GetPendingTutorLevelChanges(request: GetPendingTutorLevelChangesRequest): Promise<GetAllUsersResponse>;
  GetUser(request: GetUserRequest): Promise<UserObj>;
  UpdateUser(request: UserObj): Promise<UserObj>;
  ConfirmEmail(request: ConfirmEmailRequest): Promise<EmptyResponse>;
  GetLessonPrices(request: GetLessonPricesRequest): Promise<LessonPrices>;
  FindTutors(request: FindTutorsRequest): Promise<FindTutorsResponse>;
  RegisterUser(request: RegisterUserRequest): Promise<EmptyResponse>;
}

type Request = {
  path: string;
  method: string;
  body: string | null;
};

type RequestHandler = (request: Request) => Promise<unknown>;

export function createUserClient(
  handler: RequestHandler
): User {
  return {
    GetTutorLevels(request) {
      const path = `user/tutor-levels`; // eslint-disable-line quotes
      const body = null;
      const queryParams: string[] = [];
      if (request.country) {
        queryParams.push("country=" + encodeURIComponent(request.country.toString()));
      }
      let uri = path;
      if (queryParams.length > 0) {
        uri += "?" + queryParams.join("&");
      }
      return handler({
        path: uri,
        method: "GET",
        body,
      }) as Promise<GetTutorLevelsResponse>;
    },
    GetAllUsers(request) {
      const path = `user/all`; // eslint-disable-line quotes
      const body = null;
      const queryParams: string[] = [];
      if (request.country) {
        queryParams.push("country=" + encodeURIComponent(request.country.toString()));
      }
      if (request.role) {
        queryParams.push("role=" + encodeURIComponent(request.role.toString()));
      }
      if (request.state) {
        queryParams.push("state=" + encodeURIComponent(request.state.toString()));
      }
      if (request.recentActivity) {
        queryParams.push("recentActivity=" + encodeURIComponent(request.recentActivity.toString()));
      }
      let uri = path;
      if (queryParams.length > 0) {
        uri += "?" + queryParams.join("&");
      }
      return handler({
        path: uri,
        method: "GET",
        body,
      }) as Promise<GetAllUsersResponse>;
    },
    GetPendingTutorLevelChanges(request) {
      const path = `user/pending-tutor-level`; // eslint-disable-line quotes
      const body = null;
      const queryParams: string[] = [];
      if (request.country) {
        queryParams.push("country=" + encodeURIComponent(request.country.toString()));
      }
      let uri = path;
      if (queryParams.length > 0) {
        uri += "?" + queryParams.join("&");
      }
      return handler({
        path: uri,
        method: "GET",
        body,
      }) as Promise<GetAllUsersResponse>;
    },
    GetUser(request) {
      if (!request.userId) {
        throw new Error("missing required field request.userId");
      }
      const path = `user/${request.userId}`; // eslint-disable-line quotes
      const body = null;
      const queryParams: string[] = [];
      let uri = path;
      if (queryParams.length > 0) {
        uri += "?" + queryParams.join("&");
      }
      return handler({
        path: uri,
        method: "GET",
        body,
      }) as Promise<UserObj>;
    },
    UpdateUser(request) {
      if (!request.id) {
        throw new Error("missing required field request.id");
      }
      const path = `user/${request.id}`; // eslint-disable-line quotes
      const body = JSON.stringify(request);
      const queryParams: string[] = [];
      let uri = path;
      if (queryParams.length > 0) {
        uri += "?" + queryParams.join("&");
      }
      return handler({
        path: uri,
        method: "PUT",
        body,
      }) as Promise<UserObj>;
    },
    ConfirmEmail(request) {
      const path = `user/mail/confirm`; // eslint-disable-line quotes
      const body = JSON.stringify(request);
      const queryParams: string[] = [];
      let uri = path;
      if (queryParams.length > 0) {
        uri += "?" + queryParams.join("&");
      }
      return handler({
        path: uri,
        method: "POST",
        body,
      }) as Promise<EmptyResponse>;
    },
    GetLessonPrices(request) {
      const path = `user/prices/lesson`; // eslint-disable-line quotes
      const body = null;
      const queryParams: string[] = [];
      if (request.tutorId) {
        queryParams.push("tutorId=" + encodeURIComponent(request.tutorId.toString()));
      }
      if (request.studentId) {
        queryParams.push("studentId=" + encodeURIComponent(request.studentId.toString()));
      }
      if (request.durationInMinutes) {
        queryParams.push("durationInMinutes=" + encodeURIComponent(request.durationInMinutes.toString()));
      }
      if (request.latitude) {
        queryParams.push("latitude=" + encodeURIComponent(request.latitude.toString()));
      }
      if (request.longitude) {
        queryParams.push("longitude=" + encodeURIComponent(request.longitude.toString()));
      }
      let uri = path;
      if (queryParams.length > 0) {
        uri += "?" + queryParams.join("&");
      }
      return handler({
        path: uri,
        method: "GET",
        body,
      }) as Promise<LessonPrices>;
    },
    FindTutors(request) {
      const path = `user/tutor/find`; // eslint-disable-line quotes
      const body = null;
      const queryParams: string[] = [];
      if (request.limit) {
        queryParams.push("limit=" + encodeURIComponent(request.limit.toString()));
      }
      if (request.offset) {
        queryParams.push("offset=" + encodeURIComponent(request.offset.toString()));
      }
      if (request.randomSeed) {
        queryParams.push("randomSeed=" + encodeURIComponent(request.randomSeed.toString()));
      }
      if (request.country) {
        queryParams.push("country=" + encodeURIComponent(request.country.toString()));
      }
      if (request.latitude) {
        queryParams.push("latitude=" + encodeURIComponent(request.latitude.toString()));
      }
      if (request.longitude) {
        queryParams.push("longitude=" + encodeURIComponent(request.longitude.toString()));
      }
      if (request.lessonType) {
        queryParams.push("lessonType=" + encodeURIComponent(request.lessonType.toString()));
      }
      if (request.courseIds) {
        for (const x of request.courseIds) {
          queryParams.push("courseIds=" + encodeURIComponent(x.toString()));
        }
      }
      if (request.schoolTypeId) {
        queryParams.push("schoolTypeId=" + encodeURIComponent(request.schoolTypeId.toString()));
      }
      if (request.schoolLevelId) {
        queryParams.push("schoolLevelId=" + encodeURIComponent(request.schoolLevelId.toString()));
      }
      if (request.schoolYearId) {
        queryParams.push("schoolYearId=" + encodeURIComponent(request.schoolYearId.toString()));
      }
      if (request.atHomeRankingFormula) {
        queryParams.push("atHomeRankingFormula=" + encodeURIComponent(request.atHomeRankingFormula.toString()));
      }
      if (request.onlineRankingFormula) {
        queryParams.push("onlineRankingFormula=" + encodeURIComponent(request.onlineRankingFormula.toString()));
      }
      let uri = path;
      if (queryParams.length > 0) {
        uri += "?" + queryParams.join("&");
      }
      return handler({
        path: uri,
        method: "GET",
        body,
      }) as Promise<FindTutorsResponse>;
    },
    RegisterUser(request) {
      const path = `user/new`; // eslint-disable-line quotes
      const body = JSON.stringify(request);
      const queryParams: string[] = [];
      let uri = path;
      if (queryParams.length > 0) {
        uri += "?" + queryParams.join("&");
      }
      return handler({
        path: uri,
        method: "POST",
        body,
      }) as Promise<EmptyResponse>;
    },
  };
}

// @@protoc_insertion_point(typescript-http-eof)
