import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-info-text',
  templateUrl: './info-text.component.html',
  styleUrls: ['./info-text.component.scss']
})
export class InfoTextComponent {
  @Input()
  description: string
  @Input()
  subtitle: string
  @Input()
  title: string
  @Input()
  useLargeHeading: boolean = false;
}
