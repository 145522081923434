import { Component, ComponentRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ToastParams } from 'app/models';
import { MessageType } from '../../../constants';

@Component({
	selector: 'app-toast',
	templateUrl: './toast.component.html',
	styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit, OnDestroy {

	@Input()
	title: string;
	@Input()
	description: string;
	@Input()
	toastParams: ToastParams;

	@Input()
	viewRef: ComponentRef<ToastComponent>

	@Output() destroyCheck: EventEmitter<string> = new EventEmitter<string>();
	classConditions = {};

	ngOnInit(): void {
		this.classConditions = {
			'msg--hidden': !this.toastParams,
			'msg--error': this.toastParams.type == MessageType.error,
			'msg--info': this.toastParams.type == MessageType.info,
			'msg--success': this.toastParams.type == MessageType.success,
			'msg--warning': this.toastParams.type == MessageType.warning,
			'msg--link': this.toastParams.link
		};
	}

	dismissMsg($event) {
		this.viewRef.destroy();
	}

	ngOnDestroy() {
		this.destroyCheck.emit('destroyed');
	}
}
