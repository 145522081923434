<div class="input-field">
  <label class="input-label">
    {{'texts.Misc_where_hear_about_label' | translate}} *
  </label>
  <div class="input__icon-wrapper">
    <ng-select 
      class="input-select" 
      id="hear_about" 
      name="hear_about"
      [items]="hearAboutUsOrigin" 
      [placeholder]="'texts.Misc_where_hear_about_placeholder' | translate" 
      bindValue="id"
      bindLabel="text" 
      [closeOnSelect]="true" 
      [searchable]="false" 
      (change)="onHearAboutChange($event)"
      (open)="onHearAboutTouch()">
    </ng-select>
  </div>
  <div *ngIf="showHearAboutOtherInput" class="input-field hear-about-other">
    <label class="input-label">
      {{'texts.AppointmentSection_cancellation_reason_other' | translate}}:
    </label>
    <input
      class="input-text"
      id="otherHearAbout"
      name="otherHearAbout"
      type="text"
      [placeholder]="'texts.AppointmentSection_cancellation_reason_other_placeholder' | translate"
      [(ngModel)]="otherHearAboutValue"
      (change)="onOtherHearAboutChange()">
  </div>
  <label *ngIf="!isHearAboutValid && isHearAboutTouched"
    class="input-message input-message--error visible hear-about-error">
    {{'texts.PopupCancelAppointmentComponent_validation_error' | translate}}
  </label>
</div>
