export interface SearchFilterQueryParams {
  selectedSchoolTypeId?: number ; 
  coursesId?: number[]       
  levelId?: number | string;
  yearId?: number | string;
  postCode?: string;
  online?: boolean;
  randomSeed?: number;            
  lat?: number;
  lon?: number;
  offset?: number;
}