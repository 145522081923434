import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { LOG_TYPE, MessageType } from '../../../constants';
import { ToastService, ApiService, LogService } from '../../../services';
import { environment } from 'environments/environment';
import { CmsBaseComponent } from '../cms-base/cms-base.component';

@Component({
	selector: 'app-contact-form-section',
	templateUrl: './contact-form-section.component.html',
	styleUrls: ['./contact-form-section.component.scss']
})
export class ContactFormSectionComponent extends CmsBaseComponent implements OnInit {

	@Input()
	title: string = '';
	@Input()
	description: string = '';
	@Input()
	showSubject: boolean = false;
	@Input()
	showLevel: boolean = false;
	@Input()
	showPackage: boolean = false;

	isSmallForm: boolean = false;

	contactFormMessage!: ContactFormMessage;

	siteKey: string;

	levelValidators!: ValidatorFn[];
	packageValidators!: ValidatorFn[];
	subjectValidators!: ValidatorFn[];

	captcha_value: string;
	showRecaptchaMessage: boolean = false;

	checkoutForm: UntypedFormGroup;

	constructor(
		private apiService: ApiService,
		private toastService: ToastService,
		private translationService: TranslateService,
		private logService: LogService
	) {
		super();
	}

	ngOnInit(): void {
		if (!this.siteKey) {
			this.siteKey = environment.google.CaptchaKey;
		}
		super.ngOnInit();
		this.isSmallForm = this.section.component == 'contact-form-small';
		this.contactFormMessage = new ContactFormMessage({});
		this.levelValidators = [
			forbiddenNameValidator(/bob/i) // <-- Here's how you pass in the custom validator.
		];
		if (this.showLevel) {
			this.levelValidators.push(Validators.required);
		}
		this.packageValidators = [
			forbiddenNameValidator(/bob/i) // <-- Here's how you pass in the custom validator.
		];
		if (this.showPackage) {
			this.packageValidators.push(Validators.required);
		}
		this.subjectValidators = [
			forbiddenNameValidator(/bob/i) // <-- Here's how you pass in the custom validator.
		];
		if (this.showSubject) {
			this.subjectValidators.push(Validators.required);
		}
		if (!this.title || !this.description) {
			this.translationService.get(['texts.ContactFormSection_title', 'texts.ContactFormSection_description']).subscribe(translations => {
				if (!this.title) {
					this.title = translations['texts.ContactFormSection_title'];
				}
				if (!this.description) {
					this.description = translations['texts.ContactFormSection_description'];
				}
			});
		}
		// TODO: Pagewide input validation service
		this.checkoutForm = new UntypedFormGroup({
			name: new UntypedFormControl(this.contactFormMessage.name, [
				Validators.required,
			]),
			email: new UntypedFormControl(this.contactFormMessage.email, [
				Validators.required,
				Validators.email,
			]),
			phone: new UntypedFormControl(this.contactFormMessage.phone, {
				updateOn: 'blur',
			}),
			subject: new UntypedFormControl(this.contactFormMessage.subject, this.subjectValidators),
			level: new UntypedFormControl(this.contactFormMessage.level, this.levelValidators),
			package: new UntypedFormControl(this.contactFormMessage.package, this.packageValidators),
			message: new UntypedFormControl(this.contactFormMessage.message, [
				Validators.required,
			]),
			captcha: new UntypedFormControl(this.captcha_value, Validators.required)
			// alterEgo: new FormControl(this.contactFormMessage.alterEgo),
			// power: new FormControl(this.contactFormMessage.power, Validators.required)
		});
	}

	get name() { return this.checkoutForm.get('name'); }
	get email() { return this.checkoutForm.get('email'); }
	get phone() { return this.checkoutForm.get('phone'); }
	get subject() { return this.checkoutForm.get('subject'); }
	get level() { return this.checkoutForm.get('level'); }
	get package() { return this.checkoutForm.get('package'); }
	get message() { return this.checkoutForm.get('message'); }
	get captcha() { return this.checkoutForm.get('captcha'); }

	resolved(captchaResponse: string) {
		this.captcha_value = captchaResponse;
		this.logService.log(LOG_TYPE.INFO, `Resolved captcha with response:`, captchaResponse);
		this.showRecaptchaMessage = false;
	}

	validateCaptcha(): void {
		if (this.captcha_value && this.captcha_value.length > 0) {
			this.showRecaptchaMessage = false;
		} else {
			this.showRecaptchaMessage = true;
		}
	}

	onSubmit(): void {
		let formData = {};

		for (const [key, value] of Object.entries(this.checkoutForm.value)) {
			if (value) { formData[key] = value; }
		}

		formData['g-recaptcha-response'] = formData['captcha'];
		delete formData['captcha'];

		this.apiService.post(`api/contact/request`, formData).subscribe({
			next: () => {
				this.toastService.showMsg(`texts.Misc_success`, MessageType.success);
				this.checkoutForm.reset();
				Object.keys(this.checkoutForm.controls).forEach((key) => {
					console.log(this.checkoutForm.controls[key]);
					this.checkoutForm.controls[key].markAsPristine();
				});
			},
			error: () => {
				this.toastService.showMsg('texts.Misc_error', MessageType.error);
			}
		});
	}

	/**
	* Handle Http operation that failed.
	* Let the app continue.
	* @param operation - name of the operation that failed
	* @param result - optional value to return as the observable result
	*/
	handleError<T>(operation = 'operation', result?: T, callback?: Function) {
		return (error: any): Observable<T> => {

			this.logService.report(LOG_TYPE.ERROR, typeof error === 'object' ? error.toString() : error);
			this.logService.log(LOG_TYPE.ERROR, `${operation} failed:`, error);

			if (callback) {
				callback();
			}

			// Let the app keep running by returning an empty result.
			return of(result as T);
		};
	}
}

export function forbiddenNameValidator(nameRe: RegExp): ValidatorFn {
	return (control: AbstractControl): { [key: string]: any; } | null => {
		const forbidden = nameRe.test(control.value);
		return forbidden ? { forbiddenName: { value: control.value } } : null;
	};
}

export class ContactFormMessage {
	name!: string;
	email!: string;
	phone!: string;
	city!: string;
	address!: string;
	subject!: string;
	level!: string;
	package!: string;
	captcha!: string;
	message!: string;

	constructor(data: Partial<ContactFormMessage>) {
		Object.assign(this, data);
	}
}
