import { Pipe, PipeTransform } from '@angular/core';
import { UtilityService } from 'app/services';
import * as moment from 'moment';

@Pipe({ name: 'dateWithLocale' })
export class DateWithLocalePipe implements PipeTransform {
    constructor(private utilityService: UtilityService) { }

    transform(input: moment.Moment, format: string = 'DD-MM-YYYY'): any {
        if (!input) {
            return null;
        }
        const dayName = input.locale(this.utilityService.country).format(format);
        return dayName;
    }
}