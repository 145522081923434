import { Component } from '@angular/core';

@Component({
  selector: 'app-storybrand-steps',
  templateUrl: './storybrand-steps.component.html',
  styleUrls: ['./storybrand-steps.component.scss']
})
export class StorybrandStepsComponent {

}
