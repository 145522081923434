export class ContactVariables {
	contactEmail?: string;
	contactPhoneDisplayed?: string;
	contactPhoneLinked?: string;
	contactTimeClose?: string;
	contactTimeOpen?: string;
	contactWhatsapp?: string;
	contactWhatsappLink?: string;

	constructor(user: Partial<ContactVariables>) {
		Object.assign(this, user);
	}
}

export class AppointmentDurationVariables {

}