import { ChangeDetectorRef, Component, HostListener, isDevMode } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService, BookingService, ChatService, FeedbackFlowForIntroService, PageConfigService, PopupService, UtilityService, VariableService } from 'app/services';
import { SearchService } from 'app/services/search.service';
import { CustomCookieServiceService } from 'app/services/custom-cookie-service.service'
import { UserObj } from 'app/microservice-clients/user';
import { PackagesFlowService } from 'app/services/packages-flow.service';
import { LetsStartPackageDialogComponent } from 'app/standalone-components/new-packages/lets-start-package-dialog/lets-start-package-dialog.component';
import { Subscription } from 'rxjs';
import { Appointment } from 'app/models';
import { AppointmentApiType } from 'app/constants';
import { TranslateService } from '@ngx-translate/core';
import { CheckUserState } from 'app/standalone-components/common-dialogs/additional-information/additional-information/check-user-state';
import { CompleteProfileFor } from 'app/standalone-components/common-dialogs/additional-information/models/additional-information.model';
import { PaymentInfoVideoDialogComponent } from 'app/standalone-components/payment-info-video-dialog/payment-info-video-dialog.component';
import { AbTestService } from 'app/services/ab-test.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

export class NewNavigationSectionComponent {
  isOpen: boolean = false;
  subscription: Subscription;
  appointment: Appointment;
  isTeacher: boolean;
  appointmentTutor: string;
  appointmentType: string;
  appointmentDate: any;
  hideForChat: boolean = false;
  toggleReady: boolean = false;
  isBecomeTutorPage: boolean = false;

  isDevMode = isDevMode();

  lastScrollY: number;
  headerHidden = false;
  threshold = 150;
  allowHideHeader = true;

  constructor(
    public authService: AuthService,
    public chatService: ChatService,
    public utilityService: UtilityService,
    public pageConfigService: PageConfigService,
    public variableService: VariableService,
    public changeDetector: ChangeDetectorRef,
    public popupService: PopupService,
    public searchService: SearchService,
    public packagesFlowService: PackagesFlowService,
    public aBTestService: AbTestService,
    private bookingService: BookingService,
    private feedbackFlowForIntroService: FeedbackFlowForIntroService,
    private translationService: TranslateService,
    private cookieService: CustomCookieServiceService,
    private checkUserState: CheckUserState,
    private router: Router,
    private translateService: TranslateService
  ) {
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (this.utilityService.isBrowser && this.allowHideHeader) {
      const currentScrollY = window.scrollY;

      if (currentScrollY < this.lastScrollY || currentScrollY < this.threshold) {
        this.headerHidden = false;
        this.closeDropdown();
      } else {
        this.headerHidden = true;
        this.closeDropdown();
      }
      this.lastScrollY = currentScrollY;
    }
  }

  ngOnInit(): void {
    if (this.utilityService.isBrowser) {
      this.lastScrollY = window.scrollY;

      this.translateService.get(['ROUTES.Misc_tutor_search', 'ROUTES.Misc_become_tutor', 'ROUTES.TutorRegistration_TeacherInfo', 'ROUTES.Misc_profile']).subscribe((res) => {
        const searchPageRoute = res['ROUTES.Misc_tutor_search'];
        const tutorProfileRoute = res['ROUTES.Misc_profile'];
        const becomeTutorRoute = res['ROUTES.Misc_become_tutor'];
        const becomeTutorInfoRoute = res['ROUTES.TutorRegistration_TeacherInfo'];
        this.setFlags(searchPageRoute, tutorProfileRoute);
        this.updateRegisterToTutor(becomeTutorRoute, becomeTutorInfoRoute);
        this.router.events.subscribe(event => {
          if (event instanceof NavigationEnd) {
            this.setFlags(searchPageRoute, tutorProfileRoute);
            this.updateRegisterToTutor(becomeTutorRoute, becomeTutorInfoRoute);
          }
        });
      })

      if (this.authService.isStudent()) {
        this.getAppointment();
      }
      if (this.authService.user) {
        this.packagesFlowService.getUserPackages(this.authService.user.id).subscribe()
      }
    }
  }

  handleRegisterClick() {
    if (this.isBecomeTutorPage) {
      this.translateService.get('ROUTES.TutorRegistration_TeacherInfo').subscribe((res) => {
        this.router.navigate([res]);
      })
    } else {
      this.openRegister();
    }
  }

  private updateRegisterToTutor(becomeTutorRoute: string, becomeTutorInfoRoute: string) {
    this.isBecomeTutorPage = this.router.url.includes(becomeTutorRoute) || this.router.url.includes(becomeTutorInfoRoute);
  }

  private setFlags(searchPageRoute, tutorProfileRoute?) {
    this.hideForChat = this.router.url.includes('chat');
    setTimeout(() => {
      //WE USE THIS TO ONLY RENDER THE ACCEPTS NEW STUDENTS TOGGLE WHEN WE HAVE THE ACCEPTS NEW STUDENTS INFORMATION READY
      this.toggleReady = !this.hideForChat;
    })
    if (this.router.url.includes(searchPageRoute) || (this.router.url.includes(tutorProfileRoute))) {
      this.allowHideHeader = false;
    } else {
      this.allowHideHeader = true;
    }
  }

  async editOrCompleteStudentProfile() {
    await this.checkUserState.checkIfUserProfileCompleteDialog({ completeProfileFor: CompleteProfileFor.edit })
    this.routeToStudentEditProfile()
  }

  private routeToStudentEditProfile() {
    this.translationService.get(['ROUTES.Navigation_edit_profile_student']).subscribe(link => {
      this.router.navigate([link['ROUTES.Navigation_edit_profile_student']]);
    });
  }

  getAppointment() {
    this.subscription = this.bookingService
      .getUpComingAppointment()
      .subscribe((resp) => {
        if (resp) {
          this.appointment = resp;
          this.appointmentTutor = resp.tutor.forename;
          this.appointmentType = resp.type;
          if (!resp.online) {
            this.appointment._googleMapsLink = `https://www.google.com/maps/?q=${resp.locationLatitude},${resp.locationLongitude}`;
          }
        }
      });
  }

  joinClassroom() {
    if (
      this.isIntroductionAppointment() &&
      !this.feedbackFlowForIntroService.isTimeWithinFifteenMinutes(this.appointment.begin)
    ) {
      this.feedbackFlowForIntroService.showEarlyJoinError();
      return;
    }
    if (this.isIntroductionAppointment() && this.authService.isStudent()) {
      this.feedbackFlowForIntroService.setUpToOpenFeedbackDialog(this.appointment);
    }
    this.openClassroom();
  }

  private isIntroductionAppointment(): boolean {
    return this.appointment.type === AppointmentApiType.introduction;
  }

  private openClassroom(): void {
    this.translationService
      .get(["ROUTES.Misc_relation", "ROUTES.Lessonspace_workarea"])
      .subscribe((translations) => {
        const preparedLink = this.prepareClassroomLink(translations);
        window.open(preparedLink);
      });
  }

  private prepareClassroomLink(translations): string {
    return `${translations["ROUTES.Misc_relation"]}/${translations["ROUTES.Lessonspace_workarea"]
      }/${this.getUserId()}`;
  }

  private getUserId(): string {
    return this.isTeacher
      ? this.appointment.student.id
      : this.appointment.tutor.id;
  }

  openBuyPackageDialog() {
    let dialogComp = this.popupService.openResponsiveDialog(LetsStartPackageDialogComponent).componentInstance
    dialogComp.showPictureAndSvg = false;
    dialogComp.showTutorLevelOptions = true;
    dialogComp.applyCustomStyling = true;
  }

  openPaymentVideo() {
    this.popupService.openResponsiveDialog(PaymentInfoVideoDialogComponent);
  }

  toggleOnOff() {
    this.isOpen = !this.isOpen;
  }

  updatePreference() {
    const user = {
      id: this.authService.userDetails?.id,
      tutorDetails: {
        acceptsNewStudents: (this.authService.userDetails?.tutorDetails._acceptsNewStudents === true) ? "true" : "false",
      }
    } as UserObj;
    this.authService.updateUserDetails(user).subscribe(resp => {
      this.utilityService.trackEvent('Availability_New_Students_Menu', 'Successful', `${user.tutorDetails.acceptsNewStudents}`, this.authService.userDetails.id);
    })
  }

  openDropdown() {
    this.isOpen = true;
  }

  closeDropdown() {
    this.isOpen = false;
  }

  showDropdown() {
    this.isOpen = true;
  }

  hideDropdown(event: MouseEvent) {
    const relatedTarget = event.relatedTarget as HTMLElement;
    if (relatedTarget && !relatedTarget.closest('.dropdown')) {
      this.isOpen = false;
    }
  }

  keepDropdownOpen() {
    this.isOpen = true;
  }

  logOut() {
    this.authService.logout();
    this.packagesFlowService.clearUserPackages();
    this.chatService.close();
    this.router.navigate(['/']);
  }

  openLogin() {
    this.popupService.showLoginModal();
  }

  openRegister() {
    if (this.cookieService.get('block-register')) {
      this.popupService.openInfoDialog({ title: 'texts.RegisterBlockBookLimitTitle', description: 'texts.RegisterBlockBookLimitDescription' });
      return;
    }
    this.popupService.showRegisterModal();
  }
}