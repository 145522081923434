import { Component, Input, OnInit } from '@angular/core';
import { CardWideContent } from 'app/models';
import { CmsBaseComponent } from '../../cms/cms-base/cms-base.component';

@Component({
	selector: 'app-card-wide',
	templateUrl: './card-wide.component.html',
	styleUrls: ['./card-wide.component.scss']
})
export class CardWideComponent extends CmsBaseComponent implements OnInit {
	@Input()
	component?: string
	@Input()
	title?: string
	@Input()
	titleImgSrc?: string
	@Input()
	titleImgAltText?: string
	@Input()
	subtitle?: string
	@Input()
	contents!: CardWideContent[]
	@Input()
	sectionClass?: string

	@Input()
	numbers: boolean = false;

	constructor() {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();
	}
}