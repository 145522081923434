import { UtilityService } from './../../../services/utility.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'currencyFormatter' })
export class CurrencyFormatterPipe implements PipeTransform {
    constructor(private utilityService: UtilityService) { }

    transform(value: any): string {
        if (!value) {
            return value;
        }

        const valueString = value === 'string' ? value : value.toString();
        const valueStringArray: Array<string> = valueString.split('.');

        valueStringArray[1] = valueStringArray.length > 1 ? this.addIfNeedDecimal(valueStringArray[1]) : '00';

        if (valueStringArray[0].length > 3) {
            valueStringArray[0] = this.thousandSeparator(valueStringArray[0]);
        }

        const formattedValue = valueStringArray.join(',');

        return this.utilityService.isNL ? ('€ ' + formattedValue) : (formattedValue + ' €');
    }

    private addIfNeedDecimal(value: string) {
        return value.length == 1 ? value + '0' : value;
    }

    private thousandSeparator(integerPart: string) {
        // Add thousand separators to the integer part
        const formattedIntegerPart = integerPart.replace(/\B(?=\d{3}(?=(\d{3})*$))/g, '.');

        return formattedIntegerPart;
    }
}