<div class="button-toggle-container">
    <a *ngIf="!hideAtHomeButton" class="button-toggle button-toggle-athome" [class.button-toggle-active]="!isOnline"
        (click)="!isDisabled && changeState(false)" data-cy="BtnAtHome">
        <app-svg-icon *ngIf="!isOnline" name="home-white"></app-svg-icon>
        <app-svg-icon *ngIf="isOnline" name="home-gray"></app-svg-icon>
        <span [ngClass]="isOnline ? 'is-not-online' : 'is-online'">{{'texts.Misc_at_home' |
            translate}}</span>
    </a>
    <a *ngIf="!hideOnlineButton" class="button-toggle button-toggle-online" [class.button-toggle-active]="isOnline"
        (click)="!isDisabled && changeState(true)" data-cy="BtnOnline">
        <app-svg-icon *ngIf="isOnline" name="monitor-white"></app-svg-icon>
        <app-svg-icon *ngIf="!isOnline" name="monitor-gray"></app-svg-icon>
        <span [ngClass]="isOnline ? 'is-online' : 'is-not-online'">{{'texts.Misc_online' |
            translate}}</span>
    </a>
</div>