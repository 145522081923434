import { SharedModule } from './../../modules/shared/shared.module';
import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { GeoService, UtilityService } from '../../services';
import { debounceTime, Subject, Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';

@Component({
    selector: '',
    template: '',
    standalone: true,
    imports: [CommonModule, SharedModule]
})
export class NewAddressFormBaseComponent implements OnInit {
    @Input()
    parentForm: UntypedFormGroup;

    googleOptions = {
        types: [],
        componentRestrictions: { country: 'de' }
    };

    groupLocationFieldSelectors = {
        'street_number': '.input-group-location__houseNumber',//house number
        'route': '.input-group-location__street',//street
        'locality': '.input-group-location__city',//city
        'administrative_area_level_1': '',//county
        'postal_code': '.input-group-location__postCode',
        'country': '',
    };

    constructor(
        public geoService: GeoService,
        public renderer2: Renderer2,
        public utilityService: UtilityService,
    ) {
        this.geoService.initForm();

        this.onKeyUpSubs = this.onKeyUp.pipe(debounceTime(500)).subscribe(resp => {
            this.updateFields();
        })
    }

    onKeyUp = new Subject();
    private onKeyUpSubs: Subscription;

    ngOnInit(): void {
        this.geoService.cityField = this.parentForm.get('city') as UntypedFormControl;
        this.geoService.houseNumberField = this.parentForm.get('houseNumber') as UntypedFormControl;
        this.geoService.postCodeField = this.parentForm.get('postCode') as UntypedFormControl;
        this.geoService.streetField = this.parentForm.get('street') as UntypedFormControl;
    }

    updateFields() {
        if (this.geoService.isFormValid(this.parentForm, true)) {
            this.geoService.validateAddress();
        }
    }

    isReadOnly(field): boolean {
        return this.geoService.language == 'nl' && (field.name == 'street' || field.name == 'city');
    }

    ngOnDestroy() {
        this.onKeyUpSubs.unsubscribe();
    }
}
