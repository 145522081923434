import { Component, Input, OnInit } from '@angular/core';
import { CmsBaseComponent } from '../cms-base/cms-base.component';

@Component({
	selector: 'app-link-list-section',
	templateUrl: './link-list-section.component.html',
	styleUrls: ['./link-list-section.component.scss']
})
export class LinkListSectionComponent extends CmsBaseComponent implements OnInit {

	@Input()
	aliasList?: { [key: string]: { [key: string]: any } }
	@Input()
	description?: string
	@Input()
	linkColor?: string
	@Input()
	subtitle?: string
	@Input()
	title?: string
	@Input()
	contents?: any

	constructor() {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.aliasList = {};
		const replaceKeys: { [key: string]: any } = { "link": "url", "text": "fileName" };
		for (const key in this.contents) {
			const values = this.contents[key];
			for (const valueKey in values) {
				const value = values[valueKey];
				if (!this.aliasList[key]) {
					this.aliasList[key] = [];
				}
				this.aliasList[key][replaceKeys[valueKey]] = value;
			}
		}
	}

}
