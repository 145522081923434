import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { IntersectionObserverHooks, Attributes } from 'ng-lazyload-image';

@Injectable()
export class LazyLoadImageHooks extends IntersectionObserverHooks {

	constructor(@Inject(PLATFORM_ID) platformId: any) {
		super();
		this.platformId = platformId;
	}

	isBot(attributes: Attributes) {
		return true;
	}
}