<div *ngIf="!isSubmitted && !authService.isSignedIn()" class="contact-popup-section__wrapper"
	[style.display]="isWrapperVisible ? 'block' : 'none'">
	<form [formGroup]="contactForm" (ngSubmit)="submitContactInfo()">
		<div class="contact-popup-section" [style.background]="sectionBackground" [ngClass]="{'minified': isMinified}">
			<div class="contact-popup-section__text" [style.color]="textFontColor">
				<div *ngIf="title" class="contact-popup-section__text__title" [innerHTML]="title"></div>
				<div *ngIf="mobileTitle" id="showContactPopup" class="contact-popup-section__text__mobile-title"
					(click)="showContactPopup()">
					{{mobileTitle}}
				</div>
				<p *ngIf="description" class="contact-popup-section__text__description" [innerHTML]="description | unescape"></p>
			</div>
			<div id="hideContactPopup" class="contact-popup-section__close" [style.color]="textFontColor"
				(click)="closeContactPopupSection()">
				<i class="fa fa-times"></i>
			</div>
			<div class="contact-popup-section__form">
				<div class="input-field">
					<label class="input-label" for="contact-popup-email">
						{{emailPlaceholder | translate}} *
					</label>
					<div class="input__icon-wrapper">
						<input id="contact-popup-email" name="contact-popup-email" type="email" formControlName="email"
							[placeholder]="emailPlaceholder | translate" class="input-email data-hj-allow" required="">
						<app-input-error [element]="email" type="email"></app-input-error>
					</div>
				</div>
				<div class="input-field">
					<label class="input-label" for="contact-popup-name">
						{{namePlaceholder | translate}} *
					</label>
					<div class="input__icon-wrapper">
						<input id="contact-popup-email" name="contact-popup-name" type="text" formControlName="name"
							[placeholder]="namePlaceholder | translate" class="input-text data-hj-allow" required="">
						<app-input-error [element]="name" type="text"></app-input-error>
					</div>
				</div>
				<input type="button" [value]="buttonText" class="contact-popup-section__form__button"
					[style.background]="buttonBackground" [style.color]="textFontColor" (click)="submitContactInfo()" />
			</div>
		</div>
	</form>
</div>