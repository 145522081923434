export const environment = {
  envName: 'test',
  production: false,
  browserDirPath: 'dist/browser',
  google: {
    CaptchaKey: '6LdLlcYUAAAAAAwwGkpo4z_TF1Zt41s52VaagFpo',
    InvisibleCaptchaKey: '6LfpMe0dAAAAAIYUvNSwTmMi2pKrVd07T5bTiXLM'
  },
  gtm: {
    nl: {
      GTM_auth: 'pZBYlnYHFtsNRF1Fode3pg',
      GTM_id: 'GTM-52JWB73',
      GTM_preview: 'env-25'
    },
    de: {
      GTM_auth: 'WVNFF_689gba8osOmjE2hQ',
      GTM_id: 'GTM-MD7T24K',
      GTM_preview: 'env-9'
    }
  },
  Heap: {
    nl: '3217488958',
    de: '633262594'
  },
  LaunchDarkly: '626fa42db45e530c52d78b5e',
  microsoft: {
    tutorInterviewBookingNl: 'https://outlook.office365.com/owa/calendar/tutor-interview-test@bijlesaanhuis.nl/bookings/',
    tutorInterviewBookingDE: 'https://outlook.office365.com/owa/calendar/tutor-interview-test@lernigo.de/bookings/'
  }
};
