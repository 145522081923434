import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LOG_TYPE } from 'app/constants';
import { LogService, UtilityService } from 'app/services';

@Component({
  selector: 'app-hear-about',
  templateUrl: './hear-about.component.html',
  styleUrls: ['./hear-about.component.scss']
})
export class HearAboutComponent implements OnInit {

  @Input() toggleHearAboutTouched: boolean;
  @Input() userRole: number;
  @Output() setHearAboutValue = new EventEmitter<HearAboutUsChoice>();
  @Output() setHearAboutValidity = new EventEmitter<boolean>();
  @Output() setHearAboutTouched = new EventEmitter<boolean>();

  constructor(
    private httpClient: HttpClient,
    private logService: LogService,
    private translationService: TranslateService,
    private utilityService: UtilityService
  ) { }

  hearAboutUsOrigin = [];
  showHearAboutOtherInput: boolean = false;
  otherHearAboutValue: string = "";
  isHearAboutValid: boolean = false;
  isHearAboutTouched: boolean = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.toggleHearAboutTouched.currentValue) {
      this.isHearAboutTouched = changes.toggleHearAboutTouched.currentValue;
    }
  }

  ngOnInit(): void {
    const params = new HttpParams()
      .set('role', this.userRole)
      .set('country', this.utilityService.country);

    this.httpClient.get(`${this.utilityService.apiBase}/user/heard/about/options`, { params }).subscribe({
      next: (response: RegistrationHearAboutOptions) => {
        this.hearAboutUsOrigin = response.options;
        this.hearAboutUsOrigin.sort(() => Math.random() - 0.5);
        this.hearAboutUsOrigin = this.hearAboutUsOrigin.slice();
        this.translationService.get([
          'texts.TutorRegistrationComponent_wherehearaboutus_option_other'
        ]).subscribe(translations => {
          this.hearAboutUsOrigin.push(
            {
              id: 0,
              text: translations['texts.TutorRegistrationComponent_wherehearaboutus_option_other']
            }
          );
        });
      },
      error: (error) => {
        this.logService.log(LOG_TYPE.ERROR, 'Error retrieving where hear about us options.', error);
      }
    });

  }

  onHearAboutTouch(): void {
    if (!this.isHearAboutTouched) {
      this.isHearAboutTouched = true;
      this.setHearAboutTouched.emit(this.isHearAboutTouched);
    }
  }

  onHearAboutChange(selectedOption: any): void {
    this.showHearAboutOtherInput = (selectedOption?.id == 0) ? true : false;
    this.isHearAboutValid = this.setHearAboutValid(selectedOption);
    this.setHearAboutValidity.emit(this.isHearAboutValid);
    this.setHearAboutValue.emit((selectedOption?.id) ? selectedOption : null);
  }

  onOtherHearAboutChange(): void {
    this.isHearAboutValid = this.setHearAboutValid();
    this.setHearAboutValidity.emit(this.isHearAboutValid);
    this.setHearAboutValue.emit({ id: "0", text: this.otherHearAboutValue });
  }

  private setHearAboutValid(option?: any) {
    if (!this.showHearAboutOtherInput && option?.id) {
      return true;
    }
    if (this.showHearAboutOtherInput && this.otherHearAboutValue) {
      return true;
    } else {
      return false;
    }
  }
}

export class RegistrationHearAboutOptions {
  country: string;
  options: Array<{
    id: string
    text: string
  }>
  role: string;
}

export class HearAboutUsChoice {
  id: string;
  text: string;
}