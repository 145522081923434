<section class="searchbar__section">

	<div class="searchbar-header__container">
		<div class="searchbar__text">
			<ng-container *ngIf="isFirstSection">
				<h1 *ngIf="title" class="searchbar__text__title" [innerHTML]="title"></h1>
				<h1 *ngIf="!title && description; else description2" class="searchbar__text__description"
					[innerHTML]="description | unescape">
				</h1>
				<ng-template #description2>
					<h2 *ngIf="description" class="searchbar__text__description" [innerHTML]="description | unescape">
					</h2>
				</ng-template>
			</ng-container>
			<ng-container *ngIf="!isFirstSection">
				<h2 *ngIf="title" class="searchbar__text__title" [innerHTML]="title"></h2>
				<h3 *ngIf="description" class="searchbar__text__description" [innerHTML]="description | unescape"></h3>
			</ng-container>
		</div>
		<app-search></app-search>
	</div>
</section>