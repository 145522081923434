<section class="action-header-two__section">
	<div *ngIf="guaranteeLargeText || guaranteeSmallText" class="guarantee-wrapper">
		<div class="guarantee-content">
			<span *ngIf="guaranteeLargeText" class="guarantee-content-large">
				{{guaranteeLargeText | translate}}
			</span>
			<span *ngIf="guaranteeSmallText" class="guarantee-content-small">
				{{guaranteeSmallText | translate}}
			</span>
		</div>
	</div>
	<div
		class="action-header-two__section__background action-header-two__section__background--{{utilityService.country}} action-header-two__section__background--home visible">
	</div>

	<div class="action-header-two__container">
		<div class="action-header-two__text">
			<ng-container *ngIf="isFirstSection">
				<h1 *ngIf="title" class="action-header-two__text__title" [innerHTML]="title"></h1>
				<h1 *ngIf="description && !title; else description2" class="action-header-two__text__description"
					[innerHTML]="description | translate | unescape">
				</h1>
				<ng-template #description2>
					<h2 *ngIf="description" class="action-header-two__text__description" [innerHTML]="description | translate | unescape">
					</h2>
				</ng-template>
			</ng-container>
			<ng-container *ngIf="!isFirstSection">
				<h2 *ngIf="title" class="action-header-two__text__title" [innerHTML]="title"></h2>
				<h3 *ngIf="description" class="action-header-two__text__description" [innerHTML]="description | translate | unescape">
				</h3>
			</ng-container>
		</div>
		<div class="action-header-two__text__button-wrapper">
			<ng-container *ngIf="showLeftButton && buttonLeftLink && buttonLeftText">
				<a *ngIf="!preparedLinkLeft.urlObject; else usehref" class="action-header-two__text__button--left"
					[routerLink]="[preparedLinkLeft.url] | localize">{{buttonLeftText | translate}}</a>
				<ng-template #usehref>
					<a class="action-header-two__text__button--left" [href]="preparedLinkLeft.url">{{buttonLeftText |
						translate}}</a>
				</ng-template>
			</ng-container>
			<ng-container *ngIf="showRightButton && buttonRightLink && buttonRightText">
				<a *ngIf="!preparedLinkRight.urlObject; else usehref" class="action-header-two__text__button--right"
					[routerLink]="[preparedLinkRight.url] | localize">{{buttonRightText | translate}}</a>
				<ng-template #usehref>
					<a class="action-header-two__text__button--right" [href]="preparedLinkRight.url">{{buttonRightText |
						translate}}</a>
				</ng-template>
			</ng-container>
		</div>
		<div class="action-header-two__text__bottom">
			<ng-container *ngIf="utilityService.country == 'de'">
				<i class="fa fa-phone-alt"></i>
				<span>{{(variableService.contactVariable$ | async)?.contactPhoneDisplayed}}</span>
			</ng-container>
		</div>
	</div>

</section>

<app-storybrand-steps *ngIf="showStorybrandSteps"></app-storybrand-steps>

<app-logo-group *ngIf="showLogoGroup"></app-logo-group>

<div *ngIf="showEmpathy" class="action-header__empathy">
	<hr *ngIf="showLogoGroup" class="action-header__empathy__divider" />
	<h3 class="action-header__empathy__description">
		<div>
			<span>
				<span>
					{{'texts.ActionHeader_empathy_text1' | translate}}
				</span>
			</span>
			<span class="action-header__empathy__description--highlighted">
				<span>
					{{'texts.ActionHeader_empathy_text2' | translate}}
				</span>
			</span>
			<span>
				<span>
					{{'texts.ActionHeader_empathy_text3' | translate}}
				</span>
				<span class="action-header__empathy__description--highlighted">
					<span>
						{{'texts.ActionHeader_empathy_text4' | translate}}
					</span>
				</span>
				<span>
					<span>
						{{'texts.ActionHeader_empathy_text5' | translate}}
					</span>
				</span>
			</span>
		</div>
		<div>
			{{'texts.ActionHeader_empathy_text6' | translate}}
		</div>
	</h3>
</div>