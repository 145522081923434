import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/modules/shared/shared.module';

@Component({
    selector: 'app-confirm-base',
    standalone: true,
    imports: [CommonModule, SharedModule],
    template: '',
})
export class ConfirmBaseComponent {
    @Input('dialogTitle') dialogTitle: string = 'texts.Modal_Confirm_Title';
    @Input('dialogText') dialogText: string
    @Input('dialogConfirmButton') dialogConfirmButton: string = 'texts.Modal_Confirm_Button';
    @Input('dialogCancelButton') dialogCancelButton: string = 'texts.Modal_Confirm_Button';
    @Input('showTitle') showTitle: boolean = true;
    @Input('showSeparator') showSeparator: boolean = true;
    @Output() onConfirm = new EventEmitter();
    @Output() onClose = new EventEmitter();

    showCloseButton: boolean
    showCancelButton: boolean

    constructor() { }
}
