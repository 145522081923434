<app-login-or-register-frame [isCloseButtonVisible]="isCloseButtonVisible">
    <div *ngIf="hideSteps" class="registration-type-step">
        <div class="dialog-title before-steps-title" translate>texts.Student_Registration_Dialog_Title</div>
        <div class="option-button button" (click)="googleRegister()">{{'texts.Misc_register_with_Google'
            | translate}} &nbsp;<img class="google-logo"
                src="https://media.bijlesaanhuis.nl/logos/google-reviews-logo.png">
        </div>
        <div class="or-text decorated-text" translate>texts.Misc_Or</div>
        <div class="option-button button" (click)="hideSteps = false">
            <div translate>texts.Misc_Register_With_Email_Button &nbsp;</div>
            <div><app-svg-icon name="mail-icon"></app-svg-icon></div>
        </div>
        <div class="bottom-info"><a (click)="openLogin()"
                translate>texts.New_login_or_register_dialog_login_button_text</a>
            <span translate>texts.Misc_Or</span> <a [routerLink]="['/TutorRegistration_TeacherInfo'] | localize"
                (click)="closeDialog()" translate>texts.Misc_register_as_tutor</a>
        </div>
    </div>
    <form *ngIf="!hideSteps" [formGroup]="form" #ngForm="ngForm"
        (ngSubmit)="form.valid && studentPhone.valid && save()">
        <app-stepper #stepper>

            <div class="first-step" *ngIf="!studentData.googleId">
                <div class="dialog-title" translate>texts.Student_Registration_Dialog_Title</div>
                <div class="input-title" translate>texts.Misc_field_email</div>
                <div>
                    <input class="input-field" formControlName="email" value=""
                        [placeholder]="'texts.Misc_field_email' | translate" required="true"
                        (keyup)="isEmailDuplicate = false;" (change)="validateEmailInput(); isEmailDuplicate = false;">
                    <div class="error-message" [@errorMessage] *ngIf="emailDuplicateState">
                        {{'texts.TutorRegistrationSection_email_duplicate_error'
                        | translate}}</div>
                    <div class="error-message" [@errorMessage] *ngIf="emailFormatInvalid">
                        {{'texts.Misc_field_email_message_error' |
                        translate}}</div>
                    <app-input-required-error *ngIf="showError"
                        [inputControl]="form.controls.email"></app-input-required-error>
                </div>
                <div class="input-title" translate>texts.Misc_field_password</div>
                <div class="password">
                    <input class="input-field" matInput [type]="hidePassword ? 'password' : 'text'"
                        [placeholder]="'texts.Misc_field_password' | translate" required=""
                        (input)="validatePassword($event)" #passwordInput formControlName="password">
                    <button (click)="hidePassword = !hidePassword" class="password-toggle"
                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword" tabindex="-1">
                        <i *ngIf="!hidePassword" class="fas fa-eye-slash"></i>
                        <i *ngIf="hidePassword" class="fas fa-eye"></i>
                    </button>
                </div>
                <div class="error-message password-error" [@errorMessage]
                    *ngIf="!isPasswordValid && !(form.controls.password.touched && form.controls.password.value === '')">
                    {{ 'texts.Modal_please_type_valid_password' | translate }}
                </div>

                <app-input-required-error *ngIf="showError"
                    [inputControl]="form.controls.password"></app-input-required-error>
                <div class="password-validation-section" [ngClass]="{'touched': passwordTouched}">
                    <div class="password-info info-text" translate>texts.Student_Registration_password_info</div>
                    <ul>
                        <li class="list-item"><app-svg-icon *ngIf="minLengthMet"
                                name="password-check"></app-svg-icon><app-svg-icon *ngIf="!minLengthMet"
                                name="password-x"></app-svg-icon>
                            <div translate>
                                texts.Misc_password_validation_min_characters</div>
                        </li>
                        <li class="list-item"><app-svg-icon *ngIf="uppercaseMet"
                                name="password-check"></app-svg-icon><app-svg-icon *ngIf="!uppercaseMet"
                                name="password-x"></app-svg-icon>
                            <div translate>
                                texts.Misc_password_validation_uppercase</div>
                        </li>
                        <li class="list-item"><app-svg-icon *ngIf="lowercaseMet"
                                name="password-check"></app-svg-icon><app-svg-icon *ngIf="!lowercaseMet"
                                name="password-x"></app-svg-icon>
                            <div translate>
                                texts.Misc_password_validation_lowercase</div>
                        </li>
                        <li class="list-item"><app-svg-icon *ngIf="numberMet"
                                name="password-check"></app-svg-icon><app-svg-icon *ngIf="!numberMet"
                                name="password-x"></app-svg-icon>
                            <div translate>
                                texts.Misc_password_validation_number</div>
                        </li>
                    </ul>
                </div>
                <div class="name-info">
                    <div class="flex-box">
                        <div class="input-title" translate>texts.Student_Registration_Student_Firstname</div>
                        <input class="small-input-field input-field"
                            [placeholder]="'texts.Misc_field_firstname' | translate" required=""
                            formControlName="firstName">
                        <app-input-required-error *ngIf="showError"
                            [inputControl]="form.controls.firstName"></app-input-required-error>
                    </div>
                    <div class="flex-box">
                        <div class="input-title" translate>texts.Student_Registration_Student_Lastname</div>
                        <input class="small-input-field input-field"
                            [placeholder]="'texts.Misc_field_lastname' | translate" required=""
                            formControlName="lastName">
                        <app-input-required-error *ngIf="showError"
                            [inputControl]="form.controls.lastName"></app-input-required-error>
                    </div>
                </div>
                <div class="stepper-button button" (click)="nextStep()">
                    <div translate>texts.Misc_next_button</div>
                </div>
            </div>

            <div class="stepper-step">
                <div class="back-button" *ngIf="!studentData.googleId" (click)="prevStep()"><app-svg-icon
                        name="Alt_Arrow_Left"></app-svg-icon></div>
                <div class="section-title" translate>texts.Modal_register_student_tutoring_duration</div>
                <div *ngFor="let option of tutoringDurationOptions">
                    <label class="options-container"
                        [ngClass]="{'selected': option.duration === form.get('expectedTutoringDuration').value}">
                        <input type="radio" [value]="option.duration" formControlName="expectedTutoringDuration"
                            (click)="stepper.delayedNextStep(500)">
                        <div class="option-text">{{ option.translation }}</div>
                    </label>
                </div>
                <div class="button stepper-button" (click)="stepper.nextStep()" *ngIf="showNextButton">
                    <div translate>texts.Misc_next_button</div>
                </div>
            </div>
            <div class="stepper-step">
                <div class="back-button" (click)="prevStep()"><app-svg-icon name="Alt_Arrow_Left"></app-svg-icon></div>
                <div class="dialog-title" translate>texts.Student_Registration_Parent_Information_Title</div>
                <div class="info-text" translate>texts.Student_Registration_Parent_Information_Text</div>
                <div class="input-title" translate>texts.Misc_parent_phone_number_field_title</div>
                <div class="input-field">
                    <input class="input-field" type="tel" id="telephone" #phoneInput [formControl]="studentPhone"
                        [placeholder]="'texts.Misc_field_phone' | translate" required>
                </div>
                <label *ngIf="studentPhone?.invalid && (studentPhone?.dirty || studentPhone?.touched)"
                    class="error-message phone-error" [@errorMessage]>
                    {{ 'texts.Misc_field_phone_message_error' | translate }}
                </label>
                <button class="button stepper-button" type="submit" [disabled]="studentPhone?.value?.length < 8"
                    translate>
                    texts.Misc_register_button
                </button>

                <div class="bottom-text" translate>texts.Misc_register_tac_text<a
                        [routerLink]="['/Misc_terms'] | localize" target="_blank">{{ 'texts.Misc_terms_and_conditions' |
                        translate
                        }}</a>
                </div>
                <re-captcha #recaptchaComponent (resolved)="reCaptchaResolved($event)" (error)="reCaptchaError($event)"
                    [siteKey]=siteKey errorMode="handled" size="invisible">
                </re-captcha>
            </div>
        </app-stepper>
    </form>
</app-login-or-register-frame>