<section class="appointment-trial">
  <h2 class="appointment-trial__title">{{'texts.AppointmentTrialComponent__title' | translate }}</h2>
  <div class="appointment-trial__details">
    <p class="appointment-trial__description">{{'texts.AppointmentTrialComponent__description' | translate }}</p>
    <a class="appointment-trial__button" [href]="'ROUTES.Online_session_guide' | translate"
      target="_blank">{{'texts.AppointmentTrialComponent__instructions' | translate }}</a>
  </div>
	<div class="appointment-trial__details">
    <p class="appointment-trial__description">{{'texts.AppointmentTrialComponent__description__two' | translate }}</p>
		<a class="appointment-trial__button" [href]="'OnlineDemoSection' | localize" target="_blank">{{'texts.AppointmentTrialComponent__link' | translate }}</a>
	</div>
</section>