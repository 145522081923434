import { Component, Input, OnInit } from '@angular/core';
import { PageSection } from 'app/models';

@Component({
	selector: 'app-cms-base',
	templateUrl: './cms-base.component.html',
	styleUrls: ['./cms-base.component.scss']
})
export class CmsBaseComponent implements OnInit {

	@Input()
	section: PageSection = new PageSection({});
	position!: number;
	isFirstSection: boolean = false;

	ngOnInit(): void {
		if (!this.section) {
			return;
		}
		Object.assign(this, this.section.data);
		this.position = this.section.position;

		this.isFirstSection = this.position != null && (this.position == 0 || this.position == 1);
	}

}
