<app-info-wrapper>
	<h1 info-page-header>
		{{title | translate}}
	</h1>



	<div [formGroup]="submitForm" info-page-body>
		<p *ngIf="description" [innerHTML]="description | translate | unescape"></p>
		<!-- TODO: Support of passwort fields with icons on the right that uncover the string -->
		<div *ngFor="let field of inputFields" class="input-field">
			<label *ngIf="field.label" class="input-label" [ngClass]="field.labelClass" [for]="field.input.id">
				{{field.label | translate}}
				<span *ngIf="field.iconLabel" class="input-label__icon">
					<i class="fas" [ngClass]="'fa-' + field.iconType"></i>
				</span>
			</label>
			<div class="input__icon-wrapper">
				<input [formControlName]="field.input.name" [type]="field.input.type ? field.input.type : 'text'"
					[placeholder]="field.input.placeholder | translate" class="input-text"
					[attr.required]="field.input.required">
				<span *ngIf="field.inputIcon && field.inputIcon.icon" class="input__icon">
					<i [class]="field.inputIcon.icon" aria-hidden="true"></i>
				</span>
			</div>
			<app-input-error [element]="submitForm.get(field.input.name)" [type]="field.input.name"></app-input-error>
		</div>
		<div *ngIf="isLogin" class="recaptcha-input">
			<span class="msg-error error"></span>
			<re-captcha id="recaptcha" class="g-recaptcha" formControlName="captcha" (resolved)="resolved($event)"
				[siteKey]="siteKey" required></re-captcha>
		</div>
		<ng-template #inputInsert></ng-template>
	</div>
	<div *ngIf="footerContent" info-page-footer>
		<p *ngIf="footerContent.description" [innerHTML]="footerContent.description | translate"></p>

		<div *ngIf="footerContent.links">
			<div *ngFor="let link of footerContent.links; let i = index">
				<span *ngIf="i > 0" class="link-divider">&nbsp;/&nbsp;</span>
				<a *ngIf="!footerContent.links.preparedLink?.urlObject" class="info-page__link"
					[routerLink]="[link.preparedLink.url] | localize">{{link.text | translate}}</a>
				<a *ngIf="link.preparedLink.urlObject" class="info-page__link"
					[href]="link.preparedLink.url">{{link.text |
					translate}}</a>
			</div>
		</div>
		<div *ngIf="isLogin" class="info-page__register">
			<a class="info-page__link info-page__link--register" (click)="popupService.showRegisterModal()">
				{{'texts.InfoPageComponent_student_register' | translate}}
			</a>&nbsp;/&nbsp;
			<a class="info-page__link info-page__link--register"
				[routerLink]="['/TutorRegistration_TeacherInfo'] | localize">
				{{'texts.InfoPageComponent_tutor_register' | translate}}
			</a>
		</div>
	</div>
</app-info-wrapper>