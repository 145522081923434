import { ActivatedRouteSnapshot, RouteReuseStrategy, DetachedRouteHandle } from '@angular/router';

/** Interface for object which can store both:
 * An ActivatedRouteSnapshot, which is useful for determining whether you should attach a route (see this.shouldAttach)
 * A DetachedRouteHandle, which is offered up by `this.retrieve`, in the case that you do want to attach the stored route
 */
interface RouteStorageObject {
    snapshot: ActivatedRouteSnapshot;
    handle: DetachedRouteHandle;
}

export class CustomReuseStrategy implements RouteReuseStrategy {


    /**
     * Object which will store RouteStorageObjects indexed by keys
     * The keys will all be a path (as in route.routeConfig)
     * This allows us to see if we've got a route stored for the requested path
     */
    storedRoutes: { [key: string]: RouteStorageObject } = {};

    /**
     * Decides when the route should be stored
     * If the route should be stored, I believe the boolean is indicating to a controller whether to fire `this.store`
     * _When_ it is called though does not particularly matter, just know that this determines whether we store the route
     * An idea of what to do here: check the route.routeConfig to see if it is a path you would like to store
     * @param route This is, at least as I understand it, the route that the user is currently on, and we would like to know if we want to store it
     * @returns boolean indicating that we want to (true) or do not want to (false) store that route
     */
    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return route.data['storeState'];
    }

    /**
     * Constructs object of type `RouteStorageObject` to store, and then stores it for later attachment
     * @param route This is stored for later comparison to requested routes, see `this.shouldAttach`
     * @param handle Later to be retrieved by `this.retrieve`, and offered up to whatever controller is using this class
     */
    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
        const storedRoute: RouteStorageObject = {
            snapshot: route,
            handle: handle
        };

        const key = this.getRouteKey(route);


        // routes are stored by path - the key is the path name, and the handle is stored under it so that you can only ever have one object stored for a single path
        this.storedRoutes[key] = storedRoute;
    }

    /**
     * Determines whether there is a stored route and, if there is, whether it should be rendered in place of requested route
     * @param route The route the user requested
     * @returns boolean indicating whether to render the stored route
     */
    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        const key = this.getRouteKey(route);

        // this will be true if the route has been stored before
        let canAttach: boolean = !!route.routeConfig && !!this.storedRoutes[key];

        // this decides whether the route already stored should be rendered in place of the requested route, and is the return value
        // at this point we already know that the paths match because the storedResults key is the route.routeConfig
        // so, if the route.params and route.queryParams also match, then we should reuse the component
        if (canAttach) {
 
            const paramsMatch: boolean = this.compareObjects(route.params, this.storedRoutes[key].snapshot.params);
            const queryParamsMatch: boolean = this.compareObjects(route.queryParams, this.storedRoutes[key].snapshot.queryParams);
            canAttach = paramsMatch && queryParamsMatch

            if(!canAttach) {
               this.clearSavedHandle(key);
            }
            return canAttach;
        } else {
            return false;
        }
    }

    /**
     * Finds the locally stored instance of the requested route, if it exists, and returns it
     * @param route New route the user has requested
     * @returns DetachedRouteHandle object which can be used to render the component
     */
    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
        const key = this.getRouteKey(route);

        // return null if the path does not have a routerConfig OR if there is no stored route for that routerConfig
        if (!route.routeConfig || !this.storedRoutes[key]) return null;

        /** returns handle when the key is already stored */
        return this.storedRoutes[key].handle;
    }

    /**
     * Determines whether the current route should be reused
     * @param future The route the user is going to, as triggered by the router
     * @param curr The route the user is currently on
     * @returns boolean basically indicating true if the user intends to leave the current route
     */
    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        if (future.routeConfig === curr.routeConfig) {
            return !future.data['alwaysRefresh'];
        } else {
            return false;
        }
    }

    public clearSavedHandle(key: string): void {
        if (this.storedRoutes[key].handle) {
            (this.storedRoutes[key].handle as any).componentRef.destroy();
        }
        delete this.storedRoutes[key];
    }

    private compareObjects(base: object, compare: object): boolean {
        return JSON.stringify(base) === JSON.stringify(compare);
    }

    private getRouteKey(route: ActivatedRouteSnapshot): string {
        return route.pathFromRoot.filter(u => u.url).map(u => u.url).join('/');
    }

}
