import { Component, Input, OnInit } from '@angular/core';
import { VariableService } from '../../../services';
import { CmsBaseComponent } from '../cms-base/cms-base.component';

@Component({
	selector: 'app-contact-header-section',
	templateUrl: './contact-header-section.component.html',
	styleUrls: ['./contact-header-section.component.scss']
})
export class ContactHeaderSectionComponent extends CmsBaseComponent implements OnInit {
	@Input()
	title: string = 'texts.Misc_contact';

	constructor(
		public variableService: VariableService,
	) {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();
	}

}
