export enum UserType {
    student = 'student',
    parent = 'parent',
}

export enum CompleteProfileFor {
    intro = 'intro',
    lesson = 'lesson',
    message = 'message',
    package = 'package',
    edit = 'edit'
}

export interface DetailsForCompleteProfile {
    tutorName?: string
    completeProfileFor: CompleteProfileFor
}