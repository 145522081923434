import { EventEmitter, Injectable } from "@angular/core";
import { Subject, Observable, Observer } from "rxjs";

@Injectable({
	providedIn: 'root'
})
export class WebsocketService {

	ws: WebSocket;
	isSocketAlive: boolean;

	private subject: Subject<MessageEvent>;

	public connect(url): Subject<MessageEvent> {
		if (!this.isSocketAlive) {
			this.subject = this.create(url);
		}
		return this.subject;
	}

	onOpen = new EventEmitter();
	onErrorClose = new EventEmitter();

	close() {
		this.isSocketAlive = false;
		this.ws.close();
		this.ws = null;
		this.subject = null;
	}

	private create(url): Subject<MessageEvent> {
		this.ws = new WebSocket(url);

		let observable = Observable.create((obs: Observer<MessageEvent>) => {
			this.ws.onopen = () => {
				this.isSocketAlive = true;
				this.onOpen.emit();
			};
			this.ws.onmessage = obs.next.bind(obs);
			this.ws.onerror = (error) => this.onError(error);
			this.ws.onclose = (close) => this.onClose(close);
			return this.ws.close.bind(this.ws);
		});
		let observer = {
			next: (data: Object) => {
				if (this.ws.readyState === WebSocket.OPEN) {
					this.ws.send(JSON.stringify(data));
				}
			}
		};
		return Subject.create(observer, observable);
	}

	private onError(e: Event) {
		if (this.ws.readyState == WebSocket.CLOSED && this.isSocketAlive) {
			this.close();
			this.onErrorClose.emit()
		}
	}

	private onClose(e: CloseEvent) {
		if (this.isSocketAlive) {

			// If isSocketAlive is true, The socket was killed by the server or due to other issue, not by the client
			this.close();
			this.onErrorClose.emit();
		}
	}
}
