import { NewAddressFormComponent } from '../../../new-address-form/new-address-form.component';
import { ChangeDetectorRef, Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepperComponent } from '../../../stepper/stepper.component';
import { CompleteProfileFor, DetailsForCompleteProfile, UserType } from '../models/additional-information.model';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { SharedModule } from 'app/modules/shared/shared.module';
import { AuthService, GeoService, UtilityService } from 'app/services';
import { Address, UserObj } from 'app/microservice-clients/user';

@Component({
  selector: 'app-additional-information',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, StepperComponent, SharedModule, NewAddressFormComponent],
  templateUrl: './additional-information.component.html',
  styleUrls: ['./additional-information.component.scss']
})
export class AdditionalInformationComponent {

  @ViewChild('stepper') stepper: StepperComponent
  @ViewChild('addressForm') addressForm: NewAddressFormComponent
  @Output('onProfileCompleted') onProfileCompleted = new EventEmitter()

  userType: UserType
  personalDataForm: FormGroup = new FormGroup({});
  locationDataForm: FormGroup = new FormGroup({});
  activeTabIndex: number = 0
  showPersonalDataError: boolean = false
  detailsForCompleteProfile: DetailsForCompleteProfile = { tutorName: '', completeProfileFor: null }

  get UserType(): typeof UserType {
    return UserType;
  }

  get CompleteProfileFor(): typeof CompleteProfileFor {
    return CompleteProfileFor;
  }
  constructor(
    public cdr: ChangeDetectorRef,
    public formBuilder: FormBuilder,
    public geoService: GeoService,
    public utilityService: UtilityService,
    public authService: AuthService,
  ) {
    this.personalDataForm = this.formBuilder.group({
      name: ['', Validators.required],
      surname: ['', Validators.required],
    });
    this.locationDataForm = this.formBuilder.group({
      city: ['', Validators.required],
      houseNumber: ['', Validators.required],
      postCode: ['', [Validators.required]],
      street: ['', Validators.required],
    });
  }

  toggleStudentOrParent(userType) {
    this.userType = userType
    if (userType === UserType.student) {
      this.personalDataForm.addControl('eMail', new FormControl('', [Validators.required, Validators.pattern('^[\\w-]+(\\.[\\w-]+)*@[\\w-]+(\\.[\\w-]+)*(\\.[a-zA-Z]{2,})$')]))
    } else if (this.personalDataForm.controls.eMail) {
      this.personalDataForm.removeControl('eMail')
    }
    this.stepper.delayedNextStep(500)
  }

  validatePersonalData() {
    if (this.personalDataForm.valid) {
      this.stepper.nextStep()
    } else {
      this.showPersonalDataError = true
    }
  }

  validateAndSubmitData() {
    this.cdr.detectChanges()
    if (this.locationDataForm.valid) {
      const locationData: Address = {
        city: this.locationDataForm.controls.city.value,
        houseNumber: this.locationDataForm.controls.houseNumber.value,
        postalCode: this.locationDataForm.controls.postCode.value,
        street: this.locationDataForm.controls.street.value,
        country: this.utilityService.country,
        coordinates: this.addressForm.geoService.coordinates,
        state: this.addressForm.geoService.state
      }

      let userObj: UserObj
      if (this.userType === UserType.parent && this.authService.userDetails.googleID) {
        userObj = {
          studentDetails: {
            parentInfo: {
              forename: this.authService.userDetails.personalInfo.forename,
              surname: this.authService.userDetails.personalInfo.surname,
              location: locationData
            },
          },
          personalInfo: {
            forename: this.personalDataForm.controls.name.value,
            surname: this.personalDataForm.controls.surname.value,
            location: locationData
          },
        }
      } else {
        userObj = {
          studentDetails: {
            parentInfo: {
              forename: this.personalDataForm.controls.name.value,
              surname: this.personalDataForm.controls.surname.value,
              location: locationData
            },
          },
          personalInfo: {
            location: locationData
          },
        }
      }
      userObj.studentDetails.parentInfo.emailAddress = this.userType === UserType.student ? this.personalDataForm.controls.eMail.value : this.authService.user.email
      this.authService.updateUserDetails(userObj).subscribe(resp => {
        this.onProfileCompleted.emit()
      })
    }
    else {
      this.addressForm.showError = true
    }
  }
}