export interface RefreshResponse {
	access?: string;
}

export interface EmailCheckResponse {
	email: string;
}

export interface SocialSignInResponse {
	url?: string;
}