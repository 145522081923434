import { Component, Input, OnInit } from '@angular/core';
import { CmsBaseComponent } from '../../cms/cms-base/cms-base.component';
import { PageConfigService, UtilityService } from '../../../services';
import { PreparedLink } from 'app/models';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-tutoring-info-section',
	templateUrl: './tutoring-info-section.component.html',
	styleUrls: ['./tutoring-info-section.component.scss']
})
export class TutoringInfoSectionComponent extends CmsBaseComponent implements OnInit {

	@Input()
	anchorLink: string
	@Input()
	buttonLink: string;
	preparedLink: PreparedLink;
	@Input()
	buttonText: string = 'texts.Misc_find_tutor';
	@Input()
	contents: any
	@Input()
	contentsText: any[]
	@Input()
	description: string
	@Input()
	hideButton: boolean
	@Input()
	imageSrc: string | any
	@Input()
	imageAltText: string
	@Input()
	overlayImageSrc: any
	@Input()
	mediaPosition: string
	@Input()
	mediaRightPositionClass: string
	@Input()
	mobileVideoPopup: string
	@Input()
	mobileVideoPopupMedia: string
	@Input()
	sectionBackgroundColor: string
	@Input()
	showImage: boolean
	@Input()
	showTutors: boolean
	@Input()
	showVideo: boolean
	@Input()
	subtitle: string
	@Input()
	title: string
	@Input()
	videoPopup: any[]
	@Input()
	videoSrcSet: string | any[]
	@Input()
	useLargeHeading: boolean = false;
	isVideoPopupVisible: boolean = false;

	constructor(
		public pageConfigService: PageConfigService,
		private translationService: TranslateService,
		public utilityService: UtilityService,
	) {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.translationService.get('ROUTES.Misc_tutor_search').subscribe(link => {
			this.preparedLink = this.utilityService.prepareLink(this.buttonLink, link);
		});
		this.buttonText = this.buttonText ? this.buttonText : 'texts.Misc_find_tutor';
		if (this.mediaPosition == "left") {
			this.mediaRightPositionClass = "";
		} else if (this.mediaPosition == "right") {
			this.mediaRightPositionClass = "tutoring-info--position-right";
		}
		if (this.mediaPosition == "top" || !this.showVideo && !this.showImage && !this.showTutors) {
			this.mediaRightPositionClass = "tutoring-info--position-top";
		}
		if (this.mediaPosition == "bottom" || !this.showVideo && !this.showImage && !this.showTutors) {
			this.mediaRightPositionClass = "tutoring-info--position-bottom";
		}
		this.mobileVideoPopupMedia = "";
		if (this.mobileVideoPopup) {
			this.mobileVideoPopupMedia = "tutoring-info--position-hidden";
		}
		if (this.showVideo) {
			if (this.imageSrc) {
				this.overlayImageSrc = {
					originalSource: this.imageSrc,
					title: this.imageAltText,
				}
			}
			if (this.contents) {
				this.overlayImageSrc.sources = this.contents[0] ?? this.contents;
			}
		}

		if (this.imageSrc) {
			if (typeof this.imageSrc === "string") {
				if (this.imageSrc.includes('default-')) {
					this.translationService.get('images.' + this.imageSrc).subscribe(translation => {
						this.imageSrc = this.utilityService.convertICUTranslatedObject(translation)
						if (this.imageAltText) {
							this.imageSrc.alt = this.imageAltText;
						}
					});
				} else {
					this.imageSrc = {
						src: this.imageSrc
					};
					if (this.contents?.[0]) {
						this.imageSrc.sources = this.contents[0];
					}
					if (this.imageAltText) {
						this.imageSrc.alt = this.imageAltText;
					}
				}
			}
		}

		this.useLargeHeading = this.useLargeHeading || this.isFirstSection;
	}

	showPopup(): void {
		this.isVideoPopupVisible = true;
	}

	hideVideoPopup(): void {
		this.isVideoPopupVisible = false;
	}
}
