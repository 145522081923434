import { GoogleOneLineReviewComponent } from '../../standalone-components/google-reviews/google-one-line-review/google-one-line-review.component';
import { GoogleStarsComponent } from '../../standalone-components/google-reviews/google-stars/google-stars.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewTrustpilotCarouselComponent } from './components/new-trustpilot-carousel/new-trustpilot-carousel.component';
import { SharedModule } from '../shared/shared.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';

@NgModule({
  declarations: [
    NewTrustpilotCarouselComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    LazyLoadImageModule,
    GoogleStarsComponent,
    GoogleOneLineReviewComponent
  ],
  exports:
    [
      NewTrustpilotCarouselComponent
    ]
})
export class NewTrustpilotCarouselModule { }
