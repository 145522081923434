import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PageConfigService } from '../../../services';
import { DOCUMENT, Location } from '@angular/common';

@Component({
	selector: 'app-action-button',
	templateUrl: './action-button.component.html',
	styleUrls: ['./action-button.component.scss']
})
export class ActionButtonComponent implements OnInit {
	tutorSearchLink?: string;
	newTutorNumber?: number;
	show: boolean = true;
	scrollShow: boolean = false;
	location: Location;

	constructor(
		public pageConfigService: PageConfigService,
		private translationService: TranslateService,
		location: Location,
		@Inject(DOCUMENT) private document: Document
		) {
		this.location = location;
	}

	ngOnInit(): void {
		const dateDiff = new Date().getTime() - new Date("07/12/2020").getTime();
		this.newTutorNumber = (100 + 20 * Math.round(Math.pow(((dateDiff) / 86400000 / 7), 0.65)));
		this.translationService.get('ROUTES.Misc_tutor_search').subscribe(link => {
			this.tutorSearchLink = link;
		});
	}

	@HostListener('window:scroll', ['$event'])
	setScrollShow() {
		let windowPos = this.document.documentElement.scrollTop;
		this.scrollShow = this.show && windowPos > 400 && !this.isTutorSearchAndMobile();
	}

	hideActionButton() {
		this.show = false;
	}

	clickAction(): void {
		if (this.isFindTutorPage()) {
			return;
		}
	}

	isFindTutorPage(): boolean {
		return this.location.path() == this.tutorSearchLink;
	}

	isTutorSearchAndMobile() {
		return this.document.body.offsetWidth < 768 && this.isFindTutorPage();
	}
}
