<div class="empathy-statement">
	<hr *ngIf="showLogoGroup" class="empathy-statement__divider" />
	<h3 class="empathy-statement__description">
		<div>
			<span>
				<span>
					{{'texts.SearchbarHeader_empathy_text1' | translate }}
				</span>
			</span>
			<span class="empathy-statement__description--highlighted">
				<span>
					{{'texts.SearchbarHeader_empathy_text2' | translate }}
				</span>
			</span>
			<span>
				<span>
					{{'texts.SearchbarHeader_empathy_text3' | translate }}
				</span>
				<span class="empathy-statement__description--highlighted">
					<span>
						{{'texts.SearchbarHeader_empathy_text4' | translate }}
					</span>
				</span>
				<span>
					<span>
						{{'texts.SearchbarHeader_empathy_text5' | translate }}
					</span>
				</span>
			</span>
		</div>
		<div>
			{{'texts.SearchbarHeader_empathy_text6' | translate }}
		</div>
	</h3>
</div>