import { PreparedLink } from './';

export interface FooterContent {
	description?: string;
	links?: Link[];
}

export interface InfoContent {
	title: string;
	inputFields: InputField[];
	inputSubmitButton: InputSubmitButton;
	footerContent: FooterContent;
	description: string;
	inputLinkButton: InputLinkButton;
}

export interface InputLinkButton {
	id: string;
	link: string;
	text: string;
}

export interface InputElement {
	id?: string;
	name?: string;
	class?: string;
	type?: string;
	value?: string;
	placeholder?: string;
	required?: boolean;
	disabled?: boolean;
	onclick?: any;
}

export interface InputField {
	inputIcon?: InputIcon;
	label?: string;
	labelClass?: string;
	iconType?: string;
	iconLabel?: string;
	input: InputElement;
	messageError?: string;
	maxWidth?: any;
}

export interface InputIcon {
	icon: string;
}

export interface InputSubmitButton extends InputField {
	middle?: boolean;
	orange?: boolean;
	small?: boolean;
}

export interface Link {
	text: string;
	link: string;
	preparedLink: PreparedLink;
}
