import { Inject, Injectable,  Optional, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { API_BASE } from '../tokens/api-base';

@Injectable({
	providedIn: 'root'
})
export class TranslationHelperService {
	constructor(
		@Inject(PLATFORM_ID) private platformID,
		@Optional() @Inject(API_BASE) private apiBaseUrl,
	) {
	}

	get apiBase() {
		if (isPlatformServer(this.platformID)) {
			return `https://${this.apiBaseUrl}`;
		} else {
			return '';
		}
	}
}
