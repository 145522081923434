import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { CmsBaseComponent } from '../cms-base/cms-base.component';

@Component({
	selector: 'app-center-text',
	templateUrl: './center-text-section.component.html',
	styleUrls: ['./center-text-section.component.scss']
})
export class CenterTextSectionComponent extends CmsBaseComponent implements OnInit {
	@Input()
	supertitle?: string;
	@Input()
	title?: string;
	@Input()
	subtitle?: string;
	@Input()
	description?: string;
	@Input()
	anchorLink?: string;

	private _sectionBackgroundColor: string = '';
	sectionBackground?: SafeStyle;
	get sectionBackgroundColor() {
		return this._sectionBackgroundColor;
	}
	@Input() set sectionBackgroundColor(val: string) {
		this._sectionBackgroundColor = val;
		this.sectionBackground = this._sanitizer.bypassSecurityTrustStyle(this.sectionBackgroundColor);
	}

	private _textColor: string = '';
	color?: SafeStyle;
	get textColor() {
		return this._textColor;
	}
	@Input() set textColor(val: string) {
		this._textColor = val;
		this.color = this._sanitizer.bypassSecurityTrustStyle(this.textColor);
	}

	constructor(private _sanitizer: DomSanitizer) {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();
	}

}
