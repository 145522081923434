import { Inject, Injectable } from '@angular/core';
import { LogType, LogRecord } from '../models';
import { LoggerEvents } from '../constants';
import { debounceTime, filter, Subject } from 'rxjs';
import { environment } from 'environments/environment';
import { DOCUMENT } from '@angular/common';
import { VERSION_HASH, VERSION_NAME } from '../../../version';
import { HttpClient } from '@angular/common/http';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  private logBuffer: LogRecord[] = [];
  private logEvent$ = new Subject<LoggerEvents>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private httpClient: HttpClient,
    private utilityService: UtilityService
  ) {
    this.logEvent$.pipe(
      debounceTime(5000),
      filter((event) => event === LoggerEvents.Flush))
      .subscribe(() => this.flushLogBuffer());
  }

  private flushLogBuffer() {
    const body = this.logBuffer.splice(0);

    if (body.length === 0) {
      return;
    }

    if (environment.envName !== 'local') {
      this.httpClient.post(`${this.utilityService.apiBase}/clientlog`, { payload: body }).subscribe(res => {
        this.logBuffer = [];
      }, err => {
        this.logBuffer = [];
      });
    }
  }

  public report(type: LogType, error: string) {
    // this mechanism is used only for client side errors
    if (!this.utilityService.isBrowser)
      return;

    if (!error)
      return;

    // TODO read it from the cookie from access
    const userId = this.utilityService.isBrowser ? localStorage.getItem('userId') : '';

    this.logBuffer.push({
      level: type.level,
      level_name: type.name,
      message: error,
      microservice: 'customer-client',
      userId: userId ? + userId : null,
      url: this.document.location?.href || 'Unknown URL',
      timestamp: new Date().toString(),
      appVersion: `${VERSION_NAME}.${VERSION_HASH}`,
      sessionId: this.utilityService.sessionId,
      device: navigator?.userAgent || ''
    });
    this.logEvent$.next(LoggerEvents.Flush);
  }

  public log(type: LogType, message: string, object?: any) {
    if (environment.production) {
      return;
    }
    if (type.name == 'ERROR') {
      console.error(message, object ? object : '');
      return;
    }
    if (type.name == 'INFO') {
      console.info(message, object ? object : '');
      return;
    }
  }
}
