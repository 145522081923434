import { CommonModule } from '@angular/common';
import { Component, Output, EventEmitter, Input } from '@angular/core';
import { SharedModule } from 'app/modules/shared/shared.module';
import { SearchService } from 'app/services/search.service';

@Component({
  selector: 'app-toggle-online-home',
  templateUrl: './toggle-online-home.component.html',
  styleUrls: ['./toggle-online-home.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedModule]
})

export class ToggleOnlineHomeComponent {
  @Input() hideAtHomeButton: boolean;
  @Input() hideOnlineButton: boolean;
  @Input() isOnline: boolean;
  @Input() isDisabled: boolean;
  @Output() isOnlineChange = new EventEmitter<boolean>();

  @Output() onStateChanged = new EventEmitter();

  changeState(value: boolean) {
    this.isOnline = value;
    this.isOnlineChange.emit(this.isOnline);
    this.onStateChanged.emit();
  }

  constructor(
    public searchService: SearchService,
  ) { }
}
