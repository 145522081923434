import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { Component } from '@angular/core';
import { SubscribedBaseComponent } from './subscribed-base.component';
import { SharedModule } from 'app/modules/shared/shared.module';
import { CommonModule } from '@angular/common';
@Component({
    selector: 'app-subscribed-dialog',
    standalone: true,
    imports: [CommonModule, SharedModule],
    templateUrl: './subscribed.component.html',
    styleUrls: ['./subscribed.component.scss']
})

export class SubscribedDialogComponent extends SubscribedBaseComponent {
    canNavigateToChat: boolean = false;
    constructor(
        private matDialogRef: MatDialogRef<SubscribedDialogComponent>,
        private router: Router
    ) {
        super()
        this.showCloseButton = true;
    }
    close() {
        this.matDialogRef.close();
        this.onClose.emit();
    }

    confirm() {
        if (this.canNavigateToChat) {
            this.router.navigate(['/chat']);
        }
        this.onClose.emit();
        this.matDialogRef.close()
    }
}
