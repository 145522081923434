import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContactVariables } from '../models';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root'
})
export class VariableService {

  contactVariable$!: Promise<ContactVariables>;

  constructor(
    private http: HttpClient,
    private utilityService: UtilityService,
  ) {
    this.setContactVariables();

  }

  //TODO remove this service move this to the proper use

  setContactVariables(): void {
    this.contactVariable$ = this.http.get<ContactVariables>(`${this.utilityService.apiBase}/api/admin/contactVariables`).toPromise();
  }

}
