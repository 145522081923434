import { SliderConfigModel } from './../../../models/slider-config-model';
import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UtilityService } from '../../../services';
import { ImageDataModel } from 'app/models/image-translation.model';

@Component({
	selector: 'app-logo-group',
	templateUrl: './logo-group.component.html',
	styleUrls: ['./logo-group.component.scss']
})
export class LogoGroupComponent {

	reviewWidget = true;
	logos: Array<ImageDataModel>
	carouselConfig: SliderConfigModel = {
		page: [
			{ size: 0.2, spacing: 0.8 },
			{ size: 0.2, spacing: 0.8 },
			{ size: 0.2, spacing: 0.8 },
			{ size: 0.2, spacing: 0.8 },
			{ perView: 1 }
		],
		alwaysShowDots: true,
		showArrows: true,
		loop: true,
		autoScroll: 5000
	}

	awardImage: ImageDataModel;

	@Input() showGoogle: boolean = false;

	constructor(
		public utilityService: UtilityService,
		private translationService: TranslateService,
	) {
		this.translationService.get([
			'images.LogoGroupComponent_img_1',
			'images.LogoGroupComponent_img_2',
			'images.LogoGroupComponent_img_3',
			'images.LogoGroupComponent_img_4',
			'images.LogoGroupComponent_img_5',
			'images.Family_award'
		]).subscribe(translation => {
			this.logos = [
				translation['images.LogoGroupComponent_img_1'],
				translation['images.LogoGroupComponent_img_2'],
				translation['images.LogoGroupComponent_img_3'],
				translation['images.LogoGroupComponent_img_4'],
				translation['images.LogoGroupComponent_img_5']
			]
			this.awardImage = translation['images.Family_award'];
		})
	}
}
