import { PopupService } from './popup.service';
import { AwsFieldsModel } from './../models/awsFields.model';
import { ApiService } from './api.service';
import { EventEmitter, Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as moment from 'moment';
import { BehaviorSubject, fromEvent, Observable, Subject, Subscription } from "rxjs";
import { debounceTime, map, takeUntil } from "rxjs/operators";
import { DirectMessage, MessageToSocket, MessageToUser, NotificationUserMessage, RoomMessage, User, UserMessage } from "../models";
import { MessageType, UserRole } from '../constants';
import { ToastService } from "./toast.service";
import { WebsocketService } from "./websocket.service";
import { UtilityService } from "./utility.service";
import { HttpParams } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class ChatService {

	messages: Subject<MessageToUser | MessageToSocket>;
	notifications: NotificationUserMessage = {};
	rooms: RoomMessage[];
	roomActive: RoomMessage;
	chatMessages: DirectMessage[] = [];
	chatMessage: DirectMessage;
	nextRoomId: string;
	initialMessage = { "action": "notifications.user" };
	messageStack: MessageToUser[] = [];
	CHAT_URL: string = "/message/ws";
	isSocketInitialized: boolean = false;
	lastChatInitDate: moment.Moment;
	isMoreMessages: boolean = true;
	isLoadMoreMessagesAllowed: boolean = true;
	isNewChatOpened: boolean = true;
	isChatVisible: boolean = false;
	openedRoomId: string;

	private wsOpenSubscription: Subscription;
	private wsRestartSubscription: Subscription;

	private onRestart: boolean;
	private user: User
	private visibilitySubDestroy$ = new Subject<void>();

	onNewMessage = new EventEmitter();
	onUnreadMessages = new EventEmitter();
	onNewChatMessages = new EventEmitter();

	rooms$ = new BehaviorSubject<Array<RoomMessage>>([]);
	onChatPartnerChange = new EventEmitter<UserMessage>();

	// OnReady's first value is null, true is when active and false when socket dies or closed
	onReady: BehaviorSubject<boolean> = new BehaviorSubject(null);

	private _chatPartner: UserMessage;

	set chatPartner(value: UserMessage) {
		this._chatPartner = value;
		this.onChatPartnerChange.emit(value);
	}

	get chatPartner() {
		return this._chatPartner;
	}

	constructor(
		public wsService: WebsocketService,
		private toastService: ToastService,
		private translationService: TranslateService,
		private utilityService: UtilityService,
		private apiService: ApiService,
		private popupService: PopupService
	) {
		if (this.utilityService.isBrowser) {
			this.CHAT_URL = `wss://${location.host}/message/ws`;
		}
	}

	subscribeMessages(): void {
		this.messages.subscribe((msg: MessageToUser) => {
			switch (msg.type) {
				case 'notifications.user':
					msg.data = new NotificationUserMessage(msg.data);
					this.notifications = msg.data;
					break;
				case 'rooms':
					msg.data = msg.data as RoomMessage[];
					this.rooms = msg.data;
					if (this.rooms?.length) {
						this.translationService.get('ROUTES.Navigation_messages').subscribe(translation => {
							const isChatPageOpened = location.pathname.includes(translation);
							//The roomActive Reference needs to be updated each time when the rooms are updated from the socket 
							if (isChatPageOpened) {
								if (this.roomActive) {
									this.roomActive = this.rooms.find(r => r.id == this.roomActive.id);
									this.setChatPartner();
									this.unReadMessages();
								} else {
									this.roomActive = this.rooms[0];
									this.setChatPartner();
								}
								if (this.onRestart) {
									this.getChatMessages();
								}
							}
							if (this.onRestart) {
								this.onRestart = false;
							}
							this.rooms$.next(this.rooms);
						})
					}

					if (!this.onReady.value) {
						// When the rooms are loaded, the chat service full connection is ready. 
						this.onReady.next(true);
					}
					break;
				case 'messages':
					msg.data = msg.data as DirectMessage[];
					this.isMoreMessages = msg?.data?.length || false;
					this.isLoadMoreMessagesAllowed = true;
					if (this.isMoreMessages) {
						this.isNewChatOpened = !this.isSameRoom(msg.data);
						if (this.isNewChatOpened) {
							this.lastChatInitDate = moment();
							this.chatMessages = msg.data;
							this.onNewChatMessages.emit();
						} else {
							this.chatMessages = this.chatMessages.concat(msg.data);
							this.markMessageRead(this.chatMessages[0].id)
						}
					}
					break;
				case 'message':
					this.trackFirstMessageEvent();
					this.onNewMessage.emit();
					msg.data = new DirectMessage(msg.data);
					this.sendMessage({ action: 'rooms.get' });
					if (!this.isChatVisible) {
						let toastMessage
						if (msg.data.user.Id != this.user.id) {
							toastMessage = msg.data.message && msg.data.message.includes('message.') ? 'texts.' + msg.data.message : msg.data.message;
							this.toastService.showMsg(toastMessage, MessageType.info,
								{
									title: msg.data.user.Forename ? msg.data.user.Forename : msg.data.user.forename,
									link: 'Navigation_messages/' + msg.data.room,
								});
						}
					} else if (this.roomActive?.id == msg.data.room) {
						this.chatMessage = msg.data;
						this.chatMessages = [this.chatMessage].concat(this.chatMessages);
						this.markMessageRead(this.chatMessage.id)
					}
					break;
			}
		});
	}

	openRoom(roomID: string): void {
		let roomActive = this.rooms.find(e => e.id == roomID);
		if (roomActive.id != this.roomActive?.id) {
			this.roomActive = roomActive;
			this.chatMessages = [];
			this.unReadMessages();
			this.getChatMessages();
			this.setChatPartner();
		}
	}

	setChatPartner() {
		this.chatPartner = this.getRoomContact();
	}

	unReadMessages() {
		this.roomActive.unread_messages = 0;
		this.onUnreadMessages.emit();
	}

	markMessageRead(messageId: string) {
		this.messages.next({ action: "message.seen", message: messageId })
	}

	getChatMessages(appendMessages = false): void {
		let before_message_id = '';
		if (appendMessages && this.chatMessages && this.chatMessages.length) {
			before_message_id = this.chatMessages[this.chatMessages.length - 1].id;
		}
		this.sendMessage({
			"action": "messages.get",
			"room": this.roomActive.id,
			"before_message": before_message_id,
			"limit": 8
		});
	}

	isSameRoom(data: DirectMessage[]): boolean {
		return this.chatMessages && this.chatMessages.length && this.chatMessages[0].room == data[0].room;
	}

	executeStack(): void {
		this.messageStack.forEach(message => {
			this.sendMessage(message);
		});
	}

	addStack(message: any) {
		this.messageStack.push(message);
	}

	sendMessage(message: any) {
		// Loading more messages while messages are already loaded is not allowed.
		if (message.action == 'messages.get') {
			if (!this.isLoadMoreMessagesAllowed) {
				return;
			}
			this.isLoadMoreMessagesAllowed = false;
		}
		this.messages.next(message);
	}

	sendInitialMessage() {
		this.sendMessage(this.initialMessage);
	}

	isReadyForScroll(): boolean {
		return this.lastChatInitDate && this.lastChatInitDate.isBefore(moment().subtract('1', 's'));
	}

	getRoomContact(): UserMessage {
		return this.roomActive.users.find(user => user.id != this.user.id);
	}

	init(loggedInUser: User) {
		this.user = loggedInUser;
		this.start();
		let warningDialog;
		this.onReady.pipe(debounceTime(3000)).subscribe(ready => {
			if (ready === false) {
				if (this.isChatVisible) {
					warningDialog = this.popupService.openInfoDialog({ title: 'texts.Warning_Title', description: 'texts.Warning_Chat_Socket' })
				}
			} else if (warningDialog) {
				warningDialog.close();
			}
		})
	}

	reStart() {
		// If the page reloads and the user is on Chat page this function might be called twice, this check is needed just make sure to do not open 2 connection
		if (!this.onReady.value && !this.isSocketInitialized) {

			this.onRestart = true;
			this.start();
		}
	}

	close() {
		this.messages = null;
		this.isSocketInitialized = false;
		this.messageStack = [];
		this.wsOpenSubscription?.unsubscribe();
		this.wsRestartSubscription?.unsubscribe();
		this.chatMessages = [];
		this.roomActive = null;
		this.rooms = [];
		this.rooms$.next([]);
		this.chatPartner = null;
		this.visibilitySubDestroy$.next();
	}

	private start() {
		this.wsOpenSubscription = this.wsService.onOpen.subscribe(() => {
			this.onSocketConnection();
		})

		this.connectWebSocket();

		this.subscribeMessages();

		this.addStack(this.initialMessage);

		this.addStack({ "action": "rooms.get" });

		if (this.utilityService.isBrowser) {
			const visibilitychange$ = fromEvent(document, 'visibilitychange');
			visibilitychange$.pipe(takeUntil(this.visibilitySubDestroy$)).subscribe(event => {
				if (!document.hidden && this.wsService.ws.readyState != 1) {
					if (this.onReady.value) {
						this.onReady.next(false);
					}
					this.close();
					this.reStart();
				}
			})
		}

		this.wsRestartSubscription = this.wsService.onErrorClose.subscribe(() => {
			// Websocket connection fails on Safari, while returning to the web app from the background 
			// The operation couldn’t be completed. (kNWErrorDomainPOSIX error 53 - Software caused connection abort)
			// Due to this issue, we close and reconnect
			if (this.onReady.value) {
				this.onReady.next(false);
			}
			this.close();
			this.reStart();
		})
	}

	private connectWebSocket(): void {
		// In case of the previous socket did not connect we are stopping it.
		if (!this.wsService.isSocketAlive && this.wsService.ws?.readyState === WebSocket.CONNECTING) {
			this.wsService.close();
		}

		this.messages = <Subject<MessageToUser>>this.wsService.connect(this.CHAT_URL).pipe(
			map(
				(response: MessageEvent): MessageToUser => {
					return response?.data ? JSON.parse(response.data) : {}
				}
			)
		);
	}

	private onSocketConnection() {
		this.isSocketInitialized = true;
		this.executeStack();
	}

	/**
	 * Verifying and sending conversion events to Google Tag Manager
	 */
	trackFirstMessageEvent(): void {
		const isFirstResponseMessage = !this.chatMessages.find(e => e.user_id == this.user.id)
		const isNewChat = this.chatMessages.length < 15;
		if (isFirstResponseMessage && isNewChat) {
			const hasMessageHistory = this.chatMessages.length;
			const hasOnlySystemMessages = !this.chatMessages.find(e => !e.system)
			const isStudent = this.user.role == UserRole.student;
			if (hasMessageHistory && hasOnlySystemMessages) {
				this.utilityService.trackEvent('conversion', 'first_message', (isStudent ? 'student' : 'teacher') + '_request_chat', this.chatPartner?.id);
			} else {
				this.utilityService.trackEvent('conversion', 'first_message', (isStudent ? 'student' : 'teacher') + '_response', this.chatPartner?.id);
			}
		}
	}

	getUploadFieldsForFile(filename: string): Observable<{ url: string, fields: AwsFieldsModel }> {
		return this.apiService.post('message/chat-file', {
			fileName: filename,
			roomId: this.roomActive.id
		})
	}

	getFileDownloadLink(fileKey: string): Observable<{ url: string }> {
		let params = new HttpParams()
			.set('fileKey', fileKey);

		return this.apiService.get('message/chat-file', params)
	}

	getAndFormatMessageText(message: string): { message: string, fileKey: string } {
		let fileKey;
		if (message && message.includes('message.')) {
			message = 'texts.' + message;
		} else if (message && message.includes('fileKey') && message.includes('fileName')) {
			const data = JSON.parse(message);
			message = data.fileName;
			fileKey = data.fileKey;
		}
		return { message: message, fileKey: fileKey }
	}

}