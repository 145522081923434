<section class="question-collection__section">
	<div *ngFor="let collection of contentsCollections, let i=index" class="question-collection__container">
		<div class="question-collection__text">
			<h1 *ngIf="isFirstSection && (i === 0) && collection.seperatorTitle" class="question-collection__text__seperator-title"
				[innerHTML]="collection.seperatorTitle"></h1>
			<h2 *ngIf="!isFirstSection || (i !== 0) && collection.seperatorTitle" class="question-collection__text__seperator-title"
				[innerHTML]="collection.seperatorTitle"></h2>
			<div *ngIf="!collection.seperatorTitle" class="question-collection__text__seperator-placeholder"></div>
			<h2 *ngIf="collection.title" class="question-collection__text__title" [innerHTML]="collection.title"></h2>
			<div *ngIf="!collection.title" class="question-collection__text__title-placeholder"></div>
		</div>
		<app-question-collection-card *ngFor="let question of collection.contents" [title]="question.title"
			[description]="question.description"></app-question-collection-card>
	</div>
</section>
