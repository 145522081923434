import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormGroupDirective, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MessageType } from 'app/constants';
import { AddCourseForRegisterModel, AddCourseModelForEdit } from 'app/models';
import { AddOrEditCourse, AddOrEditLevel, AddOrEditYear, AddOrEditSchoolType } from 'app/models/add-or-edit-school.model';
import { ToastService } from 'app/services';
import { UserClientService } from 'app/services/user-client.service';

@Component({
	selector: 'app-course-selection',
	templateUrl: './course-selection.component.html',
	styleUrls: ['./course-selection.component.scss']
})
export class CourseSelectionComponent implements OnInit {

	schoolTypes: Array<AddOrEditSchoolType> = [];
	selectedSchoolTypeId: string;

	selectedSchoolType: AddOrEditSchoolType;
	allCourses: Array<AddOrEditCourse> = [];
	availableCourses: Array<AddOrEditCourse> = [];
	selectedCourseId: number;
	selectedCourse: AddOrEditCourse;

	schoolYears: Array<AddOrEditYear> = []

	levels: Array<AddOrEditLevel> = [];
	selectedLevels = [];
	formattedLevels: any;
	courseForm: FormGroup;

	@Input() isSubmitAvailable: boolean = true;
	@Output() onAddCourse = new EventEmitter();

	constructor(
		public userClientService: UserClientService,
		private toastService: ToastService
	) { }

	ngOnInit(): void {
		this.getSchoolTypes();
		this.createForm();
	}

	getSchoolTypes() {
		this.userClientService.getDefaultSchoolTypesAndCourses().subscribe((response) => {
			this.schoolTypes = response.schoolTypes
		})
	}

	selectSchoolType(schoolType: AddOrEditSchoolType) {
		if (schoolType) {
			this.selectedSchoolType = schoolType;
			this.selectedSchoolTypeId = schoolType.id
			this.availableCourses = this.selectedSchoolType.courses
		} else {
			this.selectedSchoolType = null
			this.selectedSchoolTypeId = null
		}
		this.selectedCourseId = null;
		this.selectedCourse = null
		this.levels = null
	}

	selectCourse(course: AddOrEditCourse) {
		this.levels = []
		this.selectedCourseId = course.id
		this.selectedCourse = course
		if (course.levels[0]?.years) {
			this.courseForm.addControl('level', new UntypedFormControl(null, [Validators.required]))
			this.levels = course.levels
			this.levels.forEach(level => {
				level.years.forEach(year => {
					year._customLevelYearId = level.id + '-' + year.id
				})
			})
		} else {
			this.courseForm.removeControl('level')
		}
	}

	checkIfFilled() {
		this.courseForm.get('type').markAsTouched()
		this.courseForm.get('course').markAsTouched();
		if (this.selectedCourse?.levels[0]?.years) {
			this.courseForm.get('level').markAsTouched()
		} else {
			this.courseForm.removeControl('level')
		}
		return true
	}

	private createForm() {
		this.courseForm = new UntypedFormGroup({
			type: new UntypedFormControl(null, [
				Validators.required,
			]),
			course: new UntypedFormControl(null, [
				Validators.required,
			])
		});
	}

	resetAll() {
		this.selectedSchoolType = null;
		this.selectedSchoolTypeId = null;
		this.selectedCourseId = null;
		this.selectedCourse = null
		this.selectedLevels = [];
		this.levels = [];
	}

	onSelectAll(): void {
		this.selectedLevels = [];
		this.levels.forEach(level => {
			level.years.forEach(year => {
				this.selectedLevels = [...this.selectedLevels, year._customLevelYearId]
			})
		})
	}

	onClearAll(): void {
		this.selectedLevels = [];
	}

	saveCourse(formDirective: FormGroupDirective) {
		let course = this.getAddCourseObjectForEdit();
		this.userClientService.addTutorCourse(course).subscribe({
			next: () => {
				this.toastService.showMsg('texts.Misc_success', MessageType.success);
				this.onAddCourse.emit(course);
			},
			complete: () => {
				this.resetAll();
				formDirective.resetForm();
				this.courseForm.reset();
			}
		});
	}

	getAddCourseObjectForEdit() {
		if (this.selectedSchoolTypeId && this.selectedCourseId) {
			const course: AddCourseModelForEdit = {
				courseId: this.selectedCourseId,
				schoolTypeId: this.selectedSchoolTypeId.toString(),
			} as AddCourseModelForEdit;
			course.level = this.parseLevels();
			return course;
		} else {
			return null;
		}
	}

	getAddCourseObjectsForRegister() {
		let course: AddCourseForRegisterModel
		let courses: Array<AddCourseForRegisterModel> = []
		if (this.selectedLevels.length === 0) {
			course = {
				courseId: this.selectedCourseId,
				schoolLevelId: this.schoolTypes.find(e => e.id == this.selectedSchoolTypeId).courses.find(e => e.id == this.selectedCourseId).levels[0].id.toString(),
				schoolTypeId: this.selectedSchoolTypeId.toString()
			}
      courses.push(course)
		} else {
			this.selectedLevels.forEach(level => {
				course = {
					courseId: this.selectedCourseId,
					schoolTypeId: this.selectedSchoolTypeId.toString(),
					schoolLevelId: level.split('-')[0] ? level.split('-')[0] : "",
					schoolYearId: level.split('-')[1] ? level.split('-')[1] : ""
				} as AddCourseForRegisterModel;

        courses.push(course)
			})
		}
		return courses;
	}

	clearForm() {
		this.resetAll();
		this.courseForm.reset();
	}

	private parseLevels() {
		let parsedLevels = {};
		if (this.selectedLevels?.length > 0) {
			for (let level of this.selectedLevels) {
				let parsedLevel = level.split('-');
				if (!parsedLevels.hasOwnProperty(parsedLevel[0])) {
					parsedLevels[parsedLevel[0]] = {
						'yearIds': []
					};
				}
				parsedLevels[parsedLevel[0]].yearIds.push(parsedLevel[1]);
			}
		} else {
			const lowerSchoolCourselLevel = this.schoolTypes.find(e => e.id == this.selectedSchoolTypeId).courses.find(e => e.id == this.selectedCourseId).levels[0].id.toString();
			parsedLevels[lowerSchoolCourselLevel] = {};
		}
		return parsedLevels;
	}
}