import { Directive, ElementRef, Input } from "@angular/core";
import { UtilityService } from "app/services";

@Directive({
  selector: "[formatCurrency]",
  exportAs: "formatCurrency",
})

export class FormatCurrencyDirective {

  constructor(
    private element: ElementRef,
    private utilityService: UtilityService
  ) { }

  private _html;

  @Input("formatCurrency") set formatCurrency(value) {
    if (value !== null && value !== undefined) {
      this._html = value;
      this.element.nativeElement.innerHTML = this.formatCurrencyValue(value);
    }
  }

  get formatCurrency() { return this._html; }

  private formatCurrencyValue(currency: number | string) {
    let _currency;

    if (+currency % 1 != 0) {
      _currency = Number(currency).toFixed(2).toString().replace(/\./g, ',');
    } else {
      _currency = currency;
    }

    if (this.utilityService.isNL) {
      return `€ ${_currency}`;
    } else {
      return `${_currency} €`;
    }
  }
}