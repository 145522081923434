import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewAddressFormBaseComponent } from './new-addres-form-base';
import { SharedModule } from 'app/modules/shared/shared.module';

@Component({
  selector: 'app-new-address-form',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './new-address-form.component.html',
  styleUrls: ['./new-address-form.component.scss']
})
export class NewAddressFormComponent extends NewAddressFormBaseComponent {
  showError: boolean = false;
}
