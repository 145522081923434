import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/modules/shared/shared.module';

@Component({
    selector: '',
    standalone: true,
    imports: [CommonModule, SharedModule],
    template: '',
})

export class SubscribedBaseComponent {
    @Output() onClose = new EventEmitter();

    showConfirmButton: boolean = false;
    showCloseButton: boolean
}
