<section class="error-404__section">
	<div class="error-404__container">
		<div class="error-404__text">
			<div class="error-404__text__title" [innerHTML]="title"></div>
			<div class="error-404__text__description" [innerHTML]="description | unescape">
			</div>
		</div>
		<div class="error-404__button-wrapper">
			<a *ngIf="statusCode == 404; else error403" class="error-404__button" [routerLink]="['/']">
				{{'texts.Error404Section_go_to_homepage' | translate}}
			</a>

			<ng-template #error403>
				<a *ngIf="statusCode == 403; else error401" class="error-404__button" [routerLink]="['/']">
					{{'texts.Error403Section_go_to_homepage' | translate}}
				</a>
			</ng-template>

			<ng-template #error401>
				<button *ngIf="statusCode == 401 && !googleError;" type="button" class="error-404__button"
					(click)="popupService.showLoginModal()">
					{{'texts.Error401Section_log_in' | translate}}
				</button>

				<a *ngIf="statusCode == 401 && googleError;" class="error-404__button" [routerLink]="['/']">
					{{'texts.Error403Section_go_to_homepage' | translate}}
				</a>

			</ng-template>

			<ng-container *ngIf="statusCode == 500">
				<button type="button" class="error-404__button" (click)="reload()" style="margin-top:15px;">
					{{'texts.Error500Section_try_again' | translate}}
				</button>
				<hr style="max-width: 400px;margin: 1rem auto;" />
				<a class="error-404__button" [routerLink]="['/']">
					{{'texts.Error500Section_go_to_homepage' | translate}}
				</a>
			</ng-container>
		</div>
	</div>
</section>