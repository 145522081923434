import { Component, Input, OnInit } from '@angular/core';
import { CmsBaseComponent } from '../cms-base/cms-base.component';

@Component({
	selector: 'app-counting-number',
	templateUrl: './counting-number.component.html',
	styleUrls: ['./counting-number.component.scss']
})
export class CountingNumberComponent extends CmsBaseComponent implements OnInit {
	@Input() duration!: number;
	@Input() steps!: number;

	@Input() subtitle: string = ""
	@Input() title: number = 1
	@Input() unit: string = "%"

	currentValue: number = 0;

	constructor() {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();
	}

	animateCount() {
		const increment = Math.floor(this.title / this.steps);
		const animationSeconds = this.duration / this.steps;
		setTimeout(() => {
			if (this.currentValue + increment < this.title - increment) {
				this.currentValue += increment;
			} else {
				this.currentValue = this.title;
			}
			if (this.currentValue < this.title) {
				this.animateCount();
			}
		}, animationSeconds);
	}

	startCounter() {
		if (typeof this.duration === "undefined") {
			this.duration = 1500;
		}
		if (!this.steps) {
			this.steps = this.title / 50 > 1 ? 50 : 25;
		}
		if (this.title) {
			this.animateCount();
		}
	}
}
