import { Component } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "app/modules/shared/shared.module";
import { PackagesFlowService } from "app/services/packages-flow.service";
import { PackageDataModel } from "app/models/package.model";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { PaymentIntentStatus } from "app/models/payment-intent.model";
import { PaymentInfoForForRedirect } from "app/models/payment-info-for-redirect.model";

@Component({
  selector: "app-package-status",
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: "./package-status.component.html",
  styleUrls: ["./package-status.component.scss"],
})
export class PackageStatusComponent {
  
  constructor(
    public packagesFlowService: PackagesFlowService,
    private router: Router,
    private translationService: TranslateService,
  ) {}

  get PaymentIntentStatus(): typeof PaymentIntentStatus {
    return PaymentIntentStatus;
}

  retryPayment(packageData: PackageDataModel) {
    this.saveInfoForRedirect(packageData);
    if (packageData.paymentIntent.url) {
      this.redirectToStripe(
        packageData.paymentIntent.url
      );
    } else {
      this.packagesFlowService
        .initiatePaymentIntent(
          packageData.userPackage.userPackageId
        )
        .subscribe((response) => {
          this.redirectToStripe(response.url);
        });
    }
  }

  navigateToContactPage() {
    this.translationService.get("links.Misc_contact").subscribe((res) => {
      this.router.navigate([res]);
    })
  }

  private saveInfoForRedirect(userPackage: PackageDataModel) {
    const paymentInfoForRedirect: PaymentInfoForForRedirect = {
      redirectUrl: location.pathname + location.search,
      userPackage: userPackage.userPackage,
      paymentIntent: userPackage.paymentIntent
    }
    this.packagesFlowService.saveInfoInLocalStorageForRedirect(paymentInfoForRedirect);
  }

  private redirectToStripe(url: string): void {
    window.location.href = url;
  }
}
