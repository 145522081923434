<div *ngIf="element && element.invalid && (element.dirty || element.touched)">
	<label [ngClass]="{'visible': element.errors.minlength || element.errors.email}"
		class="input-message input-message--error">
		{{errorMessage | translate }}
	</label>
	<label [ngClass]="{'visible': false}" class="input-message input-message--change">
		{{changeMessage | translate}}
	</label>
	<label [ngClass]="{'visible': element.errors.required}" class="input-message input-message--required">
		{{requiredMessage | translate}}
	</label>
	<label [ngClass]="{'visible': specialCondition}" class="input-message input-message--error">
		{{specialMessage | translate}}
	</label>
	<label [ngClass]="{'visible': element.errors.min}" class="input-message input-message--error">
		{{'texts.Misc_min_max_tutor_travel_distance' | translate}}
	</label>
	<label [ngClass]="{'visible': element.errors.max}" class="input-message input-message--error">
		{{'texts.Misc_min_max_tutor_travel_distance' | translate}}
	</label>
	<label [ngClass]="{'visible': element.errors.phone}" class="input-message input-message--error">
		{{'texts.Misc_field_phone_message_error' | translate}}
	</label>
	<label *ngIf="type == 'passwordCheck'" class="input-message input-message--change"
		[ngClass]="{'visible': element.errors.passwordCheck}">
		<span class="password-criteria__label">
			{{'texts.InputFieldComponent_password_criteria_label' | translate}}
		</span>
		<ul>
			<li [ngClass]="{'password-criteria-approved': element.errors.passwordCheck.minLength}">
				{{'texts.InputFieldComponent_password_criteria_min' | translate}}
			</li>
			<li [ngClass]="{'password-criteria-approved': element.errors.passwordCheck.lowerCase}">
				{{'texts.InputFieldComponent_password_small_letter' | translate}}
			</li>
			<li [ngClass]="{'password-criteria-approved': element.errors.passwordCheck.upperCase}">
				{{'texts.InputFieldComponent_password_uppercase' | translate}}
			</li>
			<li [ngClass]="{'password-criteria-approved': element.errors.passwordCheck.numbers}">
				{{'texts.InputFieldComponent_password_number' | translate}}
			</li>
		</ul>
	</label>
	<label *ngIf="type == 'passwordConfirm'" class="input-message input-message--error"
		[ngClass]="{'visible': element.errors.passwordConfirm}">
		{{'texts.InputFieldComponent_password_not_matching' | translate}}
	</label>
</div>
<div *ngIf="element && element.valid && (element.dirty || element.touched)
&& type == 'passwordCheck'">
	<label class="input-message input-message--info visible">
		{{'texts.InputFieldComponent_password_strong' | translate}}
	</label>
</div>