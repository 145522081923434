import { PreparedLink } from './';

export class CardContent {
	imgAltText?: string;
	imgSrc!: string;
	imgsources?: {
		src: string;
		maxScreenSize?: string;
		minScreenSize?: string;
	}[];
	title!: string;
	description?: string;
	constructor(user: Partial<CardContent>) {
		Object.assign(this, user);
	}
}

export class CardImgsources {
	src!: string;
	maxScreenSize?: string;
	minScreenSize?: string;
}

export interface CardPackage {
	title: string;
	price: string;
	finalPrice: string;
	mostPopular: boolean;
}

export interface CardResponsiveSliderContent {
	imgAltText?: string;
	imgSrc: string;
	title: string;
	description?: any;
}

export interface CardTopicContent {
	description?: string;
	imgSrc: string;
	link?: string;
	preparedLink?: PreparedLink;
	title: string;
}

export interface CardWideContent {
	title: string;
	subtitle: string;
	description: string;
	imgSrc: string;
}