<div class="section" [ngClass]="{'tutor-profile': applyCustomStyling}">
    <div class="advice-left-column">
        <div class="title" [ngClass]="{'tutor-profile': applyCustomStyling}">{{ 'texts.Price_Page_Advice_Section_title'
            | translate
            }}</div>
        <div class="text description" [ngClass]="{'tutor-profile': applyCustomStyling}">{{
            'texts.Price_Page_Advice_Section_text' | translate
            }}</div>
        <div class="testimonial-container" [ngClass]="{'tutor-profile': applyCustomStyling}" *ngIf="advices">
            <app-carousel [items]="advices" [temp]="advicesTemplate">
            </app-carousel>
        </div>
        <ng-template #advicesTemplate let-data="data">
            <div class="pictures-flex pictures-flex-tablet-desktop">
                <div class="pictures-container">
                    <div class="round-picture-container">
                        <app-image-defer-lazy [image]="data._imageObj"></app-image-defer-lazy>
                    </div>
                </div>
                <div class="icon-text">
                    <p class="subtitle name" [ngClass]="{'tutor-profile': applyCustomStyling}">{{data.name}}</p>
                    <p class="section-photo-text text" [ngClass]="{'tutor-profile': applyCustomStyling}">
                        {{data.speciality}}</p>
                </div>
            </div>
        </ng-template>
    </div>
    <div class="advice-right-column" [ngClass]="{'tutor-profile': applyCustomStyling}">
        <div>
            <div class="title phone" [ngClass]="{'tutor-profile': applyCustomStyling}">
                <span [bindHTML]="'texts.Price_Page_Advice_Section_Phone_number_wbr' | translate"></span>
            </div>
            <div class="text hours" [ngClass]="{'tutor-profile': applyCustomStyling}">{{
                'texts.Price_Page_Advice_Section_Working_hours' | translate
                }}</div>
        </div>
        <div class="contact-icons">
            <a class="phone-number"
                [href]="'tel:' + (variableService.contactVariable$ | async)?.contactPhoneLinked"><app-svg-icon
                    class="call-icon" name="call-icon"></app-svg-icon></a>
            <a target="new" [href]="(variableService.contactVariable$ | async)?.contactWhatsappLink"><app-svg-icon
                    class="whatsapp-icon" name="whatsapp-icon"></app-svg-icon></a>
        </div>
    </div>
</div>