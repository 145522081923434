import { ChangeDetectorRef, Input, QueryList, ViewChildren } from '@angular/core';
import { UtilityService } from 'app/services';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { Testimonial } from '../../../price/models/testimonial';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-new-trustpilot-carousel',
  templateUrl: './new-trustpilot-carousel.component.html',
  styleUrls: ['./new-trustpilot-carousel.component.scss']
})
export class NewTrustpilotCarouselComponent {

  @ViewChildren('carouselItems') carouselItems: QueryList<any>;
  @Input() componentStyle: boolean
  isStudent = false;
  testimonials: Array<Testimonial>

  constructor(public utilityService: UtilityService,
    public lazyLoad: LazyLoadImageModule,
    public translate: TranslateService,
    private cdr: ChangeDetectorRef
  ) { }

  ngAfterViewInit() {
    this.swapData(this.isStudent);
    this.cdr.detectChanges();
  }

  swapData(isStudent: boolean) {
    this.isStudent = isStudent
    if (isStudent) {
      this.translate.get([
        'texts.Price_Page_Trustpilot_Section_Testimonial_Student_1',
        'texts.Price_Page_Trustpilot_Section_Testimonial_Student_2',
        'texts.Price_Page_Trustpilot_Section_Testimonial_Student_3'
      ]).subscribe(translation => {

        let studentsTestimonials: Array<Testimonial> = [
          JSON.parse(translation['texts.Price_Page_Trustpilot_Section_Testimonial_Student_1']),
          JSON.parse(translation['texts.Price_Page_Trustpilot_Section_Testimonial_Student_2']),
          JSON.parse(translation['texts.Price_Page_Trustpilot_Section_Testimonial_Student_3'])
        ]
        this.testimonials = studentsTestimonials;
      })
    } else {
      this.translate.get([
        'texts.Price_Page_Trustpilot_Section_Testimonial_Parent_1',
        'texts.Price_Page_Trustpilot_Section_Testimonial_Parent_2',
        'texts.Price_Page_Trustpilot_Section_Testimonial_Parent_3'
      ]).subscribe(translation => {

        let parentsTestimonials: Array<Testimonial> = [
          JSON.parse(translation['texts.Price_Page_Trustpilot_Section_Testimonial_Parent_1']),
          JSON.parse(translation['texts.Price_Page_Trustpilot_Section_Testimonial_Parent_2']),
          JSON.parse(translation['texts.Price_Page_Trustpilot_Section_Testimonial_Parent_3'])
        ]
        this.testimonials = parentsTestimonials

        // COMMENTED OUT UNTIL NEW PICTURES PROVIDED
        // .map(
        //   x => x = {
        //     ...x,
        //     _imageObj: { title: x.name + 'picture', originalSource: x.picture }
        //   })
      })
    }
  }
}
