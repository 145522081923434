<div>
  <div class="action-buttons" #actionButtons>
    <div class="arrow-back" *ngIf="currentStep > 1">
      <i class="fas fa-chevron-left" (click)="previousStep()"></i>
    </div>
    <app-buy-package-after-booking
      [showPictureAndSvg]="showPictureAndSvg"
      [showTutorLevelOptions]="showTutorLevelOptions"
      [applyCustomStyling]="applyCustomStyling"
      [selectedTutorLevel]="tutorLevel"
      [tutorName]="tutorName"
      *ngIf="currentStep === 1"
      (nextStep)="nextStep($event.lessonPerWeek, $event.selectedTutorLevel)"
    ></app-buy-package-after-booking>
    <app-package-cards
      *ngIf="currentStep === 2"
      (previousStep)="previousStep()"
      (onClose)="onClose.emit()"
      [selectedLessonPerWeek]="selectedLessonPerWeek"
      [selectedLessonPerWeek]="selectedLessonPerWeek"
      [selectedTutorLevel]="selectedTutorLevel"
      [selectedPackageData]="selectedPackageData"
      [isModificationsAllowed]="false"
      (onNewPackagePurchase)="onNewPackagePurchase.emit()"
    ></app-package-cards>
  </div>
</div>
