<section *ngIf="aliasList && aliasList.length" class="page-list__section">
	<app-text-info-section [title]="title" [subtitle]="subtitle" [description]="description"></app-text-info-section>
	<div class="page-list__container">
		<ul *ngIf="showList" class="page-list__list">
			<li *ngFor="let item of aliasList" class="page-list__list__item">
				<i *ngIf="isArray(item.url) && authService.isAdmin()" class="far fa-copy"
					(click)="utilityService.updateClipboard(item.url[0]);"></i>
				<i *ngIf="!isArray(item.url) && authService.isAdmin()" class="far fa-copy"
					(click)="utilityService.updateClipboard(!item.url.includes('http') ? '/' + item.url : item.url);"></i>
				<a *ngIf="isArray(item.url);" class="page-list__list__link"
					[attr.style]="linkColor ? 'color: ' + linkColor +' !important;' : null"
					[routerLink]="[item.url[0]] | localize" [queryParams]="item.url[1] ? item.url[1].queryParams : null">
					<span *ngIf="item.fileName">{{item.fileName}}</span>
					<span *ngIf="!item.fileName">{{item.url}}</span>
				</a>
				<a *ngIf="!isArray(item.url) && !item.url.includes('http')" class="page-list__list__link"
					[attr.style]="linkColor ? 'color: ' + linkColor +' !important;' : null" [routerLink]="['/' + item.url]">
					<span *ngIf="item.fileName">{{item.fileName}}</span>
					<span *ngIf="!item.fileName">{{item.url}}</span>
				</a>
				<a *ngIf="!isArray(item.url)" class="page-list__list__link"
					[attr.style]="linkColor ? 'color: ' + linkColor +' !important;' : null"
					[href]="item.url.includes('http') ? item.url : '/' + item.url">
					<span *ngIf="item.fileName">{{item.fileName}}</span>
					<span *ngIf="!item.fileName">{{item.url}}</span>
				</a>
			</li>
			<div *ngIf="authService.isAdmin()"
				class="sitemap-section__toggle-collapse sitemap-section__toggle-collapse__page-list"
				onclick="toggleSitemapCollapse(this)">Instorten <i class="fas fa-chevron-down"></i></div>
		</ul>
	</div>
</section>