<section class="action-header__section">

	<div
		class="action-header__section__background action-header__section__background--nl action-header__section__background--home visible">
	</div>

	<div class="action-header__container">
		<div class="action-header__text">
			<h2 *ngIf="title && !isFirstSection" class="action-header__text__title" [innerHTML]="title"></h2>
			<h1 *ngIf="title && isFirstSection" class="action-header__text__title" [innerHTML]="title"></h1>

			<h1 *ngIf="!title && description && isFirstSection; else description2" class="action-header__text__description" [innerHTML]="description | unescape"></h1>
			<ng-template #description2>
				<h3 *ngIf="description" class="action-header__text__description" [innerHTML]="description | unescape"></h3>
			</ng-template>

			<div *ngIf="showButton && buttonLink && buttonText" class="action-header__button">
				<a [routerLink]="[buttonLink] | localize">{{buttonText}}</a>
			</div>
		</div>
	</div>
</section>

<app-storybrand-steps *ngIf="showStorybrandSteps"></app-storybrand-steps>

<app-logo-group *ngIf="showLogoGroup"></app-logo-group>

<div *ngIf="showEmpathy" class="action-header__empathy">
	<hr *ngIf="showLogoGroup" class="action-header__empathy__divider" />
	<h3 class="action-header__empathy__description">
		<div>
			<span>
				<span>
					{{'texts.ActionHeader_empathy_text1' | translate}}
				</span>
			</span>
			<span class="action-header__empathy__description--highlighted">
				<span>
					{{'texts.ActionHeader_empathy_text2' | translate}}
				</span>
			</span>
			<span>
				<span>
					{{'texts.ActionHeader_empathy_text3' | translate}}
				</span>
				<span class="action-header__empathy__description--highlighted">
					<span>
						{{'texts.ActionHeader_empathy_text4' | translate}}
					</span>
				</span>
				<span>
					<span>
						{{'texts.ActionHeader_empathy_text5' | translate}}
					</span>
				</span>
			</span>
		</div>
		<div>
			{{'texts.ActionHeader_empathy_text6' | translate}}
		</div>
	</h3>
</div>