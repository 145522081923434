<div [formGroup]="parentForm" [class]="'input-group-location ' + geoService.language">
	<div *ngFor="let field of geoService.formFields;" [class]="'input-field ' + field.fieldClass">
		<div class="input__icon-wrapper">

			<input *ngIf="!isReadOnly(field)" name="postCode" type="text" [name]="field.name"
				[formControlName]="field.name" [placeholder]="field.placeholder | translate"
				[class]="'input-text data-hj-allow ' + field.class" required="" (keyup)="onKeyUp.next($event)"
				(paste)="updateFields()">
			<input *ngIf="isReadOnly(field)" name="postCode" type="text" [name]="field.name"
				[formControlName]="field.name" [placeholder]="field.placeholder | translate"
				[class]="'input-text data-hj-allow ' + field.class" required="" [disabled]="true" [readonly]="true">
		</div>
		<app-input-error [element]="parentForm.get(field.name)" [type]="field.name"></app-input-error>
	</div>
	<label class="input-message input-group-location__error-message"
		[ngClass]="{'visible': geoService.showGroupMessage}">
		<span *ngIf="geoService.addressAjax && !geoService.addressAjax.closed">
			<i class="fa fa-spinner fa-spin"></i>
		</span>
		{{'texts.InputGroupLocationComponent_error' | translate}}
	</label>
	<label class="input-message input-group-location__error-message"
		[ngClass]="{'visible': geoService.showInvalidMessage}">
		<span *ngIf="geoService.addressAjax && !geoService.addressAjax.closed">
			<i class="fa fa-spinner fa-spin"></i>
		</span>
		{{'texts.Modal_register_student_cant_find_address' | translate}}
	</label>
</div>