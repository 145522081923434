<div class="msg" [ngClass]="{'msg--hidden': !toastParams}">
	<div [routerLink]="toastParams.link ? (['/' + toastParams.link] | localize) : null" class="msg-body msg-body--grey"
		[ngClass]="classConditions">
		<div class="msg-body__icon" [innerHTML]="toastParams.iconHTML"></div>
		<div class="msg-body__text">
			<div class="msg-body__text__title">{{toastParams.title | translate}}</div>
			<div class="msg-body__text__description" [innerHTML]="toastParams.description | translate"></div>
		</div>
	</div>
	<div class="msg-dismiss" (click)="dismissMsg($event);">
		<i class="msg-dismiss-icon fas fa-times"></i>
	</div>
</div>