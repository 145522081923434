import { NewAddressFormBaseComponent } from './../../standalone-components/new-address-form/new-addres-form-base';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { AddressFormComponent } from './address-form/address-form.component';

@NgModule({
	declarations: [
		AddressFormComponent
	],
	imports: [
		SharedModule,
		NewAddressFormBaseComponent
	],
	exports: [
		AddressFormComponent
	],
})
export class GeoModule { }
