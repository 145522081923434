<div class="search-container section" data-cy="SearchContainer" *ngIf="!isOneInput || !isMobile">
    <div class="button-toggle-container">
        <app-toggle-online-home (onStateChanged)="onAnySelectionChange()" [(isOnline)]="searchService.isOnline"
            (click)="(!isOneInput && utilityService.isMobileRes && showSearchBottom()) && onAnySelectionChange()"></app-toggle-online-home>
    </div>
    <div class="search-options"
        [ngClass]="{ 'search-options-4-cols': searchService.isOnline, 'search-options-3-cols': !searchService.selectedSchoolLevel?.years}">
        <div class="search-option">
            <app-svg-icon class="search-icon" name="buildings"></app-svg-icon>
            <ng-select data-size="2" id="school-type" name="schoolType_h" required [clearable]="false"
                dropdownPosition="bottom" [placeholder]="'texts.DropdownSelect_schooltype' | translate"
                [items]="searchService.schoolTypes" [(ngModel)]="searchService.selectedLevelId" bindValue="id"
                groupBy="levels" bindLabel="name" [searchable]="false"
                (change)="searchService.onLevelSelect($event); onAnySelectionChange()"
                [disabled]="!isOneInput && utilityService.isMobileRes"
                (click)="!isOneInput && utilityService.isMobileRes && showSearchBottom('selectSchoolType')"
                data-cy="SchoolType">
                <ng-template ng-optgroup-tmp let-item="item">
                    {{item.name}}
                </ng-template>

                <ng-template ng-label-tmp let-item="item">
                    <div class="selected-item">
                        <span class="selected-title">{{'texts.Searchbar_Component_Schooltype_Text' |
                            translate}}</span>
                        {{item.name}}
                    </div>
                </ng-template>
            </ng-select>
        </div>
        <div *ngIf="searchService.selectedSchoolLevel?.years" class="search-option">
            <app-svg-icon class="search-icon" name="backpack"></app-svg-icon>
            <ng-select class="select" required id="school-year" name="schoolYear_h" [clearable]="false"
                dropdownPosition="bottom" [items]="searchService.selectedSchoolLevel.years"
                [placeholder]="'texts.DropdownSelect_schoolyear' | translate" [searchable]="false" bindValue="id"
                bindLabel="name" [(ngModel)]="searchService.selectedYearId"
                (change)="searchService.setAvailableCoursesByYearNgSelect($event); onAnySelectionChange()"
                [disabled]="!isOneInput && utilityService.isMobileRes"
                (click)="!isOneInput && utilityService.isMobileRes && showSearchBottom('selecSchoolYear')"
                data-cy="SchoolYear">
            </ng-select>
        </div>
        <div class="search-option courses-input">
            <app-svg-icon class="search-icon" name="notebook-square"></app-svg-icon>
            <ng-select #courseSelector class="" required name="schoolSubject_h" [clearable]="false"
                dropdownPosition="bottom" [placeholder]="'texts.DropdownSelect_subjects' | translate"
                [items]="searchService.availableCourses" [multiple]="true" [closeOnSelect]="false" [searchable]="false"
                bindValue="id" bindLabel="text" [(ngModel)]="searchService.selectedCourseIds"
                (change)="searchService.selectedCourses = $event;" (close)="onAnySelectionChange()"
                [disabled]="!isOneInput && utilityService.isMobileRes"
                (click)="!isOneInput && utilityService.isMobileRes && showSearchBottom('selectSubject')"
                data-cy="Subject">
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index"
                    (click)="courseSelector.isOpen=true">
                    <div class="course-row">
                        {{item.name}}
                        <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                            [ngModelOptions]="{standalone: true}" />
                    </div>
                </ng-template>

                <ng-template ng-multi-label-tmp let-items="items">
                    <div class="selected-item">
                        <span class="selected-title">{{'texts.DropdownSelect_subjects' |
                            translate}}</span>
                        <div class="selected-items">
                            <div class="item" *ngFor="let item of items" (mousedown)="$event.stopPropagation();">
                                {{item.name}} <i class="far fa-times remove-icon"
                                    (click)="removeCourse(item); onAnySelectionChange(); $event.stopPropagation();"></i>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ng-select>
        </div>
        <div class="input-container search-option" *ngIf="!searchService.isOnline"
            [ngClass]="{'hide': searchService.isOnline,'error-height':searchService.showPostCodeError && !searchService.isOnline,'error-border':searchService.showPostCodeError && !searchService.isOnline}"
            (click)="!isOneInput && utilityService.isMobileRes && showSearchBottom('postCode')">
            <div class="search-option input-field" id="postcode">
                <app-svg-icon class="search-icon" name="map-point"></app-svg-icon>
                <input type="text" class="post-code" name="post-code" (ngModelChange)="postCodeInputChange.next($event)"
                    [placeholder]="'texts.SearchBarHeaderSection_post_code' | translate"
                    [disabled]="searchService.isOnline" maxlength="7" [(ngModel)]="searchService.postCode"
                    [readonly]="(!isOneInput && utilityService.isMobileRes) || (authService.userDetails && authService.userDetails?.state !== UserStateEnum.New)"
                    data-cy="PostCode">
            </div>
            <div *ngIf="!searchService.isOnline" class="postcode-error"
                [ngClass]="{'visible': searchService.showPostCodeError}">
                {{'texts.TutorSearchDropdownsComponent_postcode_not_valid' |
                translate }}</div>
        </div>
        <a (click)="search()" class="search-button  button">
            <app-svg-icon class="hide-on-mobile" name="search"></app-svg-icon>
            <span>{{'texts.SearchbarHeaderSection_search_button' | translate}}</span></a>
    </div>
</div>

<div class="search-container section one-input-searchbar unselected" *ngIf="isOneInput && isMobile"
    [ngClass]="{'selected':(searchService.selectedCourses.length || searchService.selectedSchoolLevel)}">
    <div class="search-option" (click)="showSearchBottom()" data-cy="OneInputSearch">
        <app-svg-icon name="search-normal"></app-svg-icon>
        <div class="search-input " name="post-code">
            <span class="search-input-placeholder"
                *ngIf="!searchService.selectedSchoolLevel && !searchService.selectedYear && !searchService.selectedCourses.length">{{'texts.SearchbarHeaderSection_search_button'
                | translate}}</span>
            <span class="search-input-filled ">
                {{searchService.selectedSchoolLevel?.name}} {{searchService.selectedYear?.name}}</span>
            <span class="search-input-filled" *ngFor="let course of searchService.selectedCourses; let i = index;">
                <span *ngIf="!i==0 || searchService.selectedSchoolLevel">, </span> {{course.name}}</span>
        </div>
        <i class="fal fa-sliders-h"></i>
    </div>
</div>