import { Component, OnInit } from '@angular/core';
import { CmsBaseComponent } from '../cms-base/cms-base.component';

@Component({
  selector: 'app-cms-section-wrapper',
  templateUrl: './cms-section-wrapper.component.html',
  styleUrls: ['./cms-section-wrapper.component.scss']
})
export class CmsSectionWrapperComponent extends CmsBaseComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

}
