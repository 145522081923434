import { UserRole } from "../constants";

export class User {
  id: number;
  email: string;
  exp: number;
  iat: number;
  forename: string;
  image: string;
  role: UserRole.admin | UserRole.signedout | UserRole.student | UserRole.teacher;
  surname: string;
  telephone: string;
  initials: string;
  adminId!: number;

	constructor(user: Partial<User>) {
		Object.assign(this, user);
	}
}

export interface UserPreference {
  whatsappEnabled: boolean
  emailEnabled: boolean
}
