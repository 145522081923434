<h1 mat-mdc-dialog-title class="text-center">
    {{title | translate}}
    <i *ngIf="titleIcon" [class]="titleIcon" #tooltip="matTooltip" [matTooltip]="titleIconTooltip | translate"
        matTooltipPosition="above" matTooltipTouchGestures="auto"></i>
    <i class="close fa fa-times close" mat-mdc-dialog-close (click)="dialogRef.close()"></i>
</h1>
<div mat-mdc-dialog-content class="text-center">
    <ng-template compHost></ng-template>
</div>
<div mat-mdc-dialog-actions class="text-center" *ngIf="actionText">
    <div class="button dialog-button" (click)="dialogRef.close()"> {{actionText |
        translate}}
    </div>
</div>