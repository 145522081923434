import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-login-or-register-dialog',
  templateUrl: './login-or-register-dialog.component.html',
  styleUrls: ['./login-or-register-dialog.component.scss']
})
export class LoginOrRegisterDialogComponent {

  @Output() onShowRegisterModal = new EventEmitter<boolean>();
  @Output() onShowLoginModal = new EventEmitter<boolean>();

  constructor(
    public dialogRef: MatDialogRef<LoginOrRegisterDialogComponent>
  ) { }

  onRegisterModalShow(): void {
    this.onShowRegisterModal.emit(true);
  }

  onLoginModalShow(): void {
    this.onShowLoginModal.emit(true);
  }
}
