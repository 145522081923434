import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ButtonComponent } from '../../shared/button/button.component';
import { LinkButtonComponent } from '../../shared/link-button/link-button.component';
// Leave InputElement import, otherwise it is breaking
import { InputField, InputLinkButton, InputSubmitButton, FooterContent } from 'app/models';
import { PopupService, UtilityService, LogService } from '../../../services';
import { environment } from 'environments/environment';
import { LOG_TYPE } from 'app/constants';

@Component({
	selector: 'app-info-content',
	templateUrl: './info-content.component.html',
	styleUrls: ['./info-content.component.scss']
})
export class InfoContentComponent implements OnInit, AfterViewInit {
	@Input()
	title: string;
	@Input()
	description: string;
	@Input()
	inputFields: InputField[];
	@Input()
	footerContent: FooterContent;
	@Input()
	inputLinkButton: InputLinkButton;
	@Input()
	inputSubmitButton: InputSubmitButton;
	@Input()
	isLogin: boolean;

	siteKey: string;

	// TODO: token email
	token;
	email;

	@ViewChild('inputInsert', { read: ViewContainerRef })
	inputInsert: ViewContainerRef;

	@Input()
	submitForm: UntypedFormGroup;

	response: any = {};

	constructor(
		private componentFactoryResolver: ComponentFactoryResolver,
		private changeDetector: ChangeDetectorRef,
		private formBuilder: UntypedFormBuilder,
		public popupService: PopupService,
		private utilityService: UtilityService,
		private translationService: TranslateService,
		private logService: LogService
	) { }

	ngOnInit(): void {
		if (!this.siteKey) {
			this.siteKey = environment.google.CaptchaKey;;
		}
		this.translationService.get('ROUTES.Misc_login').subscribe(translation => {
			if (!this.isLogin) {
				// on ssr our hard coded login page, which is not really used anymore, isn't rendered correctly on ssr
				this.isLogin = this.utilityService.isBrowser ? location.pathname.includes(translation) : false;
			}

			if (this.footerContent?.links) {
				for (let i = 0; i < this.footerContent.links.length; i++) {
					this.footerContent.links[i].preparedLink = this.utilityService.prepareLink(this.footerContent.links[i].link);
				}
			}
		});
		if (!this.submitForm) {
			this.submitForm = this.formBuilder.group({

			});
		}
	}

	ngAfterViewInit(): void {
		if (this.inputLinkButton) {
			const componentFactory = this.componentFactoryResolver.resolveComponentFactory(LinkButtonComponent);
			const component = this.inputInsert.createComponent(componentFactory).instance;
			for (let column in this.inputLinkButton) {
				component[column] = this.inputLinkButton[column];
			}
			this.changeDetector.detectChanges();
		}

		if (this.inputSubmitButton) {
			const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ButtonComponent);
			const component = this.inputInsert.createComponent(componentFactory).instance;
			for (let column in this.inputSubmitButton) {
				component[column] = this.inputSubmitButton[column];
			}
			this.changeDetector.detectChanges();
		}
	}

	resolved(captchaResponse: string) {
		this.logService.log(LOG_TYPE.INFO, `Resolved captcha with response:`, captchaResponse);
	}

}
