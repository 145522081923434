import { AddCourseModelForEdit, CombinedCourseModel, CourseModel, GroupedCourseModel } from './../models/course.model';
import { catchError, map, tap } from 'rxjs/operators';
import { LessonPrices, TutorSearch } from './../microservice-clients/user';
import { UtilityService } from 'app/services';

import { HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { FindTutorsRequest, FindTutorsResponse, UserObj } from '../microservice-clients/user';
import { StudentTutorRelationStatus, BypassAPIGlobalHandleEnums } from 'app/constants';
import { SearchSchoolType } from 'app/models/search-school.model';
import { AddOrEditSchoolType } from 'app/models/add-or-edit-school.model';

let cache = {};

@Injectable({
  providedIn: 'root'
})
export class UserClientService {

  constructor(
    private apiService: ApiService,
    private utilityService: UtilityService
  ) { }

  registerUser(registrationData: any) {// TODO: add type
    const headers = this.utilityService.addHeaders(BypassAPIGlobalHandleEnums.ErrorHandle);
    return this.apiService.postWithHeaders(`user/new`, registrationData, headers)
  }

  findTutors(findTutorsRequest: FindTutorsRequest): Observable<FindTutorsResponse> {
    let params = new HttpParams()
      .set('lessonType', findTutorsRequest.lessonType)
      .set('country', findTutorsRequest.country)
      .set('limit', findTutorsRequest.limit)
      .set('offset', findTutorsRequest.offset);

    if (findTutorsRequest.schoolTypeId) {
      params = params.append('schoolTypeId', findTutorsRequest.schoolTypeId.toString())
    }

    if (findTutorsRequest.courseIds?.length) {
      params = params.append('courseIds', findTutorsRequest.courseIds.join())
    }

    if (findTutorsRequest.latitude && findTutorsRequest.longitude) {
      params = params.append('latitude', findTutorsRequest.latitude);
      params = params.append('longitude', findTutorsRequest.longitude);
    }

    if (findTutorsRequest.randomSeed) {
      params = params.append('randomSeed', findTutorsRequest.randomSeed);
    }

    if (findTutorsRequest.schoolLevelId) {
      params = params.append('schoolLevelId', findTutorsRequest.schoolLevelId);
    }

    if (findTutorsRequest.schoolYearId) {
      params = params.append('schoolYearId', findTutorsRequest.schoolYearId);
    }

    if (findTutorsRequest.anonymousId) {
      params = params.append('anonymousId', findTutorsRequest.anonymousId);
    }

    return this.apiService.get('user/tutor/find', params)
  }

  getUser(userId: string): Observable<UserObj> {
    return this.apiService.get(`user/${userId}`).pipe(catchError(error => this.handle404(error)));
  }

  getLessonPrices(tutorId: number, studentId?: number, latitude?: number, longitude?: number, durationInMinutes?: number): Observable<LessonPrices> {
    let params = new HttpParams().set('tutorId', tutorId);

    if (studentId) params = params.append('studentId', studentId);
    if (latitude) params = params.append('latitude', latitude);
    if (longitude) params = params.append('longitude', longitude);
    if (durationInMinutes) params = params.append('durationInMinutes', durationInMinutes);

    return this.apiService.get('user/prices/lesson', params);
  }

  sendMessageToTutor(message: string, tutorId: number): Observable<any> {
    return this.apiService.post('message/tutor', { message: message, tutor: tutorId });
  }

  getTutorGroupedCourses(tutorId: number): Observable<Array<GroupedCourseModel>> {
    return this.apiService.get(`user/tutor/${tutorId}/courses/grouped`)
      .pipe(
        catchError(error => this.handle404(error)),
        map(response => response.courses)
      );
  }

  getTutorRequestedGroupedCourses(tutorId: number): Observable<{ courses: Array<GroupedCourseModel> }> {
    return this.apiService.get(`user/tutor/${tutorId}/requested-courses/grouped`)
      .pipe(
        catchError(error => this.handle404(error)),
        tap(
          (response) => {
            return {
              courses: response?.courses.map(course => {
                let _course = course;
                _course._isRequested = true;
                return _course
              })
            }
          })
      )
  }

  getTutorCombinedCourses(tutorId: string): Observable<Array<CombinedCourseModel>> {
    return this.apiService.get(`user/tutor/${tutorId}/courses/all/grouped`)
      .pipe(
        catchError(error => this.handle404(error)),
        map(response => {
          if (response.courses) {
            response.courses.map(course => {
              let _course = course;
              if (!_course.approved) {
                _course.approved = false;
              }
              return _course
            });
          }
          return response?.courses ? response.courses : [];
        })
      );
  }

  addTutorCourse(course: AddCourseModelForEdit) {
    return this.apiService.post('user/tutor/courses/new', course)

  }

  deleteTutorCourse(model: CombinedCourseModel) {
    return this.apiService.post(`user/tutor/courses/delete`, model)
  }

  getAllCourses(): Observable<Array<CourseModel>> {
    return this.apiService.get(`subject/courses/${this.utilityService.country}`)
      .pipe(
        catchError(error => this.handle404(error)),
        map(response => response.courses)
      );
  }

  getAllSearchSchoolTypesAndCourses(): Observable<{ schoolTypes: Array<SearchSchoolType> }> {
    return this.apiService.get(`subject/search/criteria/${this.utilityService.country}`);
  }

  getDefaultSchoolTypesAndCourses(): Observable<{ schoolTypes: Array<AddOrEditSchoolType> }> {
    const path = `subject/search/subjects/${this.utilityService.country}`
    return cache.hasOwnProperty(path) ? cache[path] : this.apiService.get(path).pipe(tap(response =>
      cache[path] = of(response)));
  }

  getUserRelationStatus(studentID: number, tutorID: number) {
    return this.apiService.get(`user/relations/${studentID}/${tutorID}`)
      .pipe(
        catchError(error => this.handle404(error)),
        tap((response) => {
          return (!response.status || response.status == StudentTutorRelationStatus.unknown) ? StudentTutorRelationStatus.none : response.status;
        })
      );
  }

  getFavoriteTutors(): Observable<Array<TutorSearch>> {
    return this.apiService.get(`user/favorites`)
      .pipe(
        catchError(error => this.handle404(error)),
        map(response => response.favorites)
      );
  }

  addFavoriteTutor(tutorId: string): Observable<TutorSearch> {
    return this.apiService.post(`user/favorites/${tutorId}`)
      .pipe(catchError(error => this.handle404(error)));
  }

  removeFavoriteTutor(tutor_id: number): Observable<TutorSearch> {
    return this.apiService.delete(`user/favorites/${tutor_id}`)
      .pipe(catchError(error => this.handle404(error)));
  }

  private handle404(error) {
    if (error.status == 404) return of(null);
    return throwError(error);
  }

}
