export class ChatUpdate {
	type!: string;
	data!: ChatMessage | ChatHistoryMessage[];

	constructor(user: Partial<ChatUpdate>) {
		Object.assign(this, user);
	}
}

export class ChatMessage {
	id!: number;
	message!: string;
	own!: boolean;
	read!: boolean;
	system!: boolean;
	timestamp!: string;
	sender!: ChatMessageSender;

	constructor(user: Partial<ChatMessage>) {
		Object.assign(this, user);
	}
}

export class ChatMessageSender {
	id!: number;
	forename!: string;
	image!: string;
	initials!: string;
	surname!: string;

	constructor(user: Partial<ChatMessageSender>) {
		Object.assign(this, user);
	}
}

export class ChatHistoryMessage {
	id!: number;
	message!: string;
	own!: boolean;
	read!: boolean;
	system!: boolean;
	timestamp!: string;

	constructor(user: Partial<ChatHistoryMessage>) {
		Object.assign(this, user);
	}
}

export class NavUpdate {
	type!: string;
	messages!: number;
	appointments!: number;
	constructor(user: Partial<NavUpdate>) {
		Object.assign(this, user);
	}
}
