import { isPlatformServer, Location } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CustomCookieServiceService } from 'app/services/custom-cookie-service.service'
import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root'
})
export class PageConfigService {

	private _forbiddenAdressesStudentAction: string[] = [
		'ROUTES.Misc_login',
		'ROUTES.Misc_tutor_search',
	];

	private _forbiddenAdressesTutorAction: string[] = [
		'ROUTES.Misc_tutor_registration',
	];

	private _config: any;

	private _options = {
		signedIn: '',
		signedOut: '',
		always: '',
		none: ''
	};

	public defaultTranslations?: any;

	showTutorLinksOnly: boolean = false;
	isBlogPage: boolean = false;

	location: Location;
	becomeTutorLink: string = '';

	constructor(
		private authService: AuthService,
		private cookieService: CustomCookieServiceService,
		private translationService: TranslateService,
		location: Location,
		@Inject(PLATFORM_ID) private platformID: string,
	) {
		this.location = location;

		this.setURLs();
		this.initConfig();
		this.initOptions();
	}

	/**
	 * @returns {Boolean} if the browser is safari / on an iOS device or not
	 */
	isSafari(): boolean {
		return isPlatformServer(this.platformID) ? false : /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
	}

	/**
	 * Initiates the config variables
	 */
	initConfig() {
		this.translationService.get(
			[
				'ROUTES.Misc_become_tutor',
				'ROUTES.TeacherInfo'
			]
		).subscribe((translation) => {
			this.becomeTutorLink = translation['ROUTES.Misc_become_tutor'];
			this._config = {
				'showRegisterActionButton': this.isActive('signedOut') && this.isAllowedStudentURI() && this.isAllowedTutorURI(),
				// 'showRegisterActionButton': !Common::GetUser().isSignedIn() && strpos($_SERVER['REQUEST_URI'], 'docenten') === false,
				'isRegisterAsTutor': !this.authService.isSignedIn() && (this.location.path().includes(translation['ROUTES.Misc_become_tutor']) || this.location.path().includes(translation['ROUTES.TeacherInfo'])),
				'isStorybrand': false,
			};
		});
	}

	/**
	 * Initiates the visual text of the page options
	 */
	initOptions() {
		this.translationService.get(['texts.PageConfig_signed_in', 'texts.PageConfig_signed_out', 'texts.PageConfig_always', 'texts.PageConfig_none']).subscribe((translations) => {
			this._options.signedIn = translations['texts.PageConfig_signed_in'];
			this._options.signedOut = translations['texts.PageConfig_signed_out'];
			this._options.always = translations['texts.PageConfig_always'];
			this._options.none = translations['texts.PageConfig_none'];
		});
	}


	setConfig(config: any) {
		this.initConfig();
		if (config) {
			for (const key in config) {
				this._setKey(key, config[key]);
			}
		}
		// this.showTutorLinksOnly = this.getTutorLinksOnly();
	}

	getTutorLinksOnly() {
		const isFirstPageLoad = !Boolean(this.cookieService.get('isFirstPageLoad'));
		if (isFirstPageLoad) {
			this.cookieService.put('isFirstPageLoad', '', { expires: "3600" });
		}
		if (!this.authService.isSignedIn() && (isFirstPageLoad && this.getIsRegisterAsTutor() || this.cookieService.get('showTutorLinksOnly'))) {
			this.cookieService.put('showTutorLinksOnly', '', { expires: "86400" });
		}
		return Boolean(!this.authService.isSignedIn() && this.cookieService.get('showTutorLinksOnly'));
	}

	/**
	 * Adds a new key - value pair to the config
	 * @param {String | number} key
	 * @param {any} param 
	 */
	private _setKey(key: any, param: any) {
		if (param && this._config[key] !== undefined) {
			this._config[key] = this.isActive(param);
		}
	}

	/**
	 * @returns {Object} the entire page config object
	 */
	getConfig() {
		return this._config;
	}

	getShowRegisterActionButton(): boolean {
		return this._config['showRegisterActionButton'];
	}

	getIsRegisterAsTutor(): boolean {
		return this._config['isRegisterAsTutor'];
	}

	getIsStorybrand(): boolean {
		return this._config['isStorybrand'];
	}

	/**
	 * Set special treatment URLs
	 */
	setURLs() {
		this.translationService.get(this._forbiddenAdressesStudentAction).subscribe((translations) => {
			this._forbiddenAdressesStudentAction = Object.values(translations);
		});

		this.translationService.get(this._forbiddenAdressesTutorAction).subscribe((translations) => {
			this._forbiddenAdressesTutorAction = Object.values(translations);
		});
	}

	/**
	 * @returns {Boolean} if the action button is allowed for students on the current page
	 */
	isAllowedStudentURI(): boolean {
		for (const url of this._forbiddenAdressesStudentAction) {
			if (this.location.path().includes(url)) {
				return false;
			}
		}
		return true;
	}

	/**
	 * @returns {Boolean} if the action button is allowed for tutors on the current page
	 */
	isAllowedTutorURI(): boolean {
		for (const url of this._forbiddenAdressesTutorAction) {
			if (this.location.path().includes(url)) {
				return false;
			}
		}
		return true;
	}

	getOptions() {
		return this._options;
	}

	isActive(param: any): boolean {
		switch (param) {
			case 'signedIn':
				return this.authService.isSignedIn() && (!this.location.path().includes('login'));
			case 'signedOut':
				return !this.authService.isSignedIn() && (!this.location.path().includes('login'));
			case 'always':
				return true;
			case 'none':
				return !this.authService.isSignedIn() && (this.location.path().includes(this.becomeTutorLink));
			// return false;
		}
		return false;
	}

}
