<div class="dialog-container">
    <div class="close-icon" (click)="closeDialog()">
        <app-svg-icon name="close"></app-svg-icon>
    </div>
    <div class="dialog-title" translate>texts.Payment_Info_Dialog_Title</div>
    <div class="dialog-text" translate>texts.Payment_Info_Dialog_Text</div>
    <div class="video-container">
        <div class="video-element" *ngIf="videoUrl">
            <iframe [src]="videoUrl" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
        </div>
    </div>
</div>