<section class="cards__section" [ngClass]="sectionClass">
	<div class="cards__section-container">
		<h2 *ngIf="title && !isFirstSection" class="cards__title" [innerHTML]="title | translate"></h2>
		<h1 *ngIf="title && isFirstSection" class="cards__title" [innerHTML]="title | translate"></h1>
		<img *ngIf="titleImgSrc" class="cards__title-image" [lazyLoad]="titleImgSrc"
			[alt]="titleImgAltText ? titleImgAltText : title" sizes="(max-width: 136px) 100vw, 136px" />
		<p *ngIf="subtitle" class="cards__subtitle" [innerHTML]="subtitle"></p>
	</div>

	<div *ngIf="numbers" class="cards__section-container cards__card-number__container">
		<div *ngFor="let card of contents; let cardIteration = index" class="cards__card-number-desktop">
			<div class="cards__card-divider"></div>
			<div class="cards__card-number">
				<span>{{cardIteration + 1}}</span>
			</div>
		</div>
	</div>
	<div class="cards__section-container cards__container">

		<div *ngFor="let card of contents; let cardIteration = index" class="cards__card-wrapper">

			<div *ngIf="numbers" class="cards__card-number-tablet">
				<div class="cards__card-divider"></div>
				<div class="cards__card-number">
					<span>{{cardIteration + 1}}</span>
				</div>
			</div>

			<div class="cards__card">
				<div class="cards__card__content">

					<div *ngIf="card.imgSrc" class="card-responsive-slider__card__img">
						<picture *ngIf="!pageConfigService.isSafari()">
							<img [lazyLoad]="card.imgSrc" [alt]="card.imgAltText ? card.imgAltText : card.title"
								style="width:auto;">
						</picture>
						<img *ngIf="pageConfigService.isSafari()" [lazyLoad]="card.imgSrc"
							[alt]="card.imgAltText ? card.imgAltText : card.title" style="width:auto;">
					</div>
					<div class="card-responsive-slider__card__title">
						{{card.title}}
					</div>
					<div *ngIf="card.description" class="card-responsive-slider__card__description"
						[innerHTML]="card.description">
					</div>

				</div>
			</div>
		</div>
	</div>
	<ul class="cards__section-container card-responsive-slider__slider">
		<li *ngFor="let card of contents; let cardIteration = index" class="card-responsive-slider__card-wrapper">
			<div class="card-responsive-slider__card">
				<div class="card-responsive-slider__card__content">
					<div *ngIf="card.imgSrc" class="card-responsive-slider__card__img">
						<picture *ngIf="!pageConfigService.isSafari()">
							<img [lazyLoad]="card.imgSrc" [alt]="card.imgAltText ? card.imgAltText : card.title"
								style="width:auto;">
						</picture>
						<img *ngIf="pageConfigService.isSafari()" [lazyLoad]="card.imgSrc"
							[alt]="card.imgAltText ? card.imgAltText : card.title" style="width:auto;">
					</div>
					<div class="card-responsive-slider__card__title">
						<span class="card-responsive-slider__card__title__number">{{cardIteration + 1}}.</span>
						{{card.title}}
					</div>
					<div *ngIf="card.description" class="card-responsive-slider__card__description"
						[innerHTML]="card.description">
					</div>
				</div>
			</div>
		</li>
	</ul>
</section>