export interface AvailabilitySlotsResponse {
    Mon: AvailabilitySlot[]
    Tue: AvailabilitySlot[]
    Wed: AvailabilitySlot[]
    Thu: AvailabilitySlot[]
    Fri: AvailabilitySlot[]
    Sat: AvailabilitySlot[]
    Sun: AvailabilitySlot[]
}

export interface AvailabilitySlot {
    id?: string,
    begin: string
    end: string
}

export interface AvailabilityModel extends AvailabilitySlot {
    day: number
    _isAvailability?: boolean
}

export interface WeekAvailabilityModel {
    monday: Array<AvailabilitySlot>,
    tuesday: Array<AvailabilitySlot>,
    wednesday: Array<AvailabilitySlot>,
    thursday: Array<AvailabilitySlot>,
    friday: Array<AvailabilitySlot>,
    saturday: Array<AvailabilitySlot>,
    sunday: Array<AvailabilitySlot>
}

export interface TutorweeklyAvailabilityModel {
    monday: AvailabilityByDayModel,
    tuesday: AvailabilityByDayModel,
    wednesday: AvailabilityByDayModel,
    thursday: AvailabilityByDayModel,
    friday: AvailabilityByDayModel,
    saturday: AvailabilityByDayModel,
    sunday: AvailabilityByDayModel
}

export interface AvailabilityByDayModel {
    day?: string,
    periods: Array<PeriodsModel>,
    shortLabel: string
}

export interface PeriodsModel {
    period: number
}

export interface AvailabilitySlotRequestModel {
    tutorId: string;
    day: number;
    period: number;
}