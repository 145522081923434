import { NgModule } from '@angular/core';
import { InfoContentComponent } from './info-content/info-content.component';
import { InfoPageComponent } from './info-page.component';
import { InfoWrapperComponent } from './info-wrapper/info-wrapper.component';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { SharedModule } from '../shared/shared.module';

@NgModule({
	declarations: [
		InfoContentComponent,
		InfoPageComponent,
		InfoWrapperComponent
	],
	imports: [
		SharedModule,
		RecaptchaModule,
		RecaptchaFormsModule,
	],
	exports: [
		InfoContentComponent,
		InfoPageComponent,
		InfoWrapperComponent
	]
})
export class InfoPageModule { }
