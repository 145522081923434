<div class="navigation-wrapper">
    <i class="far fa-angle-left fa-lg" [ngClass]="
'arrow arrow--left ' + ((currentSlide === 0) && !customConfig?.loop ? 'arrow--disabled' : '')    "
        [class.hide-arrow]="!customConfig?.showArrows || currentSlide === 0 " (click)="slider.prev()"
        style="color: #000000;"></i>
    <i class="far fa-angle-right fa-lg" *ngIf="slider" [ngClass]="
'arrow arrow--right ' +
((slider.track.details.slides.length - 1 === currentSlide)  && !customConfig?.loop
  ? 'arrow--disabled'
  : '')    " [class.hide-arrow]="!customConfig?.showArrows || currentSlide === slider.track.details.slides.length - 1"
        (click)="slider.next()" style="color: #000000;"></i>
    <div class="keen-slider keen-slider--vertical" #sliderRef>
        <div class="keen-slider__slide" *ngFor="let item of items">
            <ng-container [ngTemplateOutlet]="temp" [ngTemplateOutletContext]="{data: item}"></ng-container>
        </div>
    </div>

    <div class="dots " [class.show-on-mobile]="!customConfig?.alwaysShowDots">
        <button (click)="slider.moveToIdx(i);" *ngFor="let slide of dotHelper; let i = index"
            [class]="'dot ' + (i === currentSlide ? 'active' : '')"></button>
    </div>
</div>