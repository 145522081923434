import { Component, Input, OnInit } from '@angular/core';
import { AuthService, UtilityService, ApiService } from '../../../services';
import { CmsBaseComponent } from '../cms-base/cms-base.component';

@Component({
	selector: 'app-page-list',
	templateUrl: './page-list-section.component.html',
	styleUrls: ['./page-list-section.component.scss']
})
export class PageListSectionComponent extends CmsBaseComponent implements OnInit {

	@Input()
	aliasList?: any;
	@Input()
	description?: string;
	@Input()
	linkColor?: string;
	@Input()
	subtitle?: string;
	@Input()
	title?: string;
	@Input()
	template?: number;
	@Input()
	showList: boolean = true;

	constructor(
		public authService: AuthService,
		private apiService: ApiService,
		public utilityService: UtilityService,
	) {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();
		if (!this.aliasList && this.template) {
			this.apiService.get(`api/page/${this.template}/alias`).subscribe((response) => {
				this.aliasList = response;
			}, (error) => { });
		}
	}

	isArray(value: any): boolean {
		return Array.isArray(value);
	}
}
