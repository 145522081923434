<section class="card-package__section" id="packages">

	<app-text-info-section 
		[title]="title | translate" 
		[description]="description" 
		[useLargeHeading]="isFirstSection">
	</app-text-info-section>

	<div class="cards__section-container">
		<div class="card-package__wrapper">
			<div *ngFor="let package of packages;" class="card-package__item">

				<div *ngIf="package.mostPopular" class="card-package__flag"
					[innerHTML]="'texts.CardPackageComponent_flag_new' | translate : {break:'<br>'}">
				</div>

				<div class="card-package__title">
					{{package.title | translate}}
				</div>

				<div class="card-package__text">
					{{'texts.CardPackageComponent_text_1' | translate}}
				</div>

				<div class="card-package__price">
					€{{package.price}}
				</div>

				<i class="fa fa-arrow-down card-package__icon"></i>

				<div class="card-package__text">
					{{'texts.CardPackageComponent_text_2' | translate}}
				</div>

				<div class="card-package__price">
					€{{package.finalPrice}}
				</div>
			</div>
		</div>
	</div>
</section>