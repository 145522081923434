import { Component, Input } from '@angular/core';
import { UtilityService } from '../../../../services';

@Component({
	selector: 'app-question-collection-card',
	templateUrl: './question-collection-card.component.html',
	styleUrls: ['./question-collection-card.component.scss']
})
export class QuestionCollectionCardComponent {

	@Input()
	description!: string
	@Input()
	title!: string

	visible: boolean = false;
	constructor(public utilityService: UtilityService) { }

	toggleVisibility() {
		this.visible = !this.visible;
	}

}
