<a *ngIf="link && preparedLink && !preparedLink.urlObject" [attr.id]="id" [routerLink]="[preparedLink.url] | localize"
	[class]="'link-button ' + class">
	{{text | translate}}
</a>
<a *ngIf="link && preparedLink && preparedLink.urlObject" [attr.id]="id" [href]="preparedLink.url"
	[class]="'link-button ' + class">
	{{text | translate}}
</a>
<a *ngIf="!link" [attr.id]="id" [class]="'link-button ' + class">
	{{text | translate}}
</a>