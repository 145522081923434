import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { NgModule } from '@angular/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { SharedModule } from '../shared/shared.module';
import { FooterSectionComponent } from './footer-section/footer-section.component';
import { ToastComponent } from './toast/toast.component';
import { GeoModule } from 'app/modules/geo/geo.module';
import { MaterialModule } from '../material/material.module';
import { LetsStartNewPackageComponent } from 'app/standalone-components/new-packages/lets-start-new-package/lets-start-new-package.component';
import { NewNavigationSectionComponent } from './navbar/navbar.component';
import { PackageStatusComponent } from 'app/standalone-components/package-status/package-status.component';
import { SlideToggleComponent } from 'app/standalone-components/slide-toggle/slide-toggle.component';


@NgModule({
  declarations: [
    FooterSectionComponent,
    ToastComponent,
    NewNavigationSectionComponent,
  ],
  imports: [
    SharedModule,
    GeoModule,
    LazyLoadImageModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    PackageStatusComponent,
    MaterialModule,
    SlideToggleComponent,
    LetsStartNewPackageComponent],
  exports: [
    NewNavigationSectionComponent,
    FooterSectionComponent,
    ToastComponent,
  ]
})
export class CoreModule {
}
