import { Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/modules/shared/shared.module';
@Component({
  selector: 'app-custom-expansion-panel',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './custom-expansion-panel.component.html',
  styleUrls: ['./custom-expansion-panel.component.scss'],
  animations: [
    trigger('expandCollapse', [
      state('expanded', style({
        height: '*',
        overflow: 'hidden'
      })),
      state('collapsed', style({
        height: '0px',
        overflow: 'hidden'
      })),
      transition('expanded <=> collapsed', [
        animate('300ms ease-in-out')
      ])
    ])
  ]
})
export class CustomExpansionPanelComponent {
  @Input() isExpanded = false;
  @Output() onHeaderClicked = new EventEmitter<boolean>();

  @ViewChild('panelBody', { static: true }) panelBody: ElementRef;

  constructor(private renderer: Renderer2) { }

  togglePanel() {
    this.isExpanded = !this.isExpanded;
  }

  onAnimationDone() {
    const element = this.panelBody.nativeElement;
    setTimeout(() => {
      if (this.isExpanded) {
        this.renderer.setStyle(element, 'overflow', 'visible');
      } else {
        this.renderer.setStyle(element, 'overflow', 'hidden');
      }
    })
  }
}