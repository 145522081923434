import { Appointment } from "./appointment.model"

export interface OverviewAppointmentModel {
  id: string;
  studentID: number;
  tutorID: number;
  date: string;
  time: string;
  timePeriod: string;
  user: string;
  userLink: string;
  courseName: string | null;
  duration: number;
  online: boolean;
  cost: number;
  travel: number | null;
  location: string;
  status: string;
  isCancelled: boolean;
  intro: boolean;
  isCredits: boolean;
  cancellable: boolean;
  model: Appointment;
  _showJoinButton: boolean;
  _isTodayAppointment: boolean;
  _googleMapsLink: string;
}

export interface OverviewPackageModel {
  id: string,
  startDate: string,
  dateEnd: string,
  cost: number,
  discount: number,
  creditsTotal: number,
  creditsRemaining: number,
  status: number,
  // used and generated on UI
  _isUIExpanded: boolean
}

export interface OverviewAppointmentFilterModel {
  begin?: string,
  end?: string
}

export interface OverviewFilterCriteriaModel {
  period: string,
  course: number
}

export interface OverviewFilterResponseModel {
  courses: Array<string>,
  periods: Array<string>
}

export interface OverviewAppointmentPeriodsModel {
  monthShort: string,
  monthVerbose: string
}
