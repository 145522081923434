import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-info-dialog',
  template: `
    <div>{{description | translate}}</div>`,
})
export class InfoDialogComponent {
  @Input() description: string
}
