<section class="sitemap-section__section">
	<div class="sitemap-section__container">
		<app-page-list *ngFor="let list of folderAlias" [aliasList]="list.aliasList" [title]="list.title"
			[subtitle]="list.subtitle" [showList]="showAlias"></app-page-list>

		<div *ngIf="folderAlias && folderAlias.length" class="sitemap-section__toggle-collapse" (click)="toggleAlias()">
			{{'texts.Misc_collapse' | translate}}
			<i class="fas fa-chevron-down"></i>
		</div>
	</div>
	<div class="sitemap-section__container">
		<app-page-list *ngFor="let list of folderFiles" [aliasList]="list.aliasList" [title]="list.title"
			[subtitle]="list.subtitle" [showList]="showFiles"></app-page-list>

		<div *ngIf="folderFiles && folderFiles.length" class="sitemap-section__toggle-collapse" (click)="toggleFiles()">
			{{'texts.Misc_collapse' | translate}}
			<i class="fas fa-chevron-down"></i>
		</div>
	</div>
</section>