import { ComponentFactoryResolver, ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastComponent } from '../modules/core/toast/toast.component';
import { ToastParams, ToastOptions } from '../models';
import { MessageType } from '../constants';

@Injectable({
	providedIn: 'root'
})
export class ToastService {
	public messageInsert!: ViewContainerRef;

	set viewContainerRef(vcr: ViewContainerRef) {
		this.messageInsert = vcr;
	}

	errorTitle!: string;
	infoTitle!: string;
	successTitle!: string;
	warningTitle!: string;

	notificationOptions?: any;

	messageStick: boolean = false;

	messages: ComponentRef<ToastComponent>[] = [];


	constructor(
		private componentFactoryResolver: ComponentFactoryResolver,
		private translationService: TranslateService,
	) { }

	showMsg(text: string, type: MessageType.error | MessageType.info | MessageType.success | MessageType.warning, options: ToastOptions = {}): ComponentRef<ToastComponent> {
		options.timeout = options.timeout == undefined ? 9000 : options.timeout;
		options.showFullMessage = options.showFullMessage == undefined ? false : options.showFullMessage;

		const toastParams: ToastParams = this.configureToastParams(type, text, options);
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ToastComponent);
		const message = this.messageInsert.createComponent(componentFactory);
		message.instance.viewRef = message;
		message.instance.toastParams = toastParams;

		if (this.messageStick) {
			this.messageStick = false;
		}
		if (options.timeout != 'stick' && typeof options.timeout === 'number') {
			this.autoDismissMsg(message, options.timeout);
		} else {
			this.messageStick = true;
		}

		this.messages.push(message);
		return message;
	}

	autoDismissMsg(messageRef: ComponentRef<ToastComponent>, timeout = 9000) {
		setTimeout(() => {
			if (this.messageStick) {
				this.messageStick = false;
			} else {
				this.dismissMsg(messageRef);
			}
		}, timeout);
	}

	dismissMsg(messageRef: ComponentRef<ToastComponent>) {
		const index = this.messages.findIndex(e => e == messageRef);
		if (index > -1) {
			this.messages.splice(index, 1);
		}
		messageRef.destroy();
	}

	configureToastParams(type: MessageType.error | MessageType.info | MessageType.success | MessageType.warning, text: string, options: ToastOptions): ToastParams {
		this.translationService.get([
			'texts.MessageComponent_error_title',
			'texts.MessageComponent_info_title',
			'texts.MessageComponent_success_title',
			'texts.MessageComponent_warning_title',
		]).subscribe(translations => {
			this.errorTitle = translations['texts.MessageComponent_error_title'];
			this.infoTitle = translations['texts.MessageComponent_info_title'];
			this.successTitle = translations['texts.MessageComponent_success_title'];
			this.warningTitle = translations['texts.MessageComponent_warning_title'];
		});

		let result = {
			iconHTML: "",
			description: text?.length > 150 && !options.showFullMessage ? text?.substring(0, 150) + ' ..' : text,
			link: options.link,
			title: options.title,
			type: type,
		};
		switch (type) {
			case MessageType.error:
				result.iconHTML = '<i class="fa fa-close"></i>';
				if (!result.title) {
					result.title = this.errorTitle;
				}
				break;
			case MessageType.success:
				result.iconHTML = '<i class="fas fa-check"></i>';
				if (!result.title) {
					result.title = this.successTitle;
				}
				break;
			case MessageType.warning:
				result.iconHTML = '<i class="fas fa-exclamation"></i>';
				if (!result.title) {
					result.title = this.warningTitle;
				}
				break;
			default: // MessageType.info
				result.iconHTML = '<div>i</div>';
				if (!result.title) {
					result.title = this.infoTitle;
				}
				break;
		}
		if (options.image?.src) {
			result.iconHTML = `<img src="${options.image.src}" ${(options.image.alt) ? `onerror="setInitials(this, '${options.image.alt}');"` : ``}>`;
		}
		return result;
	}

	getInitials(alt: any) {
		return `<span>${alt}</span>`;
	}

}
