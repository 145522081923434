import { HttpParams } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ApiService } from '../../../services';
import { CmsBaseComponent } from '../cms-base/cms-base.component';

@Component({
	selector: 'app-sitemap',
	templateUrl: './sitemap.component.html',
	styleUrls: ['./sitemap.component.scss']
})
export class SitemapComponent extends CmsBaseComponent implements OnInit {

	sub: any;

	folderAlias: { [key: string]: any; }[] = [];
	folderFiles: { [key: string]: any; }[] = [];

	offset: number = 0;
	limit: number = 50000;
	type?: string;

	isLoadFiles: boolean = false;
	isSiteMap: boolean = false;

	showAlias: boolean = true;
	showFiles: boolean = true;

	constructor(
		private cd: ChangeDetectorRef,
		private apiService: ApiService,
		private route: ActivatedRoute,
	) {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.route.queryParams.subscribe(params => {
			this.initParams(params);
			this.initComponent();
		});
	}

	initParams(params: Params): void {
		this.offset = Number(params['offset']);
		this.offset = isNaN(this.offset) ? 0 : this.offset;
		this.limit = Number(params['limit']);
		this.type = params['type'];
	}

	/**
	 * Initiates the component
	 */
	initComponent(): void {
		let endpoint = 'page/urls';

		this.isSiteMap = this.type !== undefined;
		this.isLoadFiles = this.type == 'file';
		if (this.isSiteMap) {
			this.loadData(endpoint, !this.isLoadFiles ? 'Sitemap' : 'Afbeeldingen');
		} else {
			this.loadSitemapOverview();
		}
		this.cd.detectChanges();
	}

	loadData(endpoint: string, title: string) {
		let params = new HttpParams()
			.set('group', '1')
			.set('offset', this.offset.toString())
			.set('limit', this.limit.toString());

		this.apiService.get(`api/${endpoint}`, params).subscribe((response) => {
			const aliasList: { [key: string]: any; } = response;
			let output: { [key: string]: any; }[] = [];
			for (const folder in aliasList) {
				const files = aliasList[folder];
				output.push({
					'subtitle': !folder ? "Zonder map" : folder,
					'aliasList': files
				});
			}
			if (title) {
				output[0]['title'] = title;
			}
			if (this.isSiteMap) {
				this.folderAlias = output;
			} else if (this.isLoadFiles) {
				this.folderFiles = output;
			}
			this.cd.detectChanges();
		}, (error) => {
		});
	}

	loadSitemapOverview(): void {
		this.apiService.get(`api/page/count`).subscribe((response: { count?: number; }) => {
			let pageCount = response.count ? response.count : 0;
			this.folderAlias = [
				{
					'title': 'pages',
					'subtitle': 'User Links',
					'aliasList': []
				},
				{
					'subtitle': 'XML',
					'aliasList': []
				}
			];

			this.folderFiles = [
				{
					'title': 'files',
					'subtitle': 'User Links',
					'aliasList': [
						{
							'url': ['/admin/sitemap',
								{
									queryParams: {
										'type': 'file'
									}
								}],
							'fileName': 'All files'
						}
					]
				}
			];

			for (let interval = 0; interval < pageCount; interval += this.limit) {
				this.folderAlias[0]['aliasList'].push({
					'url': ['/admin/sitemap',
						{
							queryParams: {
								offset: interval.toString(),
								limit: this.limit.toString(),
								type: 'page',
							}
						}],
					'fileName': `${interval} - ${interval + this.limit} Links`
				});
				this.folderAlias[1]['aliasList'].push({
					'url': ['/admin/sitemap',
						{
							queryParams: {
								offset: interval.toString(),
								limit: this.limit.toString(),
								type: 'page',
							}
						}],
					'fileName': `${interval} - ${interval + this.limit} Links`
				});
			}
			this.cd.detectChanges();
		}, (error) => {
		});
	}


	toggleAlias(): void {
		this.showAlias = !this.showAlias;
	}

	toggleFiles(): void {
		this.showFiles = !this.showFiles;
	}
}
