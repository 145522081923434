<picture *ngIf="!pageConfigService.isSafari()" class="tutoring-info__image__media">
	<ng-template *ngIf="img.sources">
		<source *ngFor="let sources of img.sources"
			[media]="(sources.maxScreenSize ? '(max-width: ' + sources.maxScreenSize + 'px)' : '(min-width: ' + sources.minScreenSize + 'px)')"
			[sources]='sources.src' sizes='(max-width: 256px) 100vw, 256px'>
	</ng-template>
	<img [lazyLoad]="img.src" [alt]="img.alt" style="width:auto;">
</picture>

<img *ngIf="pageConfigService.isSafari()" [lazyLoad]="img.src" [alt]="img.alt" class="tutoring-info__image__media"
	sources="getsources()" sizes="getSizes()" style="width:auto;">