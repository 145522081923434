
import { Component, Input, OnInit } from '@angular/core';
import { CmsBaseComponent } from '../../cms/cms-base/cms-base.component';

@Component({
	selector: 'app-card-slider',
	templateUrl: './card-slider.component.html',
	styleUrls: ['./card-slider.component.scss']
})
export class CardSliderComponent extends CmsBaseComponent implements OnInit {

	@Input()
	title: string = 'texts.CardSliderSection_title'
	isTitleGreen = true;

	constructor(
	) {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();
	}

}