<div class="package-cards-container">
    <div class="arrow-back" *ngIf="isPaymentMethodOpened" (click)="$event.stopPropagation()">
        <i class="fas fa-chevron-left" (click)="previousStep()"></i>
    </div>
    <div class="info-container">
        <div class="info-title" #title translate>texts.Package_Cards_Component_Title</div>
        <ng-container *ngIf="!packageSelectedToBuy">
            <div class="info-description" *ngIf="!isModificationsAllowed && !isExamMode" translate>
                texts.Package_Cards_Component_Body_Text
            </div>
            <div class="info-description" *ngIf="!isModificationsAllowed && isExamMode" translate>
                texts.Package_Cards_Component_Body_Exam_Text
            </div>
        </ng-container>
    </div>

    <div class="cards-container" [ngClass]="{'alternative-packages': showAlternativePackages}">
        <ng-container *ngIf="!isPaymentMethodOpened">
            <div class="card-container"
                [ngClass]="{'dissapear': (packageSelectedToBuy?.packageId !== card.packageId && isPaymentMethodOpened)}"
                *ngFor="let card of packageCards">
                <div class="details-and-button">
                    <div class="package-details">
                        <div class="duration duration-hours" *ngIf="!card._isExamPackageVersion">
                            {{card._totalLessonsInHours}}&nbsp;
                            <div translate>
                                texts.Package_Cards_Component_Hours
                            </div>
                        </div>
                        <div class="lesson-per-month" *ngIf="!card._isExamPackageVersion" translate
                            [translateParams]="{ durationMonth : card.durationMonths}">
                            texts.Package_Cards_Component_Package_Duration_Month
                        </div>
                        <div class="cost-per-hour-container">
                            <div class="cost">{{card.costPerHour | currencyFormatter}}</div>
                            <div class="hour">&nbsp;<span translate>texts.Misc_Hourly</span></div>
                        </div>
                    </div>
                    <button class="select-button" (click)="packageSelectedToBuy=card; openPaymentMethod()">
                        <span *ngIf="!isPaymentMethodOpened" translate>texts.Misc_select</span>
                        <app-svg-icon *ngIf="isPaymentMethodOpened" class="checkmark"
                            name="Unread-white"></app-svg-icon>
                        <span *ngIf="isPaymentMethodOpened" translate> texts.Package_Selected</span>
                    </button>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="payment-opened" [ngClass]="{'payment-opened-animation':isPaymentMethodOpened}">
        <div class="payment-methods-container"
            [ngClass]="{'appear-payments': isPaymentMethodOpened && paymentMethod !== PaymentMethod.Subsidy}">
            <div [ngClass]="{'active-payment': paymentMethod === PaymentMethod.OneInstallment}">
                <div class="payment-method-container" (click)="togglePaymentMethod(PaymentMethod.OneInstallment)">
                    <div class="payment-method-toggle-button"></div>
                    <div class="payment-method-text-container">
                        <div class="payment-method-title" translate>
                            texts.Package_One_Time_Pay
                        </div>
                        <div class="payment-method-price-and-multiplier">
                            <div class="multiplier">1&nbsp;x&nbsp;</div>
                            <div class="payment-method-price">{{
                                packageSelectedToBuy?._totalPrice |
                                noDecimalCurrencyFormatter}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div [ngClass]="{'active-payment': paymentMethod === PaymentMethod.PayMonthly}">
                <div class="payment-method-container" (click)="togglePaymentMethod(PaymentMethod.PayMonthly)">
                    <div class="payment-method-toggle-button"></div>
                    <div class="payment-method-text-container" translate="">
                        <div class="payment-method-title" translate>
                            texts.Package_Multi_Time_Pay
                        </div>
                        <div class="payment-method-price-and-multiplier">
                            <div class="multiplier"> {{packageSelectedToBuy?.totalInstallment}}&nbsp;x&nbsp;</div>
                            <div class="payment-method-price">{{
                                packageSelectedToBuy?.pricePerInstallment |
                                noDecimalCurrencyFormatter}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="subsidy-pay-container"
            [ngClass]="{'appear-subsidy-container': isPaymentMethodOpened && paymentMethod === PaymentMethod.Subsidy}">
            <div class="payment-method-text-container  subsidy-method-text-container">
                <div class="payment-method-title subsidy-payment-method-title" translate>
                    texts.Package_Subsidy_Pay</div>
                <div class="payment-method-bonus subsidy-payment-method-bonus" translate>
                    texts.Package_Subsidy_Description</div>
                <div class="payment-method-bonus subsidy-payment-method-bonus" translate
                    [translateParams]="{ price:packageSelectedToBuy?._totalPrice}">
                    texts.Package_Subsidy_Price</div>
            </div>
        </div>
    </div>

    <div class="subsidy-and-confirm-container"
        [ngClass]="{'appear-checkbox-and-confirm': isPaymentMethodOpened, isWarning: isWarning}">
        <div class="checkbox-and-label-container">
            <input class="subsidy-checkbox" type="checkbox" id="subsidy" name="subsidy"
                [checked]="paymentMethod === PaymentMethod.Subsidy"
                (click)="togglePaymentMethod(PaymentMethod.Subsidy)">
            <label class="subsidy-label" for="subsidy" translate> texts.Package_Subsidy_Checkbox_Label</label><br>
            <app-svg-icon class="checkmark" name="Unread-white"></app-svg-icon>
        </div>
        <div *ngIf="isWarning" class="warning">
            <div>
                <app-svg-icon name="warning"></app-svg-icon>
            </div>
            <div class="warning-description">
                <div class="warning-title" translate> texts.Payment_Failed_Title </div>
                <div class="warning-description-text" translate> texts.Payment_Failed_Description </div>
            </div>
        </div>
        <div *ngIf="!isWarning" class="button confirm-button" (click)="paymentMethod && openOrConfirmSpecificPackage()"
            [ngClass]="{'disabled': !paymentMethod}" translate>
            texts.Modal_Confirm_Title
        </div>
        <div *ngIf="isWarning" class="button confirm-button" (click)="paymentMethod && tryAgain()" translate>
            texts.Payment_Failed_Try_Again
        </div>
        <div class="payment-options-icons-container">
            <ng-container *ngIf="utilityService.isDE">
                <app-svg-icon name="GPAY"></app-svg-icon>
                <app-svg-icon name="MASTERCARD"></app-svg-icon>
                <app-svg-icon name="PAYPAL"></app-svg-icon>
                <app-svg-icon name="VISA"></app-svg-icon>
            </ng-container>
            <app-svg-icon *ngIf="utilityService.isNL" name="IDEAL"></app-svg-icon>
        </div>
    </div>

    <div class="additional-info-container">
        <div class="additional-info-text" translate>texts.Package_Cards_Component_Additional_Info_Text &nbsp;</div>
        <a class="additional-info-link" [routerLink]="['/Misc_terms'] | localize" target="_blank" translate>
            texts.Package_Cards_Component_Additional_Info_Link</a>
    </div>
    <div class="money-back" translate>texts.Package_Cards_Component_Money_back_guarantee_text</div>
    <app-faq-list [faqTitle]="faqTitle" [faqListItems]="faqListItems"></app-faq-list>
</div>