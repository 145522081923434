import { PageSection } from './page-section.model';

export class PageTemplate {
	id!: number;
	template!: string;
	name!: string;
	title!: string;
	description!: string;
	socialMediaTitle?: string;
	socialMediaDescription?: string;
	image?: string;
	protected!: boolean;
	updated_at!: string;
	config: any;
	country!: string;
	sections!: PageSection[];
	version!: string;
	siteMap!: number

	variables?: {
		referral_bonus_value?: number;
	};

	constructor(user: Partial<PageTemplate>) {
		Object.assign(this, user);
	}
}
