import { DOCUMENT } from '@angular/common';
import {
    Directive,
    Output,
    EventEmitter,
    HostBinding,
    HostListener,
    ElementRef,
    Renderer2,
    Inject
} from '@angular/core';


@Directive({
    selector: '[appDnd]'
})
export class DndDirective {

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
        @Inject(DOCUMENT) private document: Document
    ) { }

    @Output('onFileDropped') onFileDropped = new EventEmitter<any>();
    @HostBinding('class.fileover') fileOver: boolean = false;


    @HostListener('dragover', ['$event']) onDragOver(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        if (this.fileOver == false) {
            this.renderer.appendChild(this.elementRef.nativeElement.parentElement, this.createOverlay());
        }
        this.fileOver = true;
    }

    @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.fileOver = false;
        this.document.getElementById('chat-overlay')?.remove()
    }

    @HostListener('drop', ['$event']) public ondrop(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.fileOver = false;
        this.elementRef.nativeElement.style = "blur(0)"
        let files = evt.dataTransfer.files;
        if (files.length > 0) {
            this.onFileDropped.emit(files);
        }
        this.document.getElementById('chat-overlay')?.remove()
    }

    private createOverlay() {
        let div = this.document.createElement('div');
        div.id = 'chat-overlay';
        const style = div.style;
        style.width = '100%'
        style.height = '100%'
        style.position = 'absolute'
        style.left = '0'
        style.top = '0'
        style['pointer-events'] = 'none'
        style.display = 'flex'
        style.justifyContent = 'center';
        style.alignItems = 'center'
        style.fontSize = "20px"
        div.innerHTML = `<i class="far fa-file-plus"></i>`
        return div;
    }
}

