import { UtilityService } from 'app/services';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { map, Observable, of } from 'rxjs';
import { AuthService } from '../services';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard  {

	constructor(
		private authService: AuthService,
		private router: Router,
		private utilityService: UtilityService
	) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
		// On the server we do not block the routing - AKA we allow the pages to be rendered
		if (!this.utilityService.isBrowser) {
			return of(true);
		}

		return this.authService.isAuthenticated.pipe(
            map((userInfo) => {
                if (userInfo) {
                    return true;
                }
				this.router.navigate(['/401'], !route.data.notFound ? { queryParams: { returnUrl: state.url } } : {});
				return false;
            })
        );
	}
}