import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/modules/shared/shared.module';

@Component({
  selector: 'app-google-stars',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './google-stars.component.html',
  styleUrls: ['./google-stars.component.scss']
})
export class GoogleStarsComponent {
  @Input('rating') rating: string;
  wholeStars: number;
  starArray = []

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.initRating()
    this.cdr.detectChanges();
  }

  initRating() {
    this.wholeStars = parseInt(this.rating.slice(5, 6))
    for (let i = 0; i < this.wholeStars; i++) {
      this.starArray.push('google-star')
    }
    if (this.rating.slice(7, 8) === '5') {
      this.starArray.push('google-star-not-full')
    }
  }
}
