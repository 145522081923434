import { Component, Input, OnInit } from '@angular/core';
import { CmsBaseComponent } from '../cms-base/cms-base.component';

@Component({
	selector: 'app-action-header',
	templateUrl: './action-header.component.html',
	styleUrls: ['./action-header.component.scss']
})
export class ActionHeaderComponent extends CmsBaseComponent implements OnInit {


	@Input()
	title?: string;
	@Input()
	description?: string;

	@Input()
	showStorybrandSteps: boolean = false;
	@Input()
	showLogoGroup: boolean = false;
	@Input()
	showEmpathy: boolean = false;

	@Input()
	showButton: boolean = false;
	@Input()
	buttonText: string = "";
	@Input()
	buttonLink: string = "";

	constructor() {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();
	}

}
