import { Pipe, PipeTransform } from '@angular/core';
import { UtilityService } from 'app/services';

@Pipe({ name: 'unescape' })
export class UnescapePipe implements PipeTransform {

    constructor(private utilityService: UtilityService) { }

    transform(input: any): any {
      return this.utilityService.unescapeHTML(input);
    }
}