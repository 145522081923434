import { MaterialModule } from './../material/material.module';
import { NgModule } from '@angular/core';
import { LazyLoadImageModule, LAZYLOAD_IMAGE_HOOKS } from 'ng-lazyload-image';
import { InfoModule } from '../info/info.module';
import { CardBasicComponent } from './card-basic/card-basic.component';
import { CardPackageComponent } from './card-package/card-package.component';
import { CardResponsiveSliderComponent } from './card-responsive-slider/card-responsive-slider.component';
import { CardSliderComponent } from './card-slider/card-slider.component';
import { CardTopicCardComponent } from './card-topic-card/card-topic-card.component';
import { CardTopicComponent } from './card-topic/card-topic.component';
import { CardWideComponent } from './card-wide/card-wide.component';
import { LazyLoadImageHooks } from '../../hooks/lazy-load-image-hooks';
import { SharedModule } from '../shared/shared.module';
import { NewTrustpilotCarouselModule } from '../new-trustpilot-carousel/new-trustpilot-carousel.module';

@NgModule({
	declarations: [
		CardBasicComponent,
		CardPackageComponent,
		CardResponsiveSliderComponent,
		CardSliderComponent,
		CardTopicComponent,
		CardTopicCardComponent,
		CardWideComponent
	],
	imports: [
		SharedModule,
		InfoModule,
		LazyLoadImageModule,
		MaterialModule,
		NewTrustpilotCarouselModule,
	],
	exports: [
		CardBasicComponent,
		CardPackageComponent,
		CardResponsiveSliderComponent,
		CardSliderComponent,
		CardTopicComponent,
		CardTopicCardComponent,
		CardWideComponent,
	],
	providers: [
		{
			provide: LAZYLOAD_IMAGE_HOOKS,
			useClass: LazyLoadImageHooks
		},
	]
})
export class CardModule { }
