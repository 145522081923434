<section class="searchbar-header__section">
	<div class="searchbar-header__wrapper">
		<div class="searchbar-header__container">
			<div class="searchbar-header__text">
				<h1 *ngIf="title && isFirstSection" class="searchbar-header__text__title" [innerHTML]="title | unescape">
				</h1>
				<h2 *ngIf="title && !isFirstSection" class="searchbar-header__text__title" [innerHTML]="title | unescape">
				</h2>
				<h1 *ngIf="!title && description && isFirstSection; else description2" class="searchbar-header__text__description"
					[innerHTML]="description | unescape"></h1>
				<ng-template #description2>
					<h3 *ngIf="description" class="searchbar-header__text__description" [innerHTML]="description | unescape"></h3>
				</ng-template>
			</div>
			<app-search [ngClass]="{'sticky': isOneInput}" [isOneInput]="isOneInput"
				[canSearchAfterDismissed]="false"></app-search>
		</div>
	</div>
</section>
<div #breakpoint></div>
<app-storybrand-steps *ngIf="showStorybrandSteps"></app-storybrand-steps>
<app-logo-group *ngIf="showLogoGroup" [showGoogle]="showGoogleReviews"></app-logo-group>
<app-empathy-statement *ngIf="showEmpathy" [showLogoGroup]="showLogoGroup"></app-empathy-statement>