import { Component, Input, OnInit } from '@angular/core';
import { UtilityService } from '../../../services';
import { CmsBaseComponent } from '../cms-base/cms-base.component';

@Component({
	selector: 'app-button-section',
	templateUrl: './button-section.component.html',
	styleUrls: ['./button-section.component.scss']
})
export class ButtonSectionComponent extends CmsBaseComponent implements OnInit {

	@Input()
	buttonPosition?: string;
	@Input()
	buttonText!: string;
	@Input()
	buttonLink!: string;
	@Input()
	isMediaButton!: string;

	constructor(
		private utilityService: UtilityService,
	) {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.buttonLink = this.isMediaButton ? this.buttonLink : this.utilityService.prepareLink(this.buttonLink).url;
	}
}
