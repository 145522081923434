<div class="tutoring-info__tutor-container">
	<div class="tutoring-info__tutor-card">
		<img class="tutoring-info__tutor-card__img" [lazyLoad]="'images.TutoringInfoMediaTutoringCards_card_one.originalSource' | translate"
			[alt]="'images.TutoringInfoMediaTutoringCards_card_one.title' | translate">
		<div class="tutoring-info__tutor-card__tutor-details">
			<div class="tutoring-info__tutor-card__tutor-name">{{'texts.TutoringInfoMediaTutoringCards_card_one_name' |
				translate}}
			</div>
			<span>
				{{'texts.TutoringInfoMediaTutoringCards_card_one_level' | translate}}
			</span>
		</div>
	</div>
	<div class="tutoring-info__tutor-card">
		<img class="tutoring-info__tutor-card__img" [lazyLoad]="'images.TutoringInfoMediaTutoringCards_card_two.originalSource' | translate"
			[alt]="'images.TutoringInfoMediaTutoringCards_card_two.title' | translate">
		<div class="tutoring-info__tutor-card__tutor-details">
			<div class="tutoring-info__tutor-card__tutor-name">
				{{'texts.TutoringInfoMediaTutoringCards_card_two_name' | translate}}
			</div>
			<span>
				{{'texts.TutoringInfoMediaTutoringCards_card_two_level' | translate}}
			</span>
		</div>
	</div>
	<div class="tutoring-info__tutor-card">
		<img class="tutoring-info__tutor-card__img" [lazyLoad]="'images.TutoringInfoMediaTutoringCards_card_three.originalSource' | translate"
			[alt]="'images.TutoringInfoMediaTutoringCards_card_three.title' | translate">
		<div class="tutoring-info__tutor-card__tutor-details">
			<div class="tutoring-info__tutor-card__tutor-name">
				{{'texts.TutoringInfoMediaTutoringCards_card_three_name' | translate}}
			</div>
			<span>
				{{'texts.TutoringInfoMediaTutoringCards_card_three_level' | translate}}
			</span>
		</div>
	</div>
	<div class="tutoring-info__tutor-card">
		<img class="tutoring-info__tutor-card__img" [lazyLoad]="'images.TutoringInfoMediaTutoringCards_card_four.originalSource' | translate"
			[alt]="'images.TutoringInfoMediaTutoringCards_card_four.title' | translate">
		<div class="tutoring-info__tutor-card__tutor-details">
			<div class="tutoring-info__tutor-card__tutor-name">
				{{'texts.TutoringInfoMediaTutoringCards_card_four_name' | translate}}
			</div>
			<span>
				{{'texts.TutoringInfoMediaTutoringCards_card_four_level' | translate}}
			</span>
		</div>
	</div>
</div>