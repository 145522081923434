<div class="info-page__header">
	<ng-content select="[info-page-header]"></ng-content>
</div>

<div class="info-page__body">
  <ng-content select="[info-page-body]"></ng-content>
</div>

<div class="info-page__footer">
  <ng-content select="[info-page-footer]"></ng-content>
</div>