<router-outlet name="modal"></router-outlet>
<div *ngIf="pageLoadService.pageTemplate as pageTemplate">
	<ng-template ngFor let-section [ngForOf]="pageTemplate.sections">
		<div *ngIf="section.component == 'button-section'"></div>
		<div>
			<app-error-page
				*ngIf="section.component == 'error-401' || section.component == 'error-403' || section.component == 'error-404' || section.component == 'error-500'"
				[section]="section">
			</app-error-page>


			<app-action-header *ngIf="section.component == 'action-header-section'" [section]="section">
			</app-action-header>

			<app-action-header-two *ngIf="section.component == 'action-header-two-section'" [section]="section">
			</app-action-header-two>

			<app-blog-post-section *ngIf="section.component == 'blog-post-section'"
				[section]="section"></app-blog-post-section>

			<app-button-section *ngIf="section.component == 'button-section'" [section]="section">
			</app-button-section>

			<app-cms-section-wrapper *ngIf="section.component == 'package-details'" [section]="section">
			</app-cms-section-wrapper>

			<app-card-basic *ngIf="section.component == 'card' || section.component == 'card-numbers'"
				[section]="section">
			</app-card-basic>

			<app-card-package *ngIf="section.component == 'card-package'" [section]="section"></app-card-package>

			<app-card-responsive-slider *ngIf="section.component == 'card-responsive-slider'" [section]="section">
			</app-card-responsive-slider>

			<app-card-slider *ngIf="section.component == 'card-slider'" [section]="section"></app-card-slider>

			<app-card-topic *ngIf="section.component == 'card-topic'" [section]="section"></app-card-topic>

			<app-card-wide *ngIf="section.component == 'card-wide'" [section]="section"></app-card-wide>

			<app-center-text *ngIf="section.component == 'center-text-section'" [section]="section"></app-center-text>

			<app-contact-form-section
				*ngIf="section.component == 'contact-form' || section.component == 'contact-form-small'"
				[section]="section"></app-contact-form-section>

			<app-contact-header-section *ngIf="section.component == 'contact-header'" [section]="section">
			</app-contact-header-section>

			<app-contact-popup *ngIf="section.component == 'contact-popup-section'" [section]="section">
			</app-contact-popup>

			<app-counting-number *ngIf="section.component == 'counting-number'" [section]="section">
			</app-counting-number>

			<app-follow-up-button-section *ngIf="section.component == 'followup-button'" [section]="section">
			</app-follow-up-button-section>

			<app-hubspot-section *ngIf="section.component == 'hubspot-section'" [section]="section">
			</app-hubspot-section>

			<app-link-list-section *ngIf="section.component == 'link-list-section'" [section]="section">
			</app-link-list-section>

			<app-link-tab-section *ngIf="section.component == 'link-tab-section'" [section]="section">
			</app-link-tab-section>

			<app-page-list *ngIf="section.component == 'page-list-section'" [section]="section"></app-page-list>

			<app-question-collection-section *ngIf="section.component == 'question-collection'" [section]="section">
			</app-question-collection-section>

			<app-searchbar-header-section *ngIf="section.component == 'searchbar-header'" [section]="section">
			</app-searchbar-header-section>

			<app-searchbar-section *ngIf="section.component == 'searchbar-section'" [section]="section">
			</app-searchbar-section>

			<app-sitemap *ngIf="section.component == 'site-map'" [section]="section"></app-sitemap>

			<app-text-info-section *ngIf="section.component == 'text-info-section'" [section]="section">
			</app-text-info-section>

			<app-table *ngIf="section.component == 'table-section'" [section]="section"></app-table>

			<app-bonus-table *ngIf="section.component == 'bonus-table-section'" [section]="section"></app-bonus-table>

			<app-tutoring-info-section *ngIf="section.component == 'tutoring-info'" [section]="section">
			</app-tutoring-info-section>

		</div>
	</ng-template>
</div>