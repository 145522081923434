<div class="text-info__text">
	<h1 *ngIf="title && useLargeHeading; else title2" class="text-info__text__title" [bindHTML]="title"></h1>
	<ng-template #title2>
		<h2 class="text-info__text__title" [bindHTML]="title">
		</h2>
	</ng-template>
	<h2 *ngIf="!title && subtitle && useLargeHeading; else subtitle2" class="text-info__text__title" [bindHTML]="subtitle">
	</h2>
	<ng-template #subtitle2>
		<h3 class="text-info__text__subtitle" [bindHTML]="subtitle"></h3>
	</ng-template>
	<div *ngIf="description" class="text-info__text__description" [bindHTML]="description"></div>
</div>