import { Component, Input } from '@angular/core';
import { CardTopicContent } from 'app/models';

@Component({
	selector: 'app-card-topic-card',
	templateUrl: './card-topic-card.component.html',
	styleUrls: ['./card-topic-card.component.scss']
})
export class CardTopicCardComponent {
	@Input()
	card!: CardTopicContent
}
