<div class="login-or-register">

  <div class="login-or-register__title">
    {{ 'texts.Modal_register_log_in_title' | translate }}
  </div>

  <div class="login-or-register__text">
    {{ 'texts.Modal_register_log_in_body_text'| translate}}
  </div>

  <div class="login-or-register__button-wrapper">
    <div class="login-or-register__button" (click)="onRegisterModalShow()">
      {{ 'texts.Modal_register_log_in_register_button' | translate }}
    </div>
  </div>
  <div class="login-or-register__button-wrapper">
    <div class="login-or-register__link-button" (click)="onLoginModalShow()">
      {{ 'texts.Modal_register_log_in_log_in_button' | translate }}
    </div>
  </div>

</div>