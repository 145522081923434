import { Injectable } from '@angular/core';
import { SequenceOptions } from 'app/constants/packages';
import { UserObj, UserStateEnum } from 'app/microservice-clients/user';

export type Cases = {
  testCase1: boolean | undefined;
  testCase2: boolean | undefined;
  testCase3: boolean | undefined;
}

@Injectable({
  providedIn: 'root'
})

export class AbTestService {

  cases: Cases

  setAbTestDataForStudents(user: UserObj) {
    const id = +user.id;
    const createdMilisec = +user.createdAtMillis
    this.cases = {
      testCase1: this.setDataForCase1(user.state, id),
      testCase2: this.setDataForCase2(id),
      testCase3: this.setDataForCase3(createdMilisec)
    }
  }

  setDataForCase1(state: UserStateEnum, id: number): boolean {
    // For users with id ending 0-4, we show package after register part 2 is finished, for users with id ending 5-9, we show package after the first booking is made

    const idLastDigit = id % 10
    if ((idLastDigit >= 0 && idLastDigit <= 4) &&
      (state === UserStateEnum.Active ||
        state === UserStateEnum.Introducing ||
        state === UserStateEnum.Tutoring)) {
      return true
    }

    if ((idLastDigit >= 5 && idLastDigit <= 9) &&
      (state === UserStateEnum.Introducing ||
        state === UserStateEnum.Tutoring)) {
      return true
    }
    return false
  }

  setDataForCase2(id: number): boolean {
    // For users with even id, we add a 4 lessons per week option, for users with odd id, we don't

    if (id % 2 === 0 && SequenceOptions.length === 3) {
      const lessonOptionUnderTesting = {
        text: 'texts.Pricing_Page_Packages_lessons_per_week_4',
        textMonthly: 'texts.Pricing_Page_Packages_lessons_per_month_16',
        value: '4',
        isActive: false
      }
      SequenceOptions.unshift(lessonOptionUnderTesting)
      return true
    }
    return false
  }

  setDataForCase3(createdMilisec) {
    // For users created in odd seconds we show a 4 month package on package cards, instead of 3 months. For users created in even seconds, we show 3 months

    if (createdMilisec % 2 === 0) {
      return true
    }
    return false
  }
}
