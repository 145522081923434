import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { SharedModule } from 'app/modules/shared/shared.module';
import { LoginDialogComponent } from '../login-dialog/login-dialog.component';
import { StudentRegisterDialogComponent } from '../student-register-dialog/student-register-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PopupService } from 'app/services';

@Component({
  selector: 'app-login-or-register-frame',
  templateUrl: './login-or-register-frame.component.html',
  styleUrls: ['./login-or-register-frame.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedModule, LoginDialogComponent, StudentRegisterDialogComponent]
})
export class LoginOrRegisterFrame {

  @Input() isCloseButtonVisible: boolean = true;

  constructor(
    private dialogRef: MatDialog,
    private router: Router,
    private popupService: PopupService
  ) { }

  closeDialogAndNavigate() {
    this.closeDialog();
    this.router.navigate(['/']);
  }

  closeDialog() {
    this.dialogRef.closeAll();
    this.popupService.onClose.emit();
  }
}