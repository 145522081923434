import { ToastService } from './../../../services/toast.service';
import { Component, Output, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { MessageType } from 'app/constants';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponent {

  constructor(private toastService: ToastService) { }

  @ViewChild('uploadInput') uploadInput: ElementRef;

  @Output('onUpload') onUpload = new EventEmitter<File>();

  private allowedExtensions = ['jpg', 'jpeg', 'png', 'heic', 'gif', 'pdf',
    'txt', 'rtf', // text documents
    'doc', 'docx', 'docm', 'odt', //word formats
    'csv',
    'xlsx', 'xlsm', 'xlsb', 'xltx', 'xltm', 'xls', 'xlt', 'xml', 'xlam', 'xla', 'xlw', 'xlr',  // excel formats
    'pptx', 'pptm', 'ppt', 'xps', 'potx', 'potm', 'pot', 'thmx', 'ppsx', 'ppsm', 'pps', 'ppa' // powerpoint formats
  ];

  async loadFile(files: Array<File>) {
    if (files.length < 1) return;

    if (files.length == 1) {
      let extenstion = this.getFileExtension(files[0].name);
      if (this.checkFileSize(files[0]) && this.checkFileType(extenstion)) {
        this.onUpload.emit(files[0])
      }
    } else {
      Array.from(files).forEach(file => {
        let extenstion = this.getFileExtension(file.name)
        if (this.checkFileSize(file) && this.checkFileType(extenstion)) {
          this.onUpload.emit(file)
        }
      })

    }
  }

  private getFileExtension(name: string) {
    return name.substring(name.lastIndexOf(".") + 1);
  }

  private checkFileType(extension: string) {
    if (this.allowedExtensions.includes(extension.toLowerCase())) {
      return true;
    } else {
      this.notSupTypeAlert();
      return false;
    }
  }

  private notSupTypeAlert() {
    this.toastService.showMsg('texts.Attachment_file_not_supported', MessageType.warning);
  }

  private checkFileSize(file: File) {
    let fileSize = file.size / 1024 / 1024;
    if (fileSize > 10) {
      this.toastService.showMsg('texts.Attachment_file_to_big', MessageType.warning);
      return false;
    } else {
      return true;
    }
  }
}