import { EventEmitter, Injectable, Output } from "@angular/core";
import { IntroFeedbackDialogComponent } from "app/standalone-components/booking/intro-feedback-dialog/intro-feedback-dialog.component";
import { PopupService } from "./popup.service";
import { Appointment } from "app/models";

@Injectable({
  providedIn: "root",
})
export class FeedbackFlowForIntroService {
  timerForIntro: number;
  appointment: Appointment;
  boundOnVisibilityChange: any;

  @Output() onBookLesson = new EventEmitter<void>();

  constructor(private popupService: PopupService) {
    this.boundOnVisibilityChange = this.onVisibilityChange.bind(this);
  }

  isTimeWithinFifteenMinutes(timeStamp: string): boolean {
      const appointmentBeginDate = new Date(timeStamp);
      const now = new Date();
      const differenceInMs = now.getTime() - appointmentBeginDate.getTime();
      const differenceInMinutes = Math.abs(differenceInMs / (1000 * 60));
      return differenceInMinutes <= 15 || now >= appointmentBeginDate;
  }

  openFeedbackDialog(tutorData): void {
    let dialogComp = this.popupService.openResponsiveDialog(IntroFeedbackDialogComponent).componentInstance;
    dialogComp.tutorBasicData = tutorData;
    dialogComp.onBookLesson.subscribe(() => {
      this.onBookLesson.emit();
    });
    localStorage.removeItem("introFeedbackDialog");
    this.stopListeningForVisibilityChange();
  }

  showEarlyJoinError(): void {
    this.popupService.openInfoDialog({
      title: "texts.To_early_intro_appointment_title",
      description: "texts.To_early_intro_appointment_description",
    });
  }

  setUpToOpenFeedbackDialog(appointment: Appointment): void {
    this.startListeningForVisibilityChange();
    this.timerForIntro = new Date().getTime();
    this.appointment = appointment;
    localStorage.setItem(
      "introFeedbackDialog",
      JSON.stringify({
        forename: appointment.tutor.forename,
        surname: appointment.tutor.surname,
        image: appointment.tutor.profileImage,
        id: appointment.tutor.id,
      })
    );
  }

  startListeningForVisibilityChange(): void {
    document.addEventListener("visibilitychange", this.boundOnVisibilityChange);
  }

  stopListeningForVisibilityChange(): void {
    document.removeEventListener(
      "visibilitychange",
      this.boundOnVisibilityChange
    );
  }

  onVisibilityChange(event: Event): void {
    if (
      document.visibilityState === "visible" &&
      this.hasFiveMinutesPassed(this.timerForIntro)
    ) {
      this.openFeedbackDialog({
        forename: this.appointment.tutor.forename,
        surname: this.appointment.tutor.surname,
        image: this.appointment.tutor.profileImage,
        id: this.appointment.tutor.id,
      });
    }
  }

  private hasFiveMinutesPassed(startTime: number): boolean {
    const now = new Date();
    const startTimeAsDate = new Date(startTime);
    const differenceInMs = now.getTime() - startTimeAsDate.getTime();
    return differenceInMs >= 1000 * 60 * 5;
  }
}
