import { UtilityService } from 'app/services';
import { Directive, ElementRef, Input } from "@angular/core";

@Directive({
  selector: "[bindHTML]",
  exportAs: "bindHTML",
})
export class BindHTMLDirective {

  constructor(
    private element: ElementRef,
    private utilityService: UtilityService
  ) { }

  private _html;

  @Input("bindHTML") set bindHTML(value) {
    if (value) {
      this._html = value;
      this.element.nativeElement.insertAdjacentHTML('afterbegin', this.decodeHTMLEntities(value));
    }
  }

  get bindHTML() { return this._html; }

  private decodeHTMLEntities(str) {
    if (this.utilityService.isBrowser) {
      // decoding PHP encoding
      let txt = document.createElement("textarea");
      txt.innerHTML = str;
      txt.value = txt.value.replace(/\134n/g, "<br>");

      return txt.value;
    } else {
      return "";
    }
  }

}