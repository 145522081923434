import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidatorFn } from '@angular/forms';
import { UtilityService } from 'app/services';

@Directive({
  selector: '[phoneValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: PhoneValidatorDirective, multi: true }],
  exportAs: "phoneValidator"
})
export class PhoneValidatorDirective {

  constructor(
    private utilityService: UtilityService
  ) { }

  phoneNumberUtil: any;

  async ngOnInit() {
    this.phoneNumberUtil = await this.utilityService.addGooglePhoneNumberLib();
  }
  // TODO delete this when the new tutorReg is finished
  phonePatternValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }

      let valid = false;

      try {
        const phoneNumber = this.phoneNumberUtil.parseAndKeepRawInput(
          control.value, this.utilityService.country.toUpperCase()
        );
        valid = this.phoneNumberUtil.isValidNumber(phoneNumber);
      } catch (e) { }

      return valid ? null : { invalidPhone: true };
    };
  }

  validate(control: AbstractControl): { [key: string]: any } | null {
    return this.phonePatternValidator()(control);
  }

}
