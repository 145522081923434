<section [attr.id]="anchorLink ? anchorLink : null" class="tutoring-info__section"
	[attr.style]="sectionBackgroundColor ? 'background: ' + sectionBackgroundColor + ';' : null">
	<div class="tutoring-info__container {{mediaRightPositionClass}}">

		<div *ngIf="showVideo || showImage || showTutors" class="video-component {{mobileVideoPopupMedia}}">
			<div *ngIf="showImage || showTutors" class="video-component__media">
				<app-info-image *ngIf="showImage" [img]="imageSrc"></app-info-image>
				<app-tutor-cards *ngIf="showTutors"></app-tutor-cards>
			</div>
			<app-video *ngIf="showVideo" [video]="videoSrcSet" [overlay]="overlayImageSrc"
				[overlayTitle]="imageAltText"></app-video>
		</div>
		<div class="tutoring-info__text">
			<h1 *ngIf="title && useLargeHeading" class="tutoring-info__text__title" [bindHTML]="title"></h1>
			<h2 *ngIf="title && !useLargeHeading" class="tutoring-info__text__title" [bindHTML]="title"></h2>
			<h3 *ngIf="subtitle" class="tutoring-info__text__subtitle" [bindHTML]="subtitle"></h3>
			<div *ngIf="description" class="tutoring-info__text__description" [bindHTML]="description"></div>
			<div *ngIf="mobileVideoPopup && !hideButton" class="tutoring-info__text__button {{mobileVideoPopupMedia}}">
				<button (click)="showPopup()">
					<span *ngIf="buttonText">
						{{buttonText | translate}}
					</span>
					<span *ngIf="!buttonText">
						{{"TutoringInfoSection_button" | translate}}
					</span>
				</button>
			</div>
			<div *ngIf="!mobileVideoPopup && !hideButton" class="tutoring-info__text__button">
				<a *ngIf="!preparedLink.urlObject" [routerLink]="[preparedLink.url] | localize">{{buttonText |
					translate}}</a>
				<a *ngIf="preparedLink.urlObject" [href]="preparedLink.url">{{buttonText | translate}}</a>
			</div>
		</div>
	</div>
	<div *ngIf="contentsText" class="tutoring-info__container tutoring-info__container__text">
		<app-info-text *ngFor="let content of contentsText" [title]="content.title" [subtitle]="content.subtitle"
			[description]="content.description"></app-info-text>
	</div>
	<app-info-popup *ngIf="mobileVideoPopup && isVideoPopupVisible" [isVideoPopupVisible]="isVideoPopupVisible"
		(hidePopup)="hideVideoPopup()" [videoSrcSet]="videoSrcSet" [overlayImageSrc]="overlayImageSrc"
		[overlayTitle]="imageAltText"></app-info-popup>
</section>