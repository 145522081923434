<section class="followup-button__section">
	<div class="followup-button__container">
		<div class="followup-button__text">
			<h1 *ngIf="title && isFirstSection" class="followup-button__text__title" [bindHTML]="title"></h1>
			<h2 *ngIf="title && !isFirstSection" class="followup-button__text__title" [bindHTML]="title"></h2>
			<div *ngIf="description" class="followup-button__text__description" [bindHTML]="description"></div>
		</div>
		<div class="followup-button__button-wrapper">
			<a *ngIf="!preparedLink.urlObject; else usehref" class="followup-button__text__button"
				[routerLink]="[preparedLink.url]">{{buttonText}}</a>
			<ng-template #usehref>
				<a class="followup-button__text__button" [href]="preparedLink.url">{{buttonText}}</a>
			</ng-template>
		</div>
	</div>
</section>
