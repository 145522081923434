import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TranslationHelperService } from 'app/services';

@Injectable({
  providedIn: 'root'
})
export class CustomTranslateLoader implements TranslateLoader {
  constructor(
    private httpClient: HttpClient,
    private translationHelperService: TranslationHelperService) {
  }


  getTranslation(lang: string): Observable<any> {

    const apiAddress = `${this.translationHelperService.apiBase}/api/country/translation/${lang}`;

    return this.httpClient.get(apiAddress)
      .pipe(
        map(response => {
          for (const key in response['links']) {
            let value = response['links'][key];
            if (value && value.substring(0, 1) == '/') {
              value = value.substring(1);
            }
            response['links'][key] = value;
          }
          response['ROUTES'] = response['links'];

          for (const key in response['images']) {
            let value = response['images'][key];
            if (value?.title) {
              value = value.title;
            }
            let replaceOccurrences = this.getIndicesOf('%s', value);
            for (const index in replaceOccurrences) {
              value = value.replace('%s', '{{value' + (Number(index) + 1) + '}}');
            }
            response['images'][key].title = value;

            value = response['images'][key];
            if (value?.source) {
              value = value.source;
            }
            replaceOccurrences = this.getIndicesOf('%s', value);
            for (const index in replaceOccurrences) {
              value = value.replace('%s', '{{value' + (Number(index) + 1) + '}}');
            }
            response['images'][key].source = value;

            // TODO: deprecated, use the new lazy-load-image component
            response['images'][key].sources = {
              large: value += "?class=large",
              tablet: value += "?class=tablet",
              mobile: value += "?class=mobile",
              small: value += "?class=mobile",
            };
            response['images'][key].originalSource = value;
          }
          
          return response;
        }),
        catchError(e => {
          console.log(e);
          return e;
        }),
      );
  }

  getIndicesOf(searchStr, str, caseSensitive = true): number[] {
    const searchStrLen = searchStr.length;
    if (searchStrLen == 0) {
      return [];
    }
    let startIndex = 0, index, indices = [];
    if (!caseSensitive) {
      str = str.toLowerCase();
      searchStr = searchStr.toLowerCase();
    }
    while ((index = str.indexOf(searchStr, startIndex)) > -1) {
      indices.push(index);
      startIndex = index + searchStrLen;
    }
    return indices;
  }
}
