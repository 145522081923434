<section [attr.id]="anchorLink ? anchorLink : null" class="text-info__section" [style.background]="sectionBackground">
	<div class="text-info__container">
		<app-info-text 
			*ngFor="let content of contents; let i=index" 
			[title]="content.title" 
			[subtitle]="content.subtitle" 
			[description]="content.description"
			[useLargeHeading]="useLargeHeading && i === 0">
		</app-info-text>
		<app-info-text 
			*ngIf="!contents || !contents.length" 
			[title]="title" 
			[subtitle]="subtitle" 
			[description]="description"
			[useLargeHeading]="useLargeHeading">
		</app-info-text>
	</div>
</section>