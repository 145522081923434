import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PreparedLink } from 'app/models';
import { UtilityService } from '../../../services';
import { CmsBaseComponent } from '../cms-base/cms-base.component';

@Component({
	selector: 'app-follow-up-button-section',
	templateUrl: './follow-up-button-section.component.html',
	styleUrls: ['./follow-up-button-section.component.scss']
})
export class FollowUpButtonSectionComponent extends CmsBaseComponent implements OnInit {
	@Input()
	buttonLink: string = '';
	preparedLink!: PreparedLink;
	@Input()
	buttonText: string = ''
	@Input()
	description?: string
	@Input()
	title?: string

	constructor(
    private translationService: TranslateService,
		public utilityService: UtilityService
	) {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();

    this.translationService.get([
      'ROUTES.Misc_contact',
      'texts.FollowupButtonSection_contact',
      'texts.FollowupButtonSection_description',
      'texts.FollowupButtonSection_title'
    ]).subscribe(translations => {
      this.preparedLink = this.utilityService.prepareLink(this.buttonLink, translations['ROUTES.Misc_contact']);
      if (!this.buttonText) {
        this.buttonText = translations['texts.FollowupButtonSection_contact'];
      }
      if (!this.description) {
        this.description = translations['texts.FollowupButtonSection_description'];
      }
      if (!this.title) {
        this.title = translations['texts.FollowupButtonSection_title'];
      }
    });
	}
}
