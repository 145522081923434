export interface MessageToSocket {
	action: string;
	room?: string;
	before_message?: string;
	message: string;
}

export class MessageToUser {
	type!: string;
	data?: any;
	reference!: string;

	constructor(user: Partial<MessageToUser>) {
		Object.assign(this, user);
	}
}

export class DirectMessage {
	id!: string;
	room!: string;
	user_id!: number;
	user!: MessageUser;
	message!: string;
	time!: string;
	system!: boolean;

	constructor(user: Partial<DirectMessage>) {
		Object.assign(this, user);
	}
}

export class MessageUser {
	Forename: string;
}

export class UserMessage {
	id!: number;
	forename!: string;
	surname!: string;
	phone!: string;
	email!: string;
	initials!: string;
	image!: string;
	owner!: boolean;
	joined_at: any;

	constructor(user: Partial<UserMessage>) {
		Object.assign(this, user);
	}
}
export class RoomMessage {
	id!: string;
	name!: string;
	direct!: boolean;
	private!: boolean;
	owner!: boolean;
	activity_at: any;
	latest_message: any;
	unread_messages!: number;
	users!: UserMessage[];
  	participant_state: string;
	_userImage: string;
	_date: string;
}

export class NotificationUserMessage {
	appointments_pending?: number;
	appointments_accepted?: number;
	messages_unseen?: number;

	constructor(user: Partial<NotificationUserMessage>) {
		Object.assign(this, user);
	}
}