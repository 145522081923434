<div *ngIf="pageConfigService.getShowRegisterActionButton() && pageConfigService.getIsRegisterAsTutor()"
	[ngClass]="{'show': show && scrollShow}" class="register-action-button">
	<button class="register-action-button__close" type="button" role="button" id="registerActionCloseBtn"
		(click)="hideActionButton()">
		<i class="fas fa-times"></i>
	</button>
	<a [routerLink]="['/TutorRegistration_TeacherInfo'] | localize" role="link">

		<div class="register-action-button__text" (click)="clickAction()">
			{{'texts.RegisterActionButtonComponent_tutor' | translate}}
		</div>
	</a>
</div>
<div *ngIf="pageConfigService.getShowRegisterActionButton() && !pageConfigService.getIsRegisterAsTutor()"
	class="register-action-button register-action-button--student"
	[ngClass]="{'register-action-button__special' : isFindTutorPage(), 'show': show && scrollShow}">
	<button class="register-action-button__close" type="button" role="button" id="registerActionCloseBtn"
		(click)="hideActionButton()">
		<i class="fas fa-times"></i>
	</button>

	<div class="register-action-button__text" [ngClass]="{'register-action-button__text__special' : isFindTutorPage()}"
		(click)="clickAction()">
		<span *ngIf="!isFindTutorPage()">
			{{'texts.RegisterActionButtonComponent_student' | translate}}
		</span>
		<div *ngIf="isFindTutorPage()" class="register-action-button__text__title">
			{{'texts.RegisterActionButtonComponent_tutorsearch_title' | translate}}
		</div>
		<div *ngIf="isFindTutorPage()" class="register-action-button__text__body">
			{{'texts.RegisterActionButtonComponent_tutorsearch_body_new' | translate:{newTutorNumber: newTutorNumber} }}
		</div>
		<div *ngIf="isFindTutorPage()" class="register-action-button__text__button">
			{{'texts.RegisterActionButtonComponent_tutorsearch_button' | translate}}
		</div>
	</div>
</div>