<div class="modal-overlay">
	<div class="modal">
		<div class="modal-content modal-card">
			<div class="modal-header modal-card-header-success modal-card-header">
				<ng-content select="[modal-close]"></ng-content>
				<h4 class="modal-card-title">
					<ng-content select="[modal-title]"></ng-content>
				</h4>
				<p class="modal-card-category">
					<ng-content select="[modal-category]"></ng-content>
				</p>
			</div>
			<div class="modal-card-body">
				<ng-content select="[modal-body]"></ng-content>
			</div>
			<div class="modal-footer modal-button-wrapper">
				<ng-content class="modal-footer" select="[modal-footer]"></ng-content>
			</div>
			<div class="modal-after">
				<div class="modal-after-text">
					<ng-content select="[modal-after]"></ng-content>
				</div>
			</div>
		</div>
	</div>
</div>