import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-empathy-statement',
	templateUrl: './empathy-statement.component.html',
	styleUrls: ['./empathy-statement.component.scss']
})
export class EmpathyStatementComponent {

	@Input()
	showLogoGroup: boolean = false;
}
