<section class="storybrand-step-section">
	<ul class="storybrand-step-section__list">
		<li class="storybrand-step-section__list__item">
			<div class="storybrand-step-section__list__item__img-wrapper">
				<img [src]="'images.CardNumberSection_img_desktop.originalSource' | translate"
					[alt]="'images.CardNumberSection_img_desktop.title' | translate" />
			</div>
			<h6 class="storybrand-step-section__list__item__text">
				{{'texts.CardNumberSection_img_desktop_title' | translate}}
			</h6>
		</li>
		<li class="storybrand-step-section__list__item">
			<div class="storybrand-step-section__list__item__img-wrapper">
				<img [src]="'images.CardNumberSection_img_calendar.originalSource' | translate"
					[alt]="'images.CardNumberSection_img_calendar.title' | translate" />
			</div>
			<h6 class="storybrand-step-section__list__item__text">
				{{'texts.CardNumberSection_img_calendar_title' | translate}}
			</h6>
		</li>
		<li class="storybrand-step-section__list__item">
			<div class="storybrand-step-section__list__item__img-wrapper">
				<img [src]="'images.CardNumberSection_img_graph.originalSource' | translate"
					[alt]="'images.CardNumberSection_img_graph.title' | translate" />
			</div>
			<h6 class="storybrand-step-section__list__item__text">
				{{'texts.CardNumberSection_img_graph_title' | translate}}
			</h6>
		</li>
	</ul>
</section>