import { TranslateService } from '@ngx-translate/core';
import { Component, Input, QueryList, ViewChildren } from '@angular/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { UtilityService, VariableService } from 'app/services';
import { ImageDataModel } from 'app/models/image-translation.model';

export interface Advice {
  name: string,
  picture: string,
  speciality: string,

  _imageObj: ImageDataModel
}

@Component({
  selector: 'app-advice-section',
  templateUrl: './advice-section.component.html',
  styleUrls: ['./advice-section.component.scss']
})

export class AdviceSectionComponent {
  @Input() applyCustomStyling: boolean = false;

  @ViewChildren('carouselItems') carouselItems: QueryList<any>;
  advices: Array<Advice>;

  constructor(public translate: TranslateService,
    public lazyLoad: LazyLoadImageModule,
    public utilityService: UtilityService,
    public variableService: VariableService,
  ) { }

  ngOnInit() {
    this.getData()
  }

  getData() {
    this.translate.get([
      'texts.Price_Page_Advice_Section_Advice_1',
      'texts.Price_Page_Advice_Section_Advice_2',
      'texts.Price_Page_Advice_Section_Advice_3'
    ]).subscribe(translation => {
      let advices: Array<Advice> = [
        JSON.parse(translation['texts.Price_Page_Advice_Section_Advice_1']),
        JSON.parse(translation['texts.Price_Page_Advice_Section_Advice_2']),
        JSON.parse(translation['texts.Price_Page_Advice_Section_Advice_3'])
      ]
      this.advices = advices.map(x => x = { ...x, _imageObj: { title: x.name + 'picture', originalSource: x.picture } })
    })
  }
}
