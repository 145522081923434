import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Display } from 'app/constants';
import { SharedModule } from 'app/modules/shared/shared.module';
import { UtilityService } from 'app/services';

@Component({
    selector: 'app-stepper',
    standalone: true,
    imports: [CommonModule, SharedModule],
    templateUrl: './stepper.component.html',
    styleUrls: ['./stepper.component.scss'],
})

export class StepperComponent implements OnInit {
    constructor(
        private cdr: ChangeDetectorRef,
        private utilityService: UtilityService
    ) { }

    @ViewChild('stepBar') stepBar: ElementRef
    @ViewChild('content') content: ElementRef
    @ViewChild('stepperContainer') stepperContainer: ElementRef
    @ViewChild('stepperAndCarouselContainer') stepperAndCarouselContainer: ElementRef

    @Input('currentStep') currentStep: number = 1;
    @Input('activeTabIndex') activeTabIndex: number = 0;
    @Output('activeTabIndexChange') activeTabIndexChange = new EventEmitter<number>()

    tabs: any[] = []
    activeTab;
    tabAnimationFinsihed = true
    steps: number;
    widthBasedOnSteps = '100%'
    tabChangeDuration = 300
    containerHeight: number;

    private resizeObserver: ResizeObserver
    @ViewChild('carouselContainer') carouselContainer: ElementRef

    ngOnInit(): void {
        this.resizeObserver = this.utilityService.isBrowser && new ResizeObserver(this.onResize.bind(this));
    }

    private onResize(entries: ResizeObserverEntry[]) {
        const element = entries[0].target;
        // @ts-ignore
        const newHeight = element.offsetHeight;
        this.content.nativeElement.style.height = newHeight + this.stepperContainer.nativeElement.offsetHeight + 'px'
    }

    ngAfterViewInit() {
        if (this.utilityService.isBrowser) {

            this.content.nativeElement.childNodes.forEach((tab) => {
                if (tab.nodeName !== '#comment') {
                    this.tabs.push(tab)
                }
            })

            this.steps = this.tabs.length
            this.currentStep = this.activeTabIndex + 1
            this.adjustBar();
            this.cdr.detectChanges()

            this.tabs.forEach((tab) => {
                tab.style.display = Display.none;
            })
            this.activeTab = this.tabs[this.activeTabIndex]
            this.activeTab.style.display = Display.block;
            this.resizeObserver.observe(this.activeTab);
        };
    }

    nextStep() {
        if (this.currentStep < this.steps && this.tabAnimationFinsihed) {
            this.currentStep = this.currentStep + 1;
            this.adjustBar()
            this.pulse();
            this.nextTab()
        }
    }

    delayedNextStep(miliSeconds: number) {
        setTimeout(() => {
            this.nextStep()
        }, miliSeconds);
    }

    prevStep() {
        if (this.currentStep > 1 && this.tabAnimationFinsihed) {
            this.currentStep = this.currentStep - 1;
            this.adjustBar();
            this.prevTab()
        }
    }

    nextTab() {
        this.activeTabIndex = this.activeTabIndex + 1;
        this.activeTabIndexChange.emit(this.activeTabIndex)
        this.animateTabChange(true)
        this.cdr.detectChanges()
    }

    prevTab() {
        this.activeTabIndex = this.activeTabIndex - 1
        this.activeTabIndexChange.emit(this.activeTabIndex)
        this.animateTabChange(false)
        this.cdr.detectChanges()
    }

    animateTabChange(isNext: boolean) {
        this.activeTab.style.display = Display.none;
        this.resizeObserver.unobserve(this.activeTab);
        this.activeTab = this.tabs[this.activeTabIndex]

        this.activeTab.style.display = Display.block;
        this.resizeObserver.observe(this.activeTab);

        this.tabAnimationFinsihed = false
        this.activeTab.animate([
            { transform: `translate3d(${isNext ? '100%' : '-100%'}, 0, 0)`, offset: 0 },
        ], {
            duration: this.tabChangeDuration,
            iterations: 1
        }).onfinish = () => {
            this.tabAnimationFinsihed = true;
        };
    }

    adjustBar() {
        const progress = (this.currentStep / this.steps) * 100;
        this.widthBasedOnSteps = `${progress}%`;
    }

    pulse() {
        this.stepBar.nativeElement.animate([
            {
                boxShadow: '0px 0px 0px 0px rgb(92, 199, 95,.7) , 0px 0px 0px 0px rgb(92, 199, 95,.7)', offset: 0
            },
            {
                boxShadow: '0px 0px 4px 10px rgb(92, 199, 95,.0) , 0px 0px 0px 0px rgb(92, 199, 95,.7)', offset: 0.4
            },
            {
                boxShadow: '0px 0px 4px 10px rgb(92, 199, 95,.0) , 0px 0px 1px 2px rgb(92, 199, 95,.0)', offset: 0.8
            },
            {
                boxShadow: '0px 0px 0px 0px rgb(92, 199, 95,.0) , 0px 0px 1px 2px rgb(92, 199, 95,.0)', offset: 1
            },
        ], {
            duration: 1000,
            iterations: 1
        });
    }
}

// Example how to use it in parent component

// @ViewChild('stepper') stepper: StepperComponent

// prevStep() {
//   this.stepper.prevStep()
// }

// nextStep() {
//   this.stepper.nextStep()
// }
