<div *ngIf="detailsForCompleteProfile.completeProfileFor === CompleteProfileFor.message"
    class="additional-information-title" [translateParams]="{ name: detailsForCompleteProfile.tutorName}" translate>
    texts.Additional_Information_Title</div>
<div *ngIf="detailsForCompleteProfile.completeProfileFor === CompleteProfileFor.intro"
    class="additional-information-title" translate>
    texts.Additional_Information_Title_Intro
</div>
<div *ngIf="detailsForCompleteProfile.completeProfileFor === CompleteProfileFor.lesson"
    class="additional-information-title" translate>
    texts.Additional_Information_Title_Lesson
</div>
<div *ngIf="detailsForCompleteProfile.completeProfileFor === CompleteProfileFor.package"
    class="additional-information-title" translate>
    texts.Additional_Information_Title_Package
</div>
<div *ngIf="detailsForCompleteProfile.completeProfileFor === CompleteProfileFor.edit"
    class="additional-information-title" translate>
    texts.Additional_Information_Title_Edit
</div>
<app-stepper #stepper [(activeTabIndex)]="activeTabIndex">
    <div class="user-type-container tab-container">
        <div class="tab-title" translate>texts.Additional_Information_User_Type_Title
        </div>
        <div class="radio-buttons-container">
            <div class="radio-button-container" (click)="toggleStudentOrParent(UserType.parent)"
                [ngClass]="{'panel-active': userType === UserType.parent}">
                <div class="radio-button" [ngClass]="{'radio-active': userType === UserType.parent}">
                </div>
                <div class="radio-text" translate>texts.Additional_Information_User_Type_Parent
                </div>
            </div>
            <div class="radio-button-container" (click)="toggleStudentOrParent(UserType.student)"
                [ngClass]="{'panel-active': userType === UserType.student}">
                <div class="radio-button" [ngClass]="{'radio-active': userType === UserType.student}">
                </div>
                <div class="radio-text" translate>texts.Additional_Information_User_Type_Student
                </div>
            </div>
        </div>
    </div>
    <div class="name-container tab-container">
        <form [formGroup]="personalDataForm" #ngForm="ngForm" (submit)="validatePersonalData()">
            <div class="form-container">
                <div class="input-and-title">
                    <div *ngIf="userType === UserType.parent && !authService.userDetails.googleID" class="input-title"
                        translate>
                        texts.Additional_Information_Name_Input_Title_Name_Parent</div>
                    <div *ngIf="userType === UserType.parent  && authService.userDetails.googleID" class="input-title"
                        translate>
                        texts.Additional_Information_Name_Input_Title_Name_Student_If_User_Registered_Google</div>
                    <div *ngIf="userType === UserType.student" class="input-title" translate>
                        texts.Additional_Information_Name_Input_Title_Name_Student</div>
                    <input class="input-field" formControlName="name"
                        placeholder="{{'texts.Additional_Information_Name_Input_Placeholder_Name' | translate}}">
                    <div class="inline-error"
                        [ngClass]="{'show-error': showPersonalDataError && !personalDataForm.value.name}" translate>
                        texts.Misc_field_message_required</div>
                </div>
                <div class="input-and-title">
                    <div *ngIf="userType === UserType.parent && !authService.userDetails.googleID" class="input-title"
                        translate>
                        texts.Additional_Information_Name_Input_Title_Surname_Parent</div>
                    <div *ngIf="userType === UserType.parent && authService.userDetails.googleID" class="input-title"
                        translate>
                        texts.Additional_Information_Name_Input_Title_Surname_Student_If_User_Registered_Google</div>
                    <div *ngIf="userType === UserType.student" class="input-title" translate>
                        texts.Additional_Information_Name_Input_Title_Surname_Student</div>
                    <input class="input-field" formControlName="surname"
                        placeholder="{{'texts.Additional_Information_Name_Input_Placeholder_Surname' | translate}}">
                    <div class="inline-error"
                        [ngClass]="{'show-error': showPersonalDataError && personalDataForm.controls.surname.status === 'INVALID'}"
                        translate>texts.Misc_field_message_required</div>
                </div>
            </div>
            <div class="input-and-title" *ngIf="userType === UserType.student">
                <div class="input-title" translate>
                    texts.Additional_Information_Name_Input_Title_Mail</div>
                <input class="input-field" formControlName="eMail"
                    placeholder="{{'texts.Additional_Information_Name_Input_Placeholder_Mail' | translate}}"
                    type="email">
                <div class="inline-error"
                    [ngClass]="{'show-error': showPersonalDataError && personalDataForm.controls.eMail.status === 'INVALID'}"
                    translate>
                    texts.Misc_field_email_message_error</div>
            </div>
            <button class="button submit-button" type="submit" translate>
                texts.Additional_Information_Name_Next_Step</button>
        </form>
    </div>
    <div class="location-container tab-container">
        <form [formGroup]="locationDataForm" #ngForm="ngForm" (submit)="validateAndSubmitData()">
            <div class="form-container">
                <app-new-address-form #addressForm [parentForm]="locationDataForm"></app-new-address-form>
            </div>
            <button *ngIf="detailsForCompleteProfile.completeProfileFor === CompleteProfileFor.message"
                class="button submit-button" type="submit" translate>
                texts.Additional_Information_Name_Submit_Message</button>
            <button *ngIf="detailsForCompleteProfile.completeProfileFor === CompleteProfileFor.intro"
                class="button submit-button" type="submit" translate>
                texts.Additional_Information_Name_Submit_Intro</button>
            <button *ngIf="detailsForCompleteProfile.completeProfileFor === CompleteProfileFor.lesson"
                class="button submit-button" type="submit" translate>
                texts.Additional_Information_Name_Submit_Lesson</button>
            <button *ngIf="detailsForCompleteProfile.completeProfileFor === CompleteProfileFor.package"
                class="button submit-button" type="submit" translate>
                texts.Additional_Information_Name_Submit_Package</button>
            <button *ngIf="detailsForCompleteProfile.completeProfileFor === CompleteProfileFor.edit"
                class="button submit-button" type="submit" translate>
                texts.Additional_Information_Name_Submit_Edit</button>
        </form>
    </div>
</app-stepper>