<section class="link-tab-section">
	<div class="link-tab-section__container">
		<h4 class="link-tab-section__title">
			{{title}}
		</h4>

		<ul class="link-tab-section__link-tabs">
			<li class="link-tab-section__link-tabs__item" (click)="setSelectedLinkTabIndex(0)" [ngClass]="{'visible': selectedLinkTabIndex == 0}">
				{{'texts.NavigationSection_subject' | translate }}
			</li>
			<li class="link-tab-section__link-tabs__item" (click)="setSelectedLinkTabIndex(1)" [ngClass]="{'visible': selectedLinkTabIndex == 1}">
				{{'texts.NavigationSection_cities' | translate }}
			</li>
		</ul>

		<ul class="link-tab-section__link-group" [ngClass]="{'visible': selectedLinkTabIndex == 0}">
			<li class="link-tab-section__link-group__item">
				<a class="link-tab-section__link-group__item__link" [routerLink]="['/Navigation_subject_maths'] | localize">
					{{'texts.NavigationSection_subject_maths' | translate }}
					<span class="link-tab-section__link-group__item__description">{{'texts.LinkTabSection_item_description' | translate }}</span>
				</a>
			</li>
			<li class="link-tab-section__link-group__item">
				<a class="link-tab-section__link-group__item__link" [routerLink]="['/Navigation_subject_economics'] | localize">
					{{'texts.NavigationSection_subject_economics' | translate }}
					<span class="link-tab-section__link-group__item__description">{{'texts.LinkTabSection_item_description' | translate }}</span>
				</a>
			</li>
			<li class="link-tab-section__link-group__item">
				<a class="link-tab-section__link-group__item__link" [routerLink]="['/Navigation_subject_chemistry'] | localize">
					{{'texts.NavigationSection_subject_chemistry' | translate }}
					<span class="link-tab-section__link-group__item__description">{{'texts.LinkTabSection_item_description' | translate }}</span>
				</a>
			</li>
			<li class="link-tab-section__link-group__item">
				<a class="link-tab-section__link-group__item__link" [routerLink]="['/Navigation_subject_physics'] | localize">
					{{'texts.NavigationSection_subject_physics' | translate }}
					<span class="link-tab-section__link-group__item__description">{{'texts.LinkTabSection_item_description' | translate }}</span>
				</a>
			</li>
			<li class="link-tab-section__link-group__item">
				<a class="link-tab-section__link-group__item__link" [routerLink]="['/Navigation_subject_biology'] | localize">
					{{'texts.NavigationSection_subject_biology' | translate }}
					<span class="link-tab-section__link-group__item__description">{{'texts.LinkTabSection_item_description' | translate }}</span>
				</a>
			</li>
			<li class="link-tab-section__link-group__item">
				<a class="link-tab-section__link-group__item__link" [routerLink]="['/Navigation_subject_all'] | localize">
					{{'texts.NavigationSection_subject_all' | translate }}
					<span class="link-tab-section__link-group__item__description">{{'texts.LinkTabSection_item_description' | translate }}</span>
				</a>
			</li>
		</ul>

		<ul class="link-tab-section__link-group" [ngClass]="{'visible': selectedLinkTabIndex == 1}">
			<li class="link-tab-section__link-group__item">
				<a class="link-tab-section__link-group__item__link" [routerLink]="['/Navigation_city_1'] | localize">
					{{'texts.Navigation_city_name_1' | translate }}
					<span class="link-tab-section__link-group__item__description">{{'texts.LinkTabSection_item_description' | translate }}</span>
				</a>
			</li>
			<li class="link-tab-section__link-group__item">
				<a class="link-tab-section__link-group__item__link" [routerLink]="['/Navigation_city_2'] | localize">
					{{'texts.Navigation_city_name_2' | translate }}
					<span class="link-tab-section__link-group__item__description">{{'texts.LinkTabSection_item_description' | translate }}</span>
				</a>
			</li>
			<li class="link-tab-section__link-group__item">
				<a class="link-tab-section__link-group__item__link" [routerLink]="['/Navigation_city_3'] | localize">
					{{'texts.Navigation_city_name_3' | translate }}
					<span class="link-tab-section__link-group__item__description">{{'texts.LinkTabSection_item_description' | translate }}</span>
				</a>
			</li>
			<li class="link-tab-section__link-group__item">
				<a class="link-tab-section__link-group__item__link" [routerLink]="['/Navigation_city_4'] | localize">
					{{'texts.Navigation_city_name_4' | translate }}
					<span class="link-tab-section__link-group__item__description">{{'texts.LinkTabSection_item_description' | translate }}</span>
				</a>
			</li>
			<li class="link-tab-section__link-group__item">
				<a class="link-tab-section__link-group__item__link" [routerLink]="['/Navigation_city_5'] | localize">
					{{'texts.Navigation_city_name_5' | translate }}
					<span class="link-tab-section__link-group__item__description">{{'texts.LinkTabSection_item_description' | translate }}</span>
				</a>
			</li>
			<li class="link-tab-section__link-group__item">
				<a class="link-tab-section__link-group__item__link" [routerLink]="['/Navigation_cities_all'] | localize">
					{{'texts.NavigationSection_cities_all' | translate }}
					<span class="link-tab-section__link-group__item__description">{{'texts.LinkTabSection_item_description' | translate }}</span>
				</a>
			</li>
			<li class="link-tab-section__link-group__item">
				<a class="link-tab-section__link-group__item__link" [routerLink]="['/Misc_tutoring_online'] | localize">
					{{'texts.NavigationSection_tutoring_online' | translate }}
					<span class="link-tab-section__link-group__item__description">{{'texts.LinkTabSection_item_description' | translate }}</span>
				</a>
			</li>
		</ul>
	</div>
</section>
