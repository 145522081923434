<form class="tutor-registration-dropdown-courses" [formGroup]="courseForm" #formDirective="ngForm"
	(ngSubmit)="checkIfFilled() && !courseForm.invalid && saveCourse(formDirective)">
	<label class="input-label courses-label">
		{{'texts.TutorRegistrationSection_courses_to_tutor' | translate}} *
	</label>
	<div class="tutor-registration-dropdown-courses__wrapper">
		<div class="input-field ">
			<label class="input-label ">
				{{'texts.TutorSubjectTableFormSection_dropdown_schooltype' | translate}}
			</label>
			<div id="" class="input__icon-wrapper input__select-wrapper school-type">
				<ng-select class="selectus show-tick school-type searchbar-header__searchbar__input placeholder"
					data-size="2" id="school-type" name="schoolType_h" required
					[placeholder]="'texts.DropdownSelect_schooltype' | translate" [items]="schoolTypes"
					[(ngModel)]="selectedSchoolTypeId" bindValue="id" bindLabel="name" [searchable]="false"
					formControlName="type" (change)="selectSchoolType($event)">
				</ng-select>
				<app-input-error [element]="courseForm.get('type')" type="text"></app-input-error>
			</div>
		</div>
		<div class="input-field">
			<label class="input-label ">
				{{'texts.TutorSubjectTableFormSection_dropdown_course' | translate}}
			</label>
			<div id="" class="input__icon-wrapper input__select-wrapper school-course">
				<ng-select
					class="selectus show-tick school-course update-onchange update-onchange-subject searchbar-header__searchbar__input"
					required name="schoolSubject_h" id="school-course"
					[placeholder]="'texts.DropdownSelect_subject' | translate" [items]="availableCourses"
					[(ngModel)]="selectedCourseId" bindValue="id" bindLabel="name" [searchable]="false"
					formControlName="course" (change)="selectCourse($event)">
				</ng-select>
				<app-input-error [element]="courseForm.get('course')" type="text"></app-input-error>
			</div>
		</div>
		<div class="input-field" *ngIf="levels?.length" tabindex="0">
			<label class="input-label " for="school-level">
				{{'texts.TutorSubjectTableFormSection_dropdown_level' | translate}}
			</label>
			<div id="" class="input__icon-wrapper input__select-wrapper">
				<ng-select class="selectus show-tick school-level update-onchange searchbar-header__searchbar__input"
					groupBy="years" [placeholder]="'texts.DropdownSelect_schooltype' | translate"
					[required]="levels?.length" [items]="levels" [(ngModel)]="selectedLevels" bindLabel="name"
					bindValue="_customLevelYearId" [multiple]="true" [closeOnSelect]="false" [searchable]="false"
					formControlName="level">
					<ng-template ng-header-tmp>
						<div class="selectus__multiple">
							<button type="button" class="selectus__multiple__select selectus__multiple__select--all"
								(click)="onSelectAll()">
								{{'texts.Misc_select_all' | translate}}
							</button>
							<button type="button" class="selectus__multiple__select selectus__multiple__select--none"
								(click)="onClearAll()">
								{{'texts.Misc_select_none' | translate}}
							</button>
						</div>
					</ng-template>
					<ng-template ng-label-tmp let-item="item">
						<b>{{item.name}}</b>
					</ng-template>
					<ng-template ng-optgroup-tmp let-item="item" let-index="index">
						{{item.name}}
					</ng-template>
					<ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
						{{item.name}}
					</ng-template>
				</ng-select>
				<app-input-error [element]="courseForm.get('level')" type="text"></app-input-error>
			</div>
		</div>
		<div class="tutor-registration-dropdown-courses__loading">
			<span>
				<i class="fa-spin" aria-hidden="true"></i>
				&nbsp;{{'texts.Misc_loading' | translate}}
			</span>
		</div>
	</div>
	<button *ngIf="isSubmitAvailable" class="submit-button" data-cy="InputSubmit"> {{
		'texts.Misc_save' |
		translate }}
	</button>
</form>