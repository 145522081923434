import { Component, Input, OnInit } from '@angular/core';
import { UtilityService } from 'app/services';
import { CmsBaseComponent } from '../cms-base/cms-base.component';

@Component({
	selector: 'app-searchbar-section',
	templateUrl: './searchbar-section.component.html',
	styleUrls: ['../searchbar-header-section/searchbar-header-section.component.scss', './searchbar-section.component.scss']
})
export class SearchbarSectionComponent extends CmsBaseComponent implements OnInit {
	@Input()
	description?: string
	@Input()
	title?: string

	constructor(
		public utilityService: UtilityService
	) {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();

		if (this.description) {
			this.description = this.utilityService.unescapeHTML(this.description);
		}
		if (this.title) {
			this.title = this.utilityService.unescapeHTML(this.title);
		}

	}

}