<section class="contact-header__section">
	<div class="contact-header__container">
		<div class="contact-header__text">
			<h1 *ngIf="title && isFirstSection" class="contact-header__text__title" [innerHTML]="title | translate"></h1>
			<h2 *ngIf="title && !isFirstSection" class="contact-header__text__title" [innerHTML]="title | translate"></h2>
			<div class="contact-header__details">
				<div class="contact-header__detail">
					<i class="fas fa-envelope contact-header__detail--icon"></i>
					<label class="contact-header__detail--label">{{'texts.ContactHeaderSection_email' | translate }}</label>
					<div class="contact-header__detail--text"><a [href]="'mailto:' + (variableService.contactVariable$ | async)?.contactEmail">{{(variableService.contactVariable$ | async)?.contactEmail}}</a></div>
				</div>
				<div class="contact-header__detail">
					<i class="fas fa-phone-alt contact-header__detail--icon"></i>
					<label class="contact-header__detail--label">{{'texts.ContactHeaderSection_telephone' | translate }}</label>
					<div class="contact-header__detail--text"><a [href]="'tel:' + (variableService.contactVariable$ | async)?.contactPhoneLinked">{{(variableService.contactVariable$ | async)?.contactPhoneDisplayed}}</a>
					</div>
				</div>
				<div class="contact-header__detail">
					<i class="fab fa-whatsapp contact-header__detail--icon"></i>
					<label class="contact-header__detail--label">{{'texts.ContactHeaderSection_whatsapp' | translate }}</label>
					<div class="contact-header__detail--text">{{(variableService.contactVariable$ | async)?.contactWhatsapp}}
						<a [href]="(variableService.contactVariable$ | async)?.contactWhatsappLink" target="_blank" class="contact-header__detail--light-weight">
							{{'texts.ContactHeaderSection_or' | translate }}
							<span class="contact-header__detail--green">{{'texts.ContactHeaderSection_whatsapp_text' | translate }}</span>
						</a>
					</div>
				</div>
				<div class="contact-header__detail">
					<i class="fas fa-clock contact-header__detail--icon"></i>
					<label 
            class="contact-header__detail--label" 
            [innerHTML]="'texts.ContactHeaderSection_opening_time_new' | translate: { open: (variableService.contactVariable$ | async)?.contactTimeOpen, close: (variableService.contactVariable$ | async)?.contactTimeClose} | unescape">
          </label>
				</div>
			</div>
		</div>
	</div>
</section>