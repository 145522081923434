import { AuthService, UtilityService } from 'app/services';
import { CustomCookieServiceService } from './custom-cookie-service.service';
import { Injectable } from "@angular/core";

@Injectable()
export class TokenValidationService {

    constructor(
        private authService: AuthService,
        private cookieService: CustomCookieServiceService,
        private utilityService: UtilityService
    ) { }

    validate() {
        return new Promise(res => {
            if (!this.utilityService.isBrowser) {
                return res(true)
            }
            if (!this.cookieService.get('access')) {
                this.authService.refresh().subscribe(
                    () => res(true),
                    () => res(false))
            } else {
                res(true)
            }

        })
    }
}