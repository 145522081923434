import { Component, Input, OnInit } from '@angular/core';
import { PageConfigService } from '../../../services';
import { CmsBaseComponent } from '../../cms/cms-base/cms-base.component';
import { CardResponsiveSliderContent } from 'app/models';

@Component({
	selector: 'app-card-responsive-slider',
	templateUrl: './card-responsive-slider.component.html',
	styleUrls: ['./card-responsive-slider.component.scss']
})
export class CardResponsiveSliderComponent extends CmsBaseComponent implements OnInit {
	@Input()
	component?: string
	@Input()
	title?: string
	@Input()
	titleImgSrc?: string
	@Input()
	titleImgAltText?: string
	@Input()
	subtitle?: string
	@Input()
	contents!: CardResponsiveSliderContent[]
	@Input()
	sectionClass: string = "card-responsive-slider-section"

	@Input()
	numbers: boolean = true;

	constructor(
		public pageConfigService: PageConfigService,
	) {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();

		if (!this.title) {
			this.title = 'CardNumberSection_title';
		}
		if (!this.contents) {
			this.contents = [
				{
					"imgAltText": "Registreren",
					"imgSrc": "https://d3oweo1tlra0ga.cloudfront.net/notes.png",
					"title": "texts.Registreren",
					"description": null
				},
				{
					"imgAltText": "Profiel maken",
					"imgSrc": "https://d3oweo1tlra0ga.cloudfront.net/resume.png",
					"title": "texts.Profiel maken",
					"description": null
				},
				{
					"imgAltText": "Interview",
					"imgSrc": "https://d3oweo1tlra0ga.cloudfront.net/interview-2.png",
					"title": "texts.Interview",
					"description": null
				},
				{
					"imgAltText": "Contract",
					"imgSrc": "https://d3oweo1tlra0ga.cloudfront.net/diploma.png",
					"title": "texts.Contract",
					"description": null
				}
			];
		}
	}

}
// { "id": "330", "position": "4", "title": "Hoe het werkt", "contents": [{ "title": "Registreren", "imgSrc": "https://static.bijlesaanhuis.nl/icoontjes/sollicitaties-bijles-geven.png" }, { "title": "Profiel maken", "imgSrc": "https://static.bijlesaanhuis.nl/icoontjes/profiel-bijles-geven.png" }, { "title": "Interview", "imgSrc": "https://static.bijlesaanhuis.nl/icoontjes/interview-bijles-geven.png" }, { "title": "Contract", "imgSrc": "https://static.bijlesaanhuis.nl/icoontjes/contract-bijles-geven.png" }] }