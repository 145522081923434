import { PackagesSelectionOption } from '../models/packages-model';

export enum SeniorityLevel {
    junior = 1,
    senior = 2,
    supreme = 3
}

export const PeriodOptions: Array<PackagesSelectionOption> = [
    {
        text: 'texts.Pricing_Page_Packages_period_option_16',
        value: '16',
        isActive: false
    },
    {
        text: 'texts.Pricing_Page_Packages_period_option_12',
        value: '12',
        isActive: false
    },
    {
        text: 'texts.Pricing_Page_Packages_period_option_10',
        value: '10',
        isActive: false
    },
    {
        text: 'texts.Pricing_Page_Packages_period_option_8',
        value: '8',
        isActive: false
    },
    {
        text: 'texts.Pricing_Page_Packages_period_option_6',
        value: '6',
        isActive: false
    },
    {
        text: 'texts.Pricing_Page_Packages_period_option_4',
        value: '4',
        isActive: true
    }
];

export const SequenceOptions: Array<PackagesSelectionOption> = [
    {
        text: 'texts.Pricing_Page_Packages_lessons_per_week_3',
        textMonthly: 'texts.Pricing_Page_Packages_lessons_per_month_12',
        value: '3',
        isActive: false
    },
    {
        text: 'texts.Pricing_Page_Packages_lessons_per_week_2',
        textMonthly: 'texts.Pricing_Page_Packages_lessons_per_month_8',
        value: '2',
        isActive: true
    },
    {
        text: 'texts.Pricing_Page_Packages_lessons_per_week_1',
        textMonthly: 'texts.Pricing_Page_Packages_lessons_per_month_4',
        value: '1',
        isActive: false
    }
];

export const TutorSeniorityLevels: Array<PackagesSelectionOption> = [
    {
        seniority: 'texts.Packages_tutor_seniority_sp_level',
        level: SeniorityLevel.supreme,
        text: 'texts.Packages_tutor_seniority_sp_text',
        value: 'texts.Packages_tutor_seniority_sp_price',
        isActive: false
    },
    {
        seniority: 'texts.Packages_tutor_seniority_sr_level',
        level: SeniorityLevel.senior,
        text: 'texts.Packages_tutor_seniority_sr_text',
        value: 'texts.Packages_tutor_seniority_sr_price',
        isActive: true
    },
    {
        seniority: 'texts.Packages_tutor_seniority_jr_level',
        level: SeniorityLevel.junior,
        text: 'texts.Packages_tutor_seniority_jr_text',
        value: 'texts.Packages_tutor_seniority_jr_price',
        isActive: false
    }
];