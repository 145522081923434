import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { SharedModule } from 'app/modules/shared/shared.module';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-input-required-error',
  templateUrl: './input-required-error.component.html',
  styleUrls: ['./input-required-error.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedModule],
  animations: [
    trigger('errorMessage', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.4s ease-in-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('0.4s ease-in-out', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class InputRequiredErrorComponent {
  @Input() inputControl: AbstractControl
}
