import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SharedModule } from 'app/modules/shared/shared.module';

@Component({
  selector: 'app-slide-toggle',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss']
})
export class SlideToggleComponent {
  @Input() model: boolean = false;
  @Output() modelChange = new EventEmitter<boolean>();

  toggle() {
    this.model = !this.model;
    this.modelChange.emit(this.model);
  }
}
