import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PageConfigService } from '../../../services';

@Component({
	selector: 'app-info-popup',
	templateUrl: './info-popup.component.html',
	styleUrls: ['./info-popup.component.scss']
})
export class InfoPopupComponent {

	@Input()
	videoSrcSet
	@Input()
	overlayImageSrc
	@Input()
	overlayTitle: string;

	@Output()
	hidePopup = new EventEmitter<boolean>();

	constructor(
		public pageConfigService: PageConfigService,
	) { }

	async modalClose($event) {
		if (!$event.submitted) {
			this.hidePopup.emit();
			return;
		}
	}
}
