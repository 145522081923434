import { Component, Input, OnInit } from '@angular/core';
import { CardContent } from 'app/models';
import { CmsBaseComponent } from '../cms-base/cms-base.component';

@Component({
	selector: 'app-blog-post-section',
	templateUrl: './blog-post-section.component.html',
	styleUrls: ['./blog-post-section.component.scss']
})
export class BlogPostSectionComponent extends CmsBaseComponent implements OnInit {

  @Input()
  contents!: CardContent[]

  title: string;
  description: string;

	constructor() {
    super();
	}

	ngOnInit(): void {
    super.ngOnInit();

    this.title = this.contents[0].title;
    this.description = this.contents[0].description;
	}

}