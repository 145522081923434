import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormsModule } from '@angular/forms';
import { MaterialModule } from 'app/modules/material/material.module';
import { SharedModule } from 'app/modules/shared/shared.module';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { StepperComponent } from 'app/standalone-components/stepper/stepper.component';
import { InputRequiredErrorComponent } from 'app/standalone-components/input-required-error/input-required-error.component';
import { environment } from "../../../../environments/environment";
import { Subscription } from 'rxjs';
import { ApiService, AuthService, LogService, PopupService, ToastService, UtilityService } from 'app/services';
import { RecaptchaComponent, RecaptchaModule } from 'ng-recaptcha';
import { TranslateService } from '@ngx-translate/core';
import { LoginOrRegisterFrame } from '../login-or-register-frame/login-or-register-frame.component';
import { RegisterUserRequest } from 'app/microservice-clients/user';
import { BypassAPIGlobalHandleEnums, LOG_TYPE, MessageType } from 'app/constants';
import { trigger, transition, style, animate } from '@angular/animations';
import { AdditionalInformationComponent } from 'app/standalone-components/common-dialogs/additional-information/additional-information/additional-information.component';

@Component({
  selector: 'app-student-register-dialog',
  templateUrl: './student-register-dialog.component.html',
  styleUrls: ['./student-register-dialog.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedModule, RecaptchaModule, AdditionalInformationComponent, MaterialModule, FormsModule, StepperComponent, LoginOrRegisterFrame, InputRequiredErrorComponent],
  animations: [
    trigger('errorMessage', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.4s ease-in-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('0.4s ease-in-out', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class StudentRegisterDialogComponent implements OnInit {
  @Input() isCloseButtonVisible: boolean = true;
  @Input() studentData: StudentRegistration = {} as StudentRegistration;

  onUserLoginComplete: EventEmitter<boolean> = new EventEmitter<boolean>();

  hidePassword = true;
  passwordTouched = false;
  hideSteps: boolean = true;

  minLengthMet: boolean = false;
  uppercaseMet: boolean = false;
  lowercaseMet: boolean = false;
  numberMet: boolean = false;
  showError: boolean = false;
  studentPhone: FormControl;
  siteKey: string = environment.google.InvisibleCaptchaKey;
  showNextButton: boolean = false;

  isEmailDuplicate: boolean = false;
  isRecaptchaDisabled: boolean;
  captcha_value: string;
  isPasswordValid: boolean = true;
  currentEmailCheck: Subscription;

  emailDuplicateState: boolean = false;
  emailFormatInvalid: boolean;
  tutoringDurationOptions: Array<{ duration: string, translation: string }>;
  form: FormGroup;

  @ViewChild('stepper') stepper: StepperComponent;
  @ViewChild("recaptchaComponent") reCaptcha: RecaptchaComponent;

  phoneNumberUtil: any;

  constructor(
    public dialogRef: MatDialogRef<StudentRegisterDialogComponent>,
    public translationService: TranslateService,
    public toastService: ToastService,
    private formBuilder: FormBuilder,
    private utilityService: UtilityService,
    private logService: LogService,
    private popupService: PopupService,
    private apiService: ApiService,
    private authService: AuthService) {
  }

  delayedValidatePhoneTimeout
  isPhoneError: boolean

  @ViewChild("phoneInput") phoneInput: any;

  async ngOnInit() {
    if (this.studentData.googleId) {
      this.form = this.formBuilder.group({
        expectedTutoringDuration: ['', [Validators.required]]
      });
    } else {
      this.form = this.formBuilder.group({
        email: ['', [Validators.required, Validators.pattern('^[\\w-]+(\\.[\\w-]+)*@[\\w-]+(\\.[\\w-]+)*(\\.[a-zA-Z]{2,})$')]],
        password: ['', [Validators.required]],
        firstName: ['', [Validators.required]],
        lastName: ['', [Validators.required]],
        expectedTutoringDuration: ['', [Validators.required]]
      });
    }
    this.phoneNumberUtil = await this.utilityService.addGooglePhoneNumberLib();

    if (environment.production) {
      this.isRecaptchaDisabled = false;
    } else {
      this.isRecaptchaDisabled = true
      this.captcha_value = "testCaptchaResponse";
    }
    this.isRecaptchaDisabled = environment?.production !== true;
    const translation = this.translationService.instant('texts.Misc_field_mobile_phone_prefix');
    this.studentPhone = new FormControl(translation,
      { validators: [Validators.required, this.utilityService.phonePatternValidator(this.phoneNumberUtil)], updateOn: 'blur' });

    this.translationService.get([
      'texts.Misc_field_mobile_phone_prefix',
      'texts.Modal_register_student_tutoring_duration',
      'texts.Student_registration_tutoring_duration_0_2_month',
      'texts.Student_registration_tutoring_duration_2_5_month',
      'texts.Student_registration_tutoring_duration_5_10_month',
      'texts.Student_registration_tutoring_duration_plus_10_month',
      // 'texts.Student_registration_tutoring_duration_exam'
    ]).subscribe(translations => {
      this.tutoringDurationOptions = [
        {
          duration: "10+",
          translation: translations["texts.Student_registration_tutoring_duration_plus_10_month"]
        },
        {
          duration: "5-10",
          translation: translations["texts.Student_registration_tutoring_duration_5_10_month"]
        },
        {
          duration: "2-5",
          translation: translations["texts.Student_registration_tutoring_duration_2_5_month"]
        },
        {
          duration: "0-2",
          translation: translations["texts.Student_registration_tutoring_duration_0_2_month"]
        },
        //WE NEED THIS WHEN WE WANT TO SELL EXAM PACKAGES
        // {
        //   duration: "0",
        //   translation: translations["texts.Student_registration_tutoring_duration_exam"]
        // }
      ]
    });
  }

  ngAfterViewInit() {
    if (this.utilityService.isBrowser && location.search.includes('autofillBAH')) {
      setTimeout(() => {
        if (!this.studentData.googleId) {
          this.fillData();
        }
      })
    }
  }

  googleRegister() {
    this.authService.loginWithGoogle()
  }

  isOptionSelected(option: any) {
    return option.duration === this.form.get('expectedTutoringDuration').value;
  }

  validateEmailInput(): void {
    if (!this.form.controls.email.errors?.pattern) {
      this.emailFormatInvalid = false;
      this.emailDuplicateState = false;
      if (this.form.controls.email.value) {
        this.currentEmailCheck = this.authService.validateEmailAddress(this.form.value.email, this.captcha_value).subscribe({
          error: (error) => {
            if (error.status == 409) {
              this.emailDuplicateState = true;
            }
          }
        });
      }
    } else {
      this.emailFormatInvalid = true;
      this.emailDuplicateState = false;
    }
  }

  validatePassword(event: Event) {
    const password = (event.target as HTMLInputElement)?.value;
    this.passwordTouched = true;
    this.minLengthMet = password.length >= 8;
    this.uppercaseMet = /[A-Z]/.test(password);
    this.lowercaseMet = /[a-z]/.test(password);
    this.numberMet = /[0-9]/.test(password);
    this.isPasswordValid = this.minLengthMet && this.uppercaseMet && this.lowercaseMet && this.numberMet;
  }

  reCaptchaResolved(captchaResponse): void {
    if (captchaResponse) {
      this.captcha_value = captchaResponse;
      this.reCaptcha.reset();
      this.postRegistrationRequest();
      this.logService.log(LOG_TYPE.INFO, `Resolved captcha with response:`, captchaResponse);
    }
  }

  reCaptchaError(event) {
    this.logService.log(LOG_TYPE.WARNING, "Resolved captcha with error", event);
  }

  save() {
    if (!this.isRecaptchaDisabled) {
      this.reCaptcha.execute();
    } else {
      this.postRegistrationRequest();
    }
  }

  postRegistrationRequest(): void {
    if (!this.studentData.googleId) {
      this.mapToStudentData()
    }

    const registrationRequest = {
      user: {
        personalInfo: {
          forename: this.studentData.firstName,
          surname: this.studentData.lastName,
          emailAddress: this.studentData.email,
          phoneNumber: this.studentPhone.value,
        },
        studentDetails: {
          expectedTutoringDuration: this.form.value.expectedTutoringDuration
        },
        country: this.utilityService.country,
        role: 'Student',
      },
      password: this.studentData.password,
      captcha: this.captcha_value,
      googleId: this.studentData.googleId,
    } as RegisterUserRequest;

    const headers = this.utilityService.addHeaders(BypassAPIGlobalHandleEnums.All);
    this.apiService.postWithHeaders(`user/new`, registrationRequest, headers).subscribe((response) => {
      this.toastService.showMsg('texts.Misc_Successful_Registration', MessageType.info, { title: 'texts.Misc_Successful_Registration_Title', timeout:'stick' });
      this.utilityService.trackEvent('Student Register', 'Response', 'Success', response.id);
      this.utilityService.trackEvent('registration_student', 'email', 'success', response.id);
      this.authService.setUserFromAccessToken(response.access)
      const subcribeToUserChange = this.authService.onCurrentUserChange.subscribe(() => {
        this.onUserLoginComplete.emit(true);
        subcribeToUserChange.unsubscribe();
        this.dialogRef.close();
      })
    });
  }

  private mapToStudentData(): void {
    this.studentData.email = this.form.value.email;
    this.studentData.password = this.form.value.password;
    this.studentData.firstName = this.form.value.firstName;
    this.studentData.lastName = this.form.value.lastName;
  }

  // autofill most of the inputs on ?autofillBAH
  fillData() {
    const email = `${Math.round(Math.random() * 1000000)}-bah@mailinator.com`;
    this.form.controls.email.setValue(email);
    this.form.controls.password.setValue(`Test12345`);
    this.form.controls.firstName.setValue("Mark");
    this.form.controls.lastName.setValue("II");
  }

  openLogin() {
    this.closeDialog();
    this.popupService.showLoginModal();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  nextStep() {
    this.isPasswordValid = this.minLengthMet && this.uppercaseMet && this.lowercaseMet && this.numberMet;
    if (this.form.controls.email.valid &&
      this.isPasswordValid &&
      !this.emailDuplicateState &&
      this.form.controls.firstName.valid &&
      this.form.controls.lastName.valid) {
      this.stepper.nextStep();
    }
    else {
      this.showError = true;
    }
  }

  prevStep() {
    if (this.stepper.currentStep === 3) {
      this.showNextButton = true;
    };
    this.stepper.prevStep()
  }

  redirectAndClose() {
    this.closeDialog();
  }
}

export class StudentRegistration {
  email: string;
  password?: string;
  firstName: string;
  lastName: string;
  expectedTutoringDuration: string;
  phone: string;
  googleId?: string;
};