import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UtilityService } from './utility.service';
import { LogService } from './log.service';
import { LOG_TYPE } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private httpClient: HttpClient,
    private utilityService: UtilityService,
    private logService: LogService
  ) {
  }

  get(path: string, params?: HttpParams, isFullPath?: boolean): Observable<any> {
    const fullPath = this.getFullPath(path, isFullPath);
    this.logService.log(LOG_TYPE.INFO, 'http get:' + fullPath);
    return this.httpClient.get(fullPath, { params })
      .pipe(
        map((response: Response) => response)
      );
  }

  getWithHeaders(path: string, headers: HttpHeaders, params?: HttpParams, isFullPath?: boolean): Observable<any> {
    const fullPath = this.getFullPath(path, isFullPath);
    this.logService.log(LOG_TYPE.INFO, 'http get:' + fullPath);
    const options = { headers: headers, params: params ? params : null }
    return this.httpClient.get(fullPath, options)
  }

  post(path: string, body: Object = {}, isFullPath?: boolean): Observable<any> {
    const fullPath = this.getFullPath(path, isFullPath);
    this.logService.log(LOG_TYPE.INFO, 'http post:' + fullPath);
    return this.httpClient.post(fullPath, body)
      .pipe(
        map((response: Response) => response)
      );
  }

  postWithHeaders(path: string, body: Object = {}, headers: HttpHeaders): Observable<any> {

    const fullPath = this.getFullPath(path, false);
    this.logService.log(LOG_TYPE.INFO, 'http post:' + fullPath);
    return this.httpClient.post(fullPath, body, { headers })
      .pipe(
        map((response: Response) => response)
      );
  }

  patch(path: string, body: Object = {}): Observable<any> {
    const fullPath = this.getFullPath(path);
    this.logService.log(LOG_TYPE.INFO, 'http patch:' + fullPath);
    return this.httpClient.patch(fullPath, body)
      .pipe(
        map((response: Response) => response)
      );
  }

  put(path: string, body: Object = {}): Observable<any> {
    const fullPath = this.getFullPath(path);
    this.logService.log(LOG_TYPE.INFO, 'http put:' + fullPath);
    return this.httpClient.put(fullPath, body)
      .pipe(
        map((response: Response) => response)
      );
  }

  delete(path: string, params?: HttpParams): Observable<any> {
    const fullPath = this.getFullPath(path);
    this.logService.log(LOG_TYPE.INFO, 'http delete:' + fullPath);
    return this.httpClient.delete(fullPath, { params })
      .pipe(
        map((response: Response) => response)
      );
  }

  private getFullPath(path: string, isFullPath?: boolean): string {
    if (isFullPath) {
      return path;
    }
    return `${this.utilityService.apiBase}/${path}`;
  }
}
