<section (appear)="startCounter()" class="counting-number__section">
	<div class="counting-number__container">
		<div class="counting-number__text">
			<h2 *ngIf="title && !isFirstSection" class="counting-number__text__title">
				<span class="counting-number__animate">
					<span>{{currentValue}}</span>
				</span>
				<span *ngIf="unit">{{unit}}</span>
			</h2>
			<h1 *ngIf="title && isFirstSection" class="counting-number__text__title">
				<span class="counting-number__animate">
					<span>{{currentValue}}</span>
				</span>
				<span *ngIf="unit">{{unit}}</span>
			</h1>
			<h3 *ngIf="subtitle" class="counting-number__text__subtitle" [innerHTML]="subtitle"></h3>
		</div>
	</div>
</section>