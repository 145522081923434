<div class="question-collection__question-wrapper" (click)="toggleVisibility()">
	<h4 class="question-collection__question--title">
		<span class="question-collection__question--title-text" [innerHTML]="title">
		</span>
		<span class="question-collection__question--title-icon">
			<i *ngIf="!visible" class="fas fa-plus-circle" [ngClass]="{'visible': !visible}"></i>
			<i *ngIf="visible" class="fas fa-minus-circle" [ngClass]="{'visible': visible}"></i>
		</span>
	</h4>
	<div class="question-collection__question--description" [ngClass]="{'visible': visible}" [bindHTML]="description" > 
	</div>
</div>