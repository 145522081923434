import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, ValidatorFn } from '@angular/forms';

@Component({
	selector: 'app-input-error',
	templateUrl: './error.component.html',
	styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

	@Input()
	element: UntypedFormControl | AbstractControl;
	@Input()
	type: string;
	@Input()
	errorMessage: string = 'texts.Misc_field_message_error';
	@Input()
	requiredMessage: string = 'texts.Misc_field_message_required';
	@Input()
	changeMessage: string = 'texts.Misc_click_save';
	@Input()
	specialMessage: string = 'texts.Misc_field_message_error';
	@Input()
	specialCondition: boolean = false;

	minCharsRegex = "(?=.{8,})";
	numbersRegex = "(?=.*\\d)";
	lowerCaseRegex = "(?=.*[a-z])";
	upperCaseRegex = "(?=.*[A-Z])";
	lowerAndUpperCaseRegex = "(?=.*[a-zA-Z])";
	cifersRegex = "(?=.*[!@#\\$%\\*&\\*§=])";
	passregex = new RegExp(`${this.minCharsRegex}(${this.numbersRegex}${this.lowerCaseRegex}${this.upperCaseRegex}|${this.numbersRegex}${this.lowerAndUpperCaseRegex}${this.cifersRegex}|${this.numbersRegex}${this.lowerCaseRegex}${this.cifersRegex}|${this.numbersRegex}${this.upperCaseRegex}${this.cifersRegex}|${this.lowerCaseRegex}${this.upperCaseRegex}${this.cifersRegex}).*`);

	ngOnInit(): void {
		switch (this.type) {
			case 'email':
				this.errorMessage = 'texts.Misc_field_email_message_error';
				break;
			case 'password':
				this.errorMessage = 'texts.Misc_field_password_message_error';
				break;
			case 'passwordCheck':
				this.errorMessage = 'texts.Misc_field_password_message_error';
				break;
			case 'passwordConfirm':
				this.errorMessage = 'texts.InputFieldComponent_password_not_matching';
				break;
			case 'firstName':
				this.errorMessage = 'texts.Misc_field_email_message_error';
				break;
			case 'lastName':
				this.errorMessage = 'texts.Misc_field_email_message_error';
				break;
			case 'birthday':
				this.errorMessage = 'texts.Misc_field_email_message_error';
				break;
		}
	}

	// validation for password repeat inputs
	// passwordComparison(e, origPassword, repPassword) {
	// 	if (origPassword === "" || repPassword === "") {
	// 		console.log("password comparison fail blank");
	// 		e.closest(".input__icon-wrapper").siblings('.input-message--error').addClass('visible');
	// 		e.addClass("error");
	// 	} else if (origPassword === repPassword) {
	// 		console.log("password comparison pass");
	// 		e.closest(".input__icon-wrapper").siblings('.input-message--error').removeClass('visible');
	// 		e.removeClass("error");
	// 	} else {
	// 		console.log("password comparison fail");
	// 		e.closest(".input__icon-wrapper").siblings('.input-message--error').addClass('visible');
	// 		e.addClass("error");
	// 	}
	// }

	passwordIsBigLetterSet: boolean = false;
	passwordIsMinLengthSet: boolean = false;
	passwordIsNumberSet: boolean = false;
	passwordIsSmallLetterSet: boolean = false;

}
export function customPasswordCheckValidator(): ValidatorFn {
	let minCharsRegex = "(?=.{8,})";
	let numbersRegex = "(?=.*\\d)";
	let lowerCaseRegex = "(?=.*[a-z])";
	let upperCaseRegex = "(?=.*[A-Z])";
	return (control: AbstractControl): { [key: string]: any } | null => {
		let controlValue = control ? control.value : null;
		let result = {
			minLength: new RegExp(minCharsRegex).test(controlValue),
			numbers: new RegExp(numbersRegex).test(controlValue),
			lowerCase: new RegExp(lowerCaseRegex).test(controlValue),
			upperCase: new RegExp(upperCaseRegex).test(controlValue),
			value: controlValue,
		};

		return result.minLength && result.numbers && result.lowerCase && result.upperCase ? null : { passwordCheck: result };
	};
}

export function customPasswordConfirmValidator(): ValidatorFn {
	return (control: AbstractControl): { [key: string]: any } | null => {
		let result = null;
		if (control?.parent) {
			let controlValue = control ? control.value : null;
			let passwordControl = control.parent.get('password') ? control.parent.get('password') : control.parent.get('passwordCheck');
			const password: string = control.parent ? passwordControl.value : "";
			result = password == controlValue ? null : { passwordConfirm: { value: controlValue } };
		}
		return result;
	}
}


export function customPhoneValidator(regionCode: string = undefined, phoneNumberUtil): ValidatorFn {
	return (control: AbstractControl): { [key: string]: any } => {
		let validNumber = false;
		try {
			const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(
				control.value, regionCode.toUpperCase()
			);
			validNumber = phoneNumberUtil.isValidNumber(phoneNumber);
		} catch (e) { }
		return validNumber ? null : { phone: { phoneValid: false, value: control.value } };
	}
}