import { Component } from '@angular/core';

@Component({
  selector: 'app-info-wrapper',
  templateUrl: './info-wrapper.component.html',
  styleUrls: ['./info-wrapper.component.scss']
})
export class InfoWrapperComponent {

}
