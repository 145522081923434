import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  isDevMode,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "app/modules/shared/shared.module";
import { SearchService } from "app/services/search.service";
import { ImageDataModel } from "app/models/image-translation.model";
import { MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-intro-feedback-dialog",
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: "./intro-feedback-dialog.component.html",
  styleUrls: ["./intro-feedback-dialog.component.scss"],
})
export class IntroFeedbackDialogComponent implements OnInit {
  image: ImageDataModel;

  @Input() tutorBasicData: {
    forename: string;
    surname: string;
    image: string;
    id: string;
  };
  
  @Output() onBookLesson = new EventEmitter<void>();

  constructor(
    private searchService: SearchService,
    private dialogRef: MatDialogRef<IntroFeedbackDialogComponent>,
    private router: Router,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.setTutorImage();
  }

  bookLesson() {
    this.translateService.get('links.Misc_profile').subscribe(translation => {
      const tutorProfileUrl = `${translation}/${this.tutorBasicData.id}`;
      localStorage.setItem('initBooking', 'true');
      this.router.navigate([tutorProfileUrl]);
      this.onBookLesson.emit();
      this.close();
    })
  }

  close() {
    this.dialogRef.close();
  }

  goToSearch() {
    this.close();

    this.searchService.navigateToSearch(true);
  }

  private setTutorImage() {
    this.image = {
      title: this.tutorBasicData.forename + "picture",
      originalSource:
        (isDevMode()
          ? "https://staging.bijlesaanhuis.nl/profileImages/"
          : "/profileImages/") + this.tutorBasicData.image,
    };
  }
}
