import { Component, Input, OnInit } from '@angular/core';
import { PreparedLink } from 'app/models';
import { UtilityService, VariableService } from '../../../services';
import { CmsBaseComponent } from '../cms-base/cms-base.component';

@Component({
	selector: 'app-action-header-two',
	templateUrl: './action-header-two.component.html',
	styleUrls: ['./action-header-two.component.scss']
})
export class ActionHeaderTwoComponent extends CmsBaseComponent implements OnInit {

	@Input()
	title?: string;
	@Input()
	description?: string;

	@Input()
	showLogoGroup: boolean = false;
	@Input()
	showEmpathy: boolean = false;
	@Input()
	guaranteeLargeText: string;
	@Input()
	guaranteeSmallText: string;

	@Input()
	showStorybrandSteps: boolean = false;

	@Input()
	showLeftButton: boolean = false;
	@Input()
	buttonLeftText: string;
	@Input()
	buttonLeftLink: string;
	@Input()
	showRightButton: boolean = false;
	@Input()
	buttonRightText: string;
	@Input()
	buttonRightLink: string;
	preparedLinkLeft!: PreparedLink;
	preparedLinkRight!: PreparedLink;

	constructor(
		public utilityService: UtilityService,
		public variableService: VariableService,
	) {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();

		this.preparedLinkLeft = this.utilityService.prepareLink(this.buttonLeftLink, null);
		this.preparedLinkRight = this.utilityService.prepareLink(this.buttonRightLink, null);
		if (this.description) {
			this.description = this.utilityService.unescapeHTML(this.description);
		}
	}

}
