export interface StripePaymentIntent {
    id: string;
    userId: string;
    userPackageId: string;
    userStripeId: string;
    stripeId: string;
    country: string;
    pricePerInstallment: number;
    status: string;
    paymentOption: string;
    url: string;
    currentInstallment: string;
}

export enum PaymentMethod {
    Subsidy = 1,
    OneInstallment = 2,
    PayMonthly = 3,
}

export enum PaymentIntentStatus {
    success= 'Success'
}