<div class="search-container" data-cy="BottomSheetSearch">
    <i class="fas fa-times" (click)="closeBottomSheet(); location.historyGo(-1)"></i>
    <div class="sheet-title">{{'texts.NewSearch.Mobile.Title' |
        translate}}</div>
    <app-toggle-online-home class="toggle-container" [(isOnline)]="searchService.isOnline"
        (onStateChanged)="searchService.setQueryParamObjBasedOnSelection()"></app-toggle-online-home>
    <div class="panels-container" [class.allowSticky]="expandedPanelIndex === 1">
        <app-custom-expansion-panel #schooltypePanel class="schooltype-panel search-option"
            [isExpanded]="expandedPanelIndex === 0" (onHeaderClicked)="nextStep(0)">
            <div header class="expansion-panel-header" data-cy="SchoolTypes">
                <div class="header-container" *ngIf="expandedPanelIndex !== 0">
                    <app-svg-icon class="expansion-panel-icon" name="buildings"></app-svg-icon>
                    <div *ngIf="!searchService.selectedSchoolLevel"> {{'texts.Searchbar_Component_Schooltype_Text' |
                        translate}} </div>
                    <div class="header-container header-selected-elements" *ngIf="searchService.selectedSchoolLevel">
                        <div (click)="searchService.onLevelSelect(searchService.selectedSchoolLevel); $event.stopPropagation()"
                            class="header-selected-element">
                            {{searchService.selectedSchoolLevel.name}} <app-svg-icon name="close"></app-svg-icon>
                        </div>
                        <div (click)="searchService.setAvailableCoursesByYearCheckbox(searchService.selectedYear);$event.stopPropagation()"
                            class="header-selected-element" *ngIf="searchService.selectedYear">
                            {{'texts.NewSearch.Mobile.Year' |
                            translate}} {{searchService.selectedYear.name}}<app-svg-icon name="close"></app-svg-icon>
                        </div>
                    </div>
                </div>
                <div class="content-title-container" *ngIf="expandedPanelIndex === 0">
                    <div class="content-icon"> <app-svg-icon class="expansion-panel-icon"
                            name="buildings"></app-svg-icon>
                    </div>
                    <div class="content-title">{{'texts.Searchbar_Component_Schooltype_Text' |
                        translate}}</div>
                </div>
            </div>
            <div expandedContent class="expansion-content-container">
                <div *ngFor="let schoolType of searchService.schoolTypes">
                    <div class="content-subtitle">{{schoolType.name}}
                        <div class="checkbox-container" *ngFor="let level of schoolType.levels">
                            <mat-checkbox class="schools-checkbox"
                                [checked]="searchService.selectedSchoolLevel?.id == level.id"
                                (change)="onSchoolLevelSelect(level)" data-cy="SchoolType">{{level.name}}</mat-checkbox>
                            <div *ngIf=" searchService.selectedSchoolLevel==level">
                                <div *ngIf=" searchService.selectedSchoolLevel.years" class="content-subtitle">
                                    {{'texts.DropdownSelect_schoolyear'|translate}}
                                </div>
                                <div class=" school-year-container" [style.height]=dynamicHeight data-cy="SchoolYears">
                                    <div class="checkbox-container"
                                        *ngFor="let year of searchService.selectedSchoolLevel.years">
                                        <mat-checkbox [checked]="searchService.selectedYearId == year.id"
                                            (change)="searchService.setAvailableCoursesByYearCheckbox(year);nextStep(1)"
                                            class="checkbox subject" data-cy="SchoolYear">{{year.name}}</mat-checkbox>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </app-custom-expansion-panel>
        <app-custom-expansion-panel #subjectsPanel class="subjects-panel search-option"
            [isExpanded]="expandedPanelIndex === 1" (onHeaderClicked)="nextStep(1)">
            <div header class="expansion-panel-header">
                <div class="header-container" *ngIf="expandedPanelIndex !== 1">
                    <app-svg-icon class="expansion-panel-icon" name="notebook-square"></app-svg-icon>
                    <div *ngIf="!searchService.selectedCourses.length"> {{'texts.DropdownSelect_subjects' |
                        translate}} </div>
                    <div class="header-selected-elements" *ngIf="searchService.selectedCourses.length">
                        <div *ngFor="let course of searchService.selectedCourses">
                            <div (click)="searchService.addOrRemoveCourses(course);$event.stopPropagation()"
                                class="header-selected-element">
                                {{course.name}} <app-svg-icon name="close"></app-svg-icon> </div>
                        </div>
                    </div>
                </div>
                <div class="content-title-container" *ngIf="expandedPanelIndex === 1">
                    <div class="content-icon"> <app-svg-icon class="expansion-panel-icon"
                            name="notebook-square"></app-svg-icon>
                    </div>
                    <div class="content-title">{{'texts.DropdownSelect_subjects' |
                        translate}}</div>
                    <div class="content-recommandation">{{'texts.NewSearch.Mobile.MultipleChoices' |
                        translate}}</div>
                </div>
            </div>
            <div expandedContent class="expansion-content-container subjects-list">
                <app-subjects-checkbox-list [availableCourses]="searchService.availableCourses"
                    [selectedCourseIds]="searchService.selectedCourseIds"
                    (onSelect)="searchService.addOrRemoveCourses($event)"></app-subjects-checkbox-list>
                <div *ngIf="!searchService.isOnline" class="confirm-button-container">
                    <div class="gradient-separator"></div>
                    <button class="confrim-button button" (click)="searchOrFocusOnPostCode()"
                        data-cy="ConfirmButton">{{'texts.NewSearch.Mobile.Next'
                        |
                        translate}}</button>
                </div>
                <div *ngIf="searchService.isOnline && expandedPanelIndex === 1" [ngClass]="{'moveSticky': moveSticky}"
                    class="confirm-button-container">
                    <div class="gradient-separator" *ngIf="!moveSticky"></div>
                    <button class="confrim-button button" (click)="search()"
                        data-cy="InsideSearchButton">{{'texts.NewSearch.Mobile.Find' |
                        translate}}</button>
                </div>
            </div>
        </app-custom-expansion-panel>
    </div>
    <div [ngClass]="{'hide': searchService.isOnline, 'is-error-in-container' : searchService.showPostCodeError && !searchService.isOnline}"
        class="input-container">
        <div *ngIf="!searchService.isOnline" class="search-option input"
            [ngClass]="{'error-border': searchService.showPostCodeError}">
            <app-svg-icon name="map-point"></app-svg-icon>
            <input matInput #postCodeInput type="text" class="post-code" name="post-code"
                (ngModelChange)="postCodeInputChange.next($event)"
                [placeholder]="'texts.SearchBarHeaderSection_post_code' | translate" [disabled]="searchService.isOnline"
                [readonly]="authService.userDetails && authService.userDetails?.state !== UserStateEnum.New"
                maxlength="7" [(ngModel)]="searchService.postCode" data-cy="PostCode">
        </div>
        <div *ngIf="!searchService.isOnline" class="postcode-error"
            [ngClass]="{'visible': searchService.showPostCodeError}">
            {{'texts.TutorSearchDropdownsComponent_postcode_not_valid' |
            translate }}</div>
    </div>
    <div #observerTarget class="search-and-clear-container observed">
        <button class="clear-button button" (click)="searchService.clearAll(); expandedPanelIndex = null"
            data-cy="ClearButton">{{'texts.NewSearch.Mobile.Clear' |
            translate}}</button>
        <button class="search-button button"
            *ngIf="!searchService.isOnline || (searchService.isOnline && expandedPanelIndex !== 1)" (click)="search()"
            data-cy="OutsideSearchButton">
            <span> {{'texts.NewSearch.Mobile.Find' | translate}} </span>
        </button>
    </div>
</div>