<section>
	<!-- DON'T REMOVE! Alibi section to ensure background alternation consistency -->
</section>
<section class="button-section">
	<div class="button-section__container">
		<a *ngIf="buttonLink && !isMediaButton " class="button-section__button {{buttonPosition}}"
			[routerLink]="['/' + buttonLink] | localize">
			{{buttonText}}
		</a>
		<a *ngIf="buttonLink && isMediaButton " class="button-section__button {{buttonPosition}}"
			href="{{buttonLink}}" target="_blank">
			{{buttonText}}
		</a>
	</div>
</section>