import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PageConfigService, UtilityService } from '../../../services';

@Component({
	selector: 'app-info-image',
	templateUrl: './info-image.component.html',
	styleUrls: ['./info-image.component.scss']
})
export class InfoImageComponent implements OnInit {

	@Input()
	img

	constructor(
		public pageConfigService: PageConfigService,
		private translationService: TranslateService,
		private utilityService: UtilityService
	) { }

	ngOnInit(): void {
		if (typeof this.img === "string" && (this.img.includes('default-') || this.img.includes('overlay-'))) {
			this.translationService.get('img.' + this.img).subscribe(translation => {
				this.img = this.utilityService.convertICUTranslatedObject(translation);
			});
		}
	}

	getsources(): string {
		let result: string = "";
		for (let sources of this.img.sources) {
			if (sources.maxScreenSize && sources.src) {
				result += `${sources.src} ${Math.round(sources.maxScreenSize / 2)} w,`;
			}
		}
		return result;
	}

	getSizes(): string {
		let result: string = "";
		let i = 0;
		let setLength = this.img.sources ? this.img.sources.length : 0;
		for (let sources of this.img.sources) {
			if (!sources.src) {
				continue;
			}

			if (sources.maxScreenSize && i != setLength - 1) {
				result += `max-width: ${sources.maxScreenSize} px) ${Math.round(sources.maxScreenSize / 2)} px,`;
			} else {
				result += `${Math.round(sources.maxScreenSize / 2)} px`;
			}
			i++;
		}
		return result;
	}

}
