import { NgModule } from '@angular/core';
import { TutoringInfoSectionComponent } from './tutoring-info-section/tutoring-info-section.component';
import { TutorCardsComponent } from './tutor-cards/tutor-cards.component';
import { TextInfoSectionComponent } from './text-info-section/text-info-section.component';
import { InfoTextComponent } from './info-text/info-text.component';
import { InfoPopupComponent } from './info-popup/info-popup.component';
import { InfoImageComponent } from './info-image/info-image.component';
import { LazyLoadImageModule, LAZYLOAD_IMAGE_HOOKS } from 'ng-lazyload-image';
import { LazyLoadImageHooks } from '../../hooks/lazy-load-image-hooks';
import { SharedModule } from '../shared/shared.module';

@NgModule({
	declarations: [
		InfoPopupComponent,
		InfoImageComponent,
		InfoTextComponent,
		TextInfoSectionComponent,
		TutorCardsComponent,
		TutoringInfoSectionComponent
	],
	imports: [
		SharedModule,
		LazyLoadImageModule
	],
	exports: [
		InfoPopupComponent,
		InfoImageComponent,
		InfoTextComponent,
		TextInfoSectionComponent,
		TutorCardsComponent,
		TutoringInfoSectionComponent
	],
	providers: [
		{
			provide: LAZYLOAD_IMAGE_HOOKS,
			useClass: LazyLoadImageHooks
		},
	]
})
export class InfoModule { }
