<div class="logo-group" [ngClass]="{'logo-group--nl': this.utilityService.country == 'nl'}">
	<ul class="logo-group-list">
		<li class="logo-group-list__item item-carousel">
			<div class="logo-container" [class.logo-container-de]="utilityService.isDE">
				<app-carousel *ngIf="utilityService.isNL" [customConfig]="carouselConfig" [items]="logos"
					[temp]="logosTemplate"></app-carousel>
				<app-image-defer-lazy *ngIf="utilityService.isDE" [image]="awardImage"></app-image-defer-lazy>
			</div>
			<ng-template #logosTemplate let-data="data">
				<div class="new-logo">
					<app-image-defer-lazy [image]="data"></app-image-defer-lazy>
				</div>
			</ng-template>
		</li>
		<li class="logo-group-list__item logo-group-list__item--review-widget">
			<app-google-default-review></app-google-default-review>
		</li>
		<li class="logo-group-list__item">
			<div class="partner-logo">
				<a [routerLink]="['/Navigation_stichting'] | localize" role="link" class="partner-logo__link">
					<img [src]="'images.LogoGroupComponent_stichting_logo.originalSource' | translate"
						[alt]="'images.LogoGroupComponent_stichting_logo.title' | translate" class="partner-logo__img">
				</a>
				<a [routerLink]="['/Navigation_stichting'] | localize" class="logo-group-list__text--small"
					[innerHTML]="'texts.LogoGroupComponent_stichting_text' | translate"></a>
			</div>
		</li>
	</ul>
</div>